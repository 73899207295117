import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  getRequested,
  getSubscription,
  lendLoan,
} from "../../redux/reducers/seller";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkyLight from "react-skylight";
import moment from "moment";
import "./loan.css";
import { nextPagesItems } from "../../helpers/custom";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";

var divStyle = {
  marginLeft: "10%",
};

const Loan = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const microLoanTranslation =
    translationState?.[0]?.translations?.microLoan?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const modal = useRef(null);
  const { singleSubscription, loading: Membershiploading } = useSelector(
    (state) => state.seller
  );
  useEffect(() => {
    dispatch(getSubscription());
  }, []);
  const fremiumText1 = `${
    reportingTranslation?.infoLineOne
      ? reportingTranslation?.infoLineOne
      : "Only SOLO and Partner users can use the Lend Loan Features. Please upgrade your membership to access this and many more exciting features."
  }`;
  const { requestedLoans, filters, count, loading } = useSelector(
    (state) => state.seller
  );
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [selectedLoan, setSelectedLoan] = useState({});
  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    setSelectedPerPage(5);
    dispatch(getRequested());
  }, []);
  const onLendLoan = (item) => {
    setSelectedLoan(item);
    modal.current.show();
  };

  const search = () => {};

  const searchElement = (evt) => {};
  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getRequested());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getRequested());
  };

  const lend = () => {
    const data = {
      loanId: selectedLoan._id,
    };
    dispatch(lendLoan(data)).then(({ payload }) => {
      if (payload?.success) {
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "Loan sent successfully"
          }`
        );
      } else {
        toast.error(payload.err);
      }
      dispatch(getRequested());
    });
    modal.current.hide();
  };
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {Membershiploading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : singleSubscription?.membershipType !== "Fremium" ? (
        <>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={modal}
            title={
              <p style={{ display: "flex", justifyContent: "center" }}>
                <b>
                  <h4>Are you sure you want to lend this Loan?</h4>
                </b>
              </p>
            }
          >
            <Row>
              <Row className="selectedLoan">
                <b>Amount:</b>
                <p>{selectedLoan.amount}</p>
              </Row>
              <Row className="selectedLoan">
                <b>Due Date:</b>
                <p>{moment(selectedLoan.dueDate).format("MMM-D-YYYY")}</p>
              </Row>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Button type="submit" onClick={lend} size="md" color="warning">
                Yes
              </Button>
              <Col xs="12" sm="1"></Col>
              <Button
                type="submit"
                size="md"
                onClick={() => modal.current.hide()}
                color="danger"
              >
                No
              </Button>
            </Row>
          </SkyLight>
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row>
                    <Col xs="12" sm="9">
                      <b>
                        {microLoanTranslation?.request
                          ? microLoanTranslation?.request
                          : "Requested Loans"}
                      </b>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="card-body">
                  <br />
                  <div className="tableFixHead">
                    <Table hover bordered striped responsive size="sm">
                      <thead align="center" style={{ position: "sticky" }}>
                        <tr className="header-color">
                          <th>
                            {microLoanTranslation?.id
                              ? microLoanTranslation?.id
                              : "Loan Application ID"}
                          </th>
                          <th>
                            {microLoanTranslation?.amount
                              ? microLoanTranslation?.amount
                              : "Amount"}
                          </th>
                          <th>
                            {microLoanTranslation?.number
                              ? microLoanTranslation?.number
                              : "No. of Installments"}
                          </th>
                          <th>
                            {microLoanTranslation?.dueDate
                              ? microLoanTranslation?.dueDate
                              : "Due Date"}
                          </th>
                          <th>
                            {productTranslation?.action
                              ? productTranslation?.action
                              : "Actions"}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <td colSpan={5} className="py-5">
                            <div style={{ textAlign: "center" }}>
                              <i
                                className="fas fa-spinner fa-pulse fa-2x"
                                style={{ color: "green" }}
                              ></i>
                            </div>
                          </td>
                        ) : requestedLoans.length > 0 && !loading ? (
                          requestedLoans.map((item, ind) => (
                            <tr align="center" key={ind}>
                              <td> {item._id} </td>
                              <td> {item.amount} </td>
                              <td> {item.installments.length} </td>
                              <td>
                                {moment(item.dueDate).format("MMM-D-YYYY")}
                              </td>
                              <td>
                                <Button
                                  color="warning"
                                  onClick={() => onLendLoan(item)}
                                  style={divStyle}
                                  btn="sm"
                                >
                                  <b>
                                    {microLoanTranslation?.lendLoan
                                      ? microLoanTranslation?.lendLoan
                                      : "Lend Loan"}
                                  </b>
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td colSpan={5} className="py-5">
                            <div style={{ textAlign: "center" }}>
                              <p style={{ color: "red" }}>
                                {walletTranslation?.notFound
                                  ? walletTranslation?.notFound
                                  : "Records Not Found"}
                              </p>
                            </div>
                          </td>
                        )}
                      </tbody>
                    </Table>
                  </div>
                  <nav>
                    <Pagination>
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(pageNo - 1)}
                          disabled={pageNo === 1 ? true : false}
                          style={{ backgroundColor: "#E4E7EA" }}
                        >
                          {reportingTranslation?.previousButton
                            ? reportingTranslation?.previousButton
                            : "Prev"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => paginate(pageNo + 1)}
                          disabled={!clickableNext}
                          className="mx-2"
                          style={{ backgroundColor: "#E4E7EA" }}
                        >
                          {reportingTranslation?.nextButton
                            ? reportingTranslation?.nextButton
                            : "Next"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem className={"ml-auto"}>
                        <PaginationLink
                          className="rounded"
                          style={{
                            color: "black",
                            hover: false,
                            backgroundColor: "white",
                          }}
                        >
                          <div>
                            {productTranslation?.itemPerPage
                              ? productTranslation?.itemPerPage
                              : "Items per Page"}
                            <select
                              className="ml-2"
                              value={selectedPerPage}
                              onChange={(e) => perPageHandler(e, pageNo)}
                            >
                              {itemsPerPageOptions.map((option, i) => (
                                <option key={i} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <FreemiumPage text={fremiumText1} />
      )}
    </div>
  );
};
export default Loan;
