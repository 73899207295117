export const materials = [
  "PLA (Polylactic Acid)",
  "Steel",
  "ABS (Acrylonitrile Butadiene Styrene)",
  "Resin",
  "Nylon (Polyamide)",
  "Mixed Electronics",
  "Canvas/Acrylic",
  "Acrylic",
  "Cotton",
  "Stainless Steel",
  "Wool",
  "Polyester",
  "Cotton/Synthetic",
  "Synthetic",
  "Recycle Wool",
  "Leather",
  "Plastic",
  "Ceramic",
  "Glass",
  "Metal",
  "Wood",
  "Mixed Materials",
  "Metal/Wood",
  "Iron",
  "Recycle iron",
  "Copper",
  "Nickle",
  "Glass/Ceramic",
  "Bronze (Copper/tin)",
  "Brass (zinc)",
  "Aluminium",
  "Titanium",
  "Lead",
  "Metal (Steel)",
  "Sugar",
  "Cocoa",
  "Coffee",
  "Paper",
  "Fabric",
  "Canvas (Cotton)",
  "Essential Oils",
  "Wax",
  "Cardboard/Paper",
  "Electronics",
  "Fabric/Textile",
  "Canvas",
  "Digital",
  "PLA/ABS",
  "Laser Cutters( per 100 m)",
  "Foam Boards/Modeling Clay/Breadboards:",
  "Plastic/Metal",
  "Metal/Plastic",
  "Cashmere",
  "Cotton/Thread",
  "Various",
  "Pashmina",
  "Silk",
  "Rubber",
  "Rope",
  "Leather/Plastic",
  "Leather/Fabric",
  "Mixed Chemicals",
  "Mixed Ingredients",
  "Oils",
  "Stone",
  "Digital Media",
  "Organic Material",
  "Live Performance",
  "Leather/Wood",
  "Fabric/Rope",
];

export const materialList = {
  en: {
    "PLA (Polylactic Acid)": "PLA (Polylactic Acid)",
    Steel: "Steel",
    "ABS (Acrylonitrile Butadiene Styrene)":
      "ABS (Acrylonitrile Butadiene Styrene)",
    Resin: "Resin",
    "Nylon (Polyamide)": "Nylon (Polyamide)",
    "Mixed Electronics": "Mixed Electronics",
    "Canvas/Acrylic": "Canvas/Acrylic",
    Acrylic: "Acrylic",
    Cotton: "Cotton",
    "Stainless Steel": "Stainless Steel",
    Wool: "Wool",
    Polyester: "Polyester",
    "Cotton/Synthetic": "Cotton/Synthetic",
    Synthetic: "Synthetic",
    "Recycle Wool": "Recycle Wool",
    Leather: "Leather",
    Plastic: "Plastic",
    Ceramic: "Ceramic",
    Glass: "Glass",
    Metal: "Metal",
    Wood: "Wood",
    "Mixed Materials": "Mixed Materials",
    "Metal/Wood": "Metal/Wood",
    Iron: "Iron",
    "Recycle iron": "Recycle Iron",
    Copper: "Copper",
    Nickle: "Nickel",
    "Glass/Ceramic": "Glass/Ceramic",
    "Bronze (Copper/tin)": "Bronze (Copper/Tin)",
    "Brass (zinc)": "Brass (Zinc)",
    Aluminium: "Aluminium",
    Titanium: "Titanium",
    Lead: "Lead",
    "Metal (Steel)": "Metal (Steel)",
    Sugar: "Sugar",
    Cocoa: "Cocoa",
    Coffee: "Coffee",
    Paper: "Paper",
    Fabric: "Fabric",
    "Canvas (Cotton)": "Canvas (Cotton)",
    "Essential Oils": "Essential Oils",
    Wax: "Wax",
    "Cardboard/Paper": "Cardboard/Paper",
    Electronics: "Electronics",
    "Fabric/Textile": "Fabric/Textile",
    Canvas: "Canvas",
    Digital: "Digital",
    "PLA/ABS": "PLA/ABS",
    "Laser Cutters( per 100 m)": "Laser Cutters (per 100 m)",
    "Foam Boards/Modeling Clay/Breadboards:":
      "Foam Boards/Modeling Clay/Breadboards:",
    "Plastic/Metal": "Plastic/Metal",
    "Metal/Plastic": "Metal/Plastic",
    Cashmere: "Cashmere",
    "Cotton/Thread": "Cotton/Thread",
    Various: "Various",
    Pashmina: "Pashmina",
    Silk: "Silk",
    Rubber: "Rubber",
    Rope: "Rope",
    "Leather/Plastic": "Leather/Plastic",
    "Leather/Fabric": "Leather/Fabric",
    "Mixed Chemicals": "Mixed Chemicals",
    "Mixed Ingredients": "Mixed Ingredients",
    Oils: "Oils",
    Stone: "Stone",
    "Digital Media": "Digital Media",
    "Organic Material": "Organic Material",
    "Live Performance": "Live Performance",
    "Leather/Wood": "Leather/Wood",
    "Fabric/Rope": "Fabric/Rope",
  },
  ar: {
    "PLA (Polylactic Acid)": "بلا (حمض اللاكتيك)",
    Steel: "فولاذ",
    "ABS (Acrylonitrile Butadiene Styrene)":
      "ايه بي اس (أكريلونيتريل بوتادين ستايرين)",
    Resin: "راتنج",
    "Nylon (Polyamide)": "نايلون (بولي أميد)",
    "Mixed Electronics": "إلكترونيات مختلطة",
    "Canvas/Acrylic": "قماش / أكريليك",
    Acrylic: "أكريليك",
    Cotton: "قطن",
    "Stainless Steel": "فولاذ مقاوم للصدأ",
    Wool: "صوف",
    Polyester: "بوليستر",
    "Cotton/Synthetic": "قطن / صناعي",
    Synthetic: "صناعي",
    "Recycle Wool": "صوف معاد تدويره",
    Leather: "جلد",
    Plastic: "بلاستيك",
    Ceramic: "سيراميك",
    Glass: "زجاج",
    Metal: "معدن",
    Wood: "خشب",
    "Mixed Materials": "مواد مختلطة",
    "Metal/Wood": "معدن / خشب",
    Iron: "حديد",
    "Recycle iron": "حديد معاد تدويره",
    Copper: "نحاس",
    Nickle: "نيكل",
    "Glass/Ceramic": "زجاج / سيراميك",
    "Bronze (Copper/tin)": "برونز (نحاس / قصدير)",
    "Brass (zinc)": "نحاس أصفر (زنك)",
    Aluminium: "ألمنيوم",
    Titanium: "تيتانيوم",
    Lead: "رصاص",
    "Metal (Steel)": "معدن (فولاذ)",
    Sugar: "سكر",
    Cocoa: "كاكاو",
    Coffee: "قهوة",
    Paper: "ورق",
    Fabric: "قماش",
    "Canvas (Cotton)": "قماش (قطن)",
    "Essential Oils": "زيوت أساسية",
    Wax: "شمع",
    "Cardboard/Paper": "كرتون / ورق",
    Electronics: "إلكترونيات",
    "Fabric/Textile": "قماش / نسيج",
    Canvas: "قماش",
    Digital: "رقمي",
    "PLA/ABS": "بلا / ايه بي اس",
    "Laser Cutters( per 100 m)": "قواطع الليزر (لكل 100 متر)",
    "Foam Boards/Modeling Clay/Breadboards:":
      "ألواح رغوة / طين النمذجة / لوحات خبز",
    "Plastic/Metal": "بلاستيك / معدن",
    "Metal/Plastic": "معدن / بلاستيك",
    Cashmere: "كشمير",
    "Cotton/Thread": "قطن / خيط",
    Various: "متنوع",
    Pashmina: "باشمينا",
    Silk: "حرير",
    Rubber: "مطاط",
    Rope: "حبل",
    "Leather/Plastic": "جلد / بلاستيك",
    "Leather/Fabric": "جلد / قماش",
    "Mixed Chemicals": "مواد كيميائية مختلطة",
    "Mixed Ingredients": "مكونات مختلطة",
    Oils: "زيوت",
    Stone: "حجر",
    "Digital Media": "وسائط رقمية",
    "Organic Material": "مادة عضوية",
    "Live Performance": "أداء حي",
    "Leather/Wood": "جلد / خشب",
    "Fabric/Rope": "قماش / حبل",
  },
  fr: {
    "PLA (Polylactic Acid)": "PLA (Acide polylactique)",
    Steel: "Acier",
    "ABS (Acrylonitrile Butadiene Styrene)":
      "ABS (Acrylonitrile Butadiène Styrène)",
    Resin: "Résine",
    "Nylon (Polyamide)": "Nylon (Polyamide)",
    "Mixed Electronics": "Électronique mixte",
    "Canvas/Acrylic": "Toile/Acrylique",
    Acrylic: "Acrylique",
    Cotton: "Coton",
    "Stainless Steel": "Acier inoxydable",
    Wool: "Laine",
    Polyester: "Polyester",
    "Cotton/Synthetic": "Coton/Synthétique",
    Synthetic: "Synthétique",
    "Recycle Wool": "Laine recyclée",
    Leather: "Cuir",
    Plastic: "Plastique",
    Ceramic: "Céramique",
    Glass: "Verre",
    Metal: "Métal",
    Wood: "Bois",
    "Mixed Materials": "Matériaux mélangés",
    "Metal/Wood": "Métal/Bois",
    Iron: "Fer",
    "Recycle iron": "Fer recyclé",
    Copper: "Cuivre",
    Nickle: "Nickel",
    "Glass/Ceramic": "Verre/Céramique",
    "Bronze (Copper/tin)": "Bronze (Cuivre/étain)",
    "Brass (zinc)": "Laiton (zinc)",
    Aluminium: "Aluminium",
    Titanium: "Titane",
    Lead: "Plomb",
    "Metal (Steel)": "Métal (Acier)",
    Sugar: "Sucre",
    Cocoa: "Cacao",
    Coffee: "Café",
    Paper: "Papier",
    Fabric: "Tissu",
    "Canvas (Cotton)": "Toile (Coton)",
    "Essential Oils": "Huiles essentielles",
    Wax: "Cire",
    "Cardboard/Paper": "Carton/Papier",
    Electronics: "Électronique",
    "Fabric/Textile": "Tissu/Textile",
    Canvas: "Toile",
    Digital: "Numérique",
    "PLA/ABS": "PLA/ABS",
    "Laser Cutters( per 100 m)": "Découpeurs laser (par 100 m)",
    "Foam Boards/Modeling Clay/Breadboards:":
      "Planches en mousse/Argile à modeler/Planche à pain:",
    "Plastic/Metal": "Plastique/Métal",
    "Metal/Plastic": "Métal/Plastique",
    Cashmere: "Cachemire",
    "Cotton/Thread": "Coton/Fil",
    Various: "Divers",
    Pashmina: "Pashmina",
    Silk: "Soie",
    Rubber: "Caoutchouc",
    Rope: "Corde",
    "Leather/Plastic": "Cuir/Plastique",
    "Leather/Fabric": "Cuir/Tissu",
    "Mixed Chemicals": "Produits chimiques mélangés",
    "Mixed Ingredients": "Ingrédients mélangés",
    Oils: "Huiles",
    Stone: "Pierre",
    "Digital Media": "Média numérique",
    "Organic Material": "Matériau organique",
    "Live Performance": "Performance en direct",
    "Leather/Wood": "Cuir/Bois",
    "Fabric/Rope": "Tissu/Corde",
  },
  es: {
    "PLA (Polylactic Acid)": "PLA (Ácido poliláctico)",
    Steel: "Acero",
    "ABS (Acrylonitrile Butadiene Styrene)":
      "ABS (Acrilonitrilo Butadieno Estireno)",
    Resin: "Resina",
    "Nylon (Polyamide)": "Nylon (Poliamida)",
    "Mixed Electronics": "Electrónica mixta",
    "Canvas/Acrylic": "Lona/Acrílico",
    Acrylic: "Acrílico",
    Cotton: "Algodón",
    "Stainless Steel": "Acero inoxidable",
    Wool: "Lana",
    Polyester: "Poliéster",
    "Cotton/Synthetic": "Algodón/Sintético",
    Synthetic: "Sintético",
    "Recycle Wool": "Lana reciclada",
    Leather: "Cuero",
    Plastic: "Plástico",
    Ceramic: "Cerámica",
    Glass: "Vidrio",
    Metal: "Metal",
    Wood: "Madera",
    "Mixed Materials": "Materiales mezclados",
    "Metal/Wood": "Metal/Madera",
    Iron: "Hierro",
    "Recycle iron": "Hierro reciclado",
    Copper: "Cobre",
    Nickle: "Níquel",
    "Glass/Ceramic": "Vidrio/Cerámica",
    "Bronze (Copper/tin)": "Bronce (Cobre/estaño)",
    "Brass (zinc)": "Latón (zinc)",
    Aluminium: "Aluminio",
    Titanium: "Titanio",
    Lead: "Plomo",
    "Metal (Steel)": "Metal (Acero)",
    Sugar: "Azúcar",
    Cocoa: "Cacao",
    Coffee: "Café",
    Paper: "Papel",
    Fabric: "Tela",
    "Canvas (Cotton)": "Lona (Algodón)",
    "Essential Oils": "Aceites esenciales",
    Wax: "Cera",
    "Cardboard/Paper": "Cartón/Papel",
    Electronics: "Electrónica",
    "Fabric/Textile": "Tela/Textil",
    Canvas: "Lona",
    Digital: "Digital",
    "PLA/ABS": "PLA/ABS",
    "Laser Cutters( per 100 m)": "Cortadores láser (por 100 m)",
    "Foam Boards/Modeling Clay/Breadboards:":
      "Tableros de espuma/Arcilla para modelar/Placas de pan:",
    "Plastic/Metal": "Plástico/Metal",
    "Metal/Plastic": "Metal/Plástico",
    Cashmere: "Cachemira",
    "Cotton/Thread": "Algodón/Hilo",
    Various: "Varios",
    Pashmina: "Pashmina",
    Silk: "Seda",
    Rubber: "Goma",
    Rope: "Cuerda",
    "Leather/Plastic": "Cuero/Plástico",
    "Leather/Fabric": "Cuero/Tela",
    "Mixed Chemicals": "Productos químicos mezclados",
    "Mixed Ingredients": "Ingredientes mezclados",
    Oils: "Aceites",
    Stone: "Piedra",
    "Digital Media": "Medios digitales",
    "Organic Material": "Material orgánico",
    "Live Performance": "Actuación en vivo",
    "Leather/Wood": "Cuero/Madera",
    "Fabric/Rope": "Tela/Cuerda",
  },
  zh: {
    "PLA (Polylactic Acid)": "PLA（聚乳酸）",
    Steel: "钢",
    "ABS (Acrylonitrile Butadiene Styrene)": "ABS（丙烯腈-丁二烯-苯乙烯）",
    Resin: "树脂",
    "Nylon (Polyamide)": "尼龙（聚酰胺）",
    "Mixed Electronics": "混合电子",
    "Canvas/Acrylic": "画布/丙烯",
    Acrylic: "丙烯",
    Cotton: "棉花",
    "Stainless Steel": "不锈钢",
    Wool: "羊毛",
    Polyester: "聚酯",
    "Cotton/Synthetic": "棉花/合成",
    Synthetic: "合成",
    "Recycle Wool": "回收羊毛",
    Leather: "皮革",
    Plastic: "塑料",
    Ceramic: "陶瓷",
    Glass: "玻璃",
    Metal: "金属",
    Wood: "木材",
    "Mixed Materials": "混合材料",
    "Metal/Wood": "金属/木材",
    Iron: "铁",
    "Recycle iron": "回收铁",
    Copper: "铜",
    Nickle: "镍",
    "Glass/Ceramic": "玻璃/陶瓷",
    "Bronze (Copper/tin)": "青铜（铜/锡）",
    "Brass (zinc)": "黄铜（锌）",
    Aluminium: "铝",
    Titanium: "钛",
    Lead: "铅",
    "Metal (Steel)": "金属（钢）",
    Sugar: "糖",
    Cocoa: "可可",
    Coffee: "咖啡",
    Paper: "纸",
    Fabric: "织物",
    "Canvas (Cotton)": "画布（棉花）",
    "Essential Oils": "精油",
    Wax: "蜡",
    "Cardboard/Paper": "纸板/纸",
    Electronics: "电子产品",
    "Fabric/Textile": "织物/纺织品",
    Canvas: "画布",
    Digital: "数字",
    "PLA/ABS": "PLA/ABS",
    "Laser Cutters( per 100 m)": "激光切割机（每100米）",
    "Foam Boards/Modeling Clay/Breadboards:": "泡沫板/建模粘土/面包板：",
    "Plastic/Metal": "塑料/金属",
    "Metal/Plastic": "金属/塑料",
    Cashmere: "开士米",
    "Cotton/Thread": "棉花/线",
    Various: "各种",
    Pashmina: "羊绒",
    Silk: "丝绸",
    Rubber: "橡胶",
    Rope: "绳子",
    "Leather/Plastic": "皮革/塑料",
    "Leather/Fabric": "皮革/织物",
    "Mixed Chemicals": "混合化学品",
    "Mixed Ingredients": "混合成分",
    Oils: "油",
    Stone: "石头",
    "Digital Media": "数字媒体",
    "Organic Material": "有机材料",
    "Live Performance": "现场表演",
    "Leather/Wood": "皮革/木材",
    "Fabric/Rope": "织物/绳子",
  },
  pt: {
    "PLA (Polylactic Acid)": "PLA (Ácido Poliláctico)",
    Steel: "Aço",
    "ABS (Acrylonitrile Butadiene Styrene)":
      "ABS (Acrilonitrila Butadieno Estireno)",
    Resin: "Resina",
    "Nylon (Polyamide)": "Nylon (Poliamida)",
    "Mixed Electronics": "Eletrônicos Mistos",
    "Canvas/Acrylic": "Lona/Acrílico",
    Acrylic: "Acrílico",
    Cotton: "Algodão",
    "Stainless Steel": "Aço Inoxidável",
    Wool: "Lã",
    Polyester: "Poliéster",
    "Cotton/Synthetic": "Algodão/Sintético",
    Synthetic: "Sintético",
    "Recycle Wool": "Lã Reciclada",
    Leather: "Couro",
    Plastic: "Plástico",
    Ceramic: "Cerâmica",
    Glass: "Vidro",
    Metal: "Metal",
    Wood: "Madeira",
    "Mixed Materials": "Materiais Mistos",
    "Metal/Wood": "Metal/Madeira",
    Iron: "Ferro",
    "Recycle iron": "Ferro Reciclado",
    Copper: "Cobre",
    Nickle: "Níquel",
    "Glass/Ceramic": "Vidro/Cerâmica",
    "Bronze (Copper/tin)": "Bronze (Cobre/Estanho)",
    "Brass (zinc)": "Latão (Zinco)",
    Aluminium: "Alumínio",
    Titanium: "Titânio",
    Lead: "Chumbo",
    "Metal (Steel)": "Metal (Aço)",
    Sugar: "Açúcar",
    Cocoa: "Cacau",
    Coffee: "Café",
    Paper: "Papel",
    Fabric: "Tecido",
    "Canvas (Cotton)": "Lona (Algodão)",
    "Essential Oils": "Óleos Essenciais",
    Wax: "Cera",
    "Cardboard/Paper": "Papelão/Papel",
    Electronics: "Eletrônicos",
    "Fabric/Textile": "Tecido/Textil",
    Canvas: "Lona",
    Digital: "Digital",
    "PLA/ABS": "PLA/ABS",
    "Laser Cutters( per 100 m)": "Cortadores a Laser (por 100 m)",
    "Foam Boards/Modeling Clay/Breadboards:":
      "Placas de Espuma/Argila de Modelagem/Placas de Circuito:",
    "Plastic/Metal": "Plástico/Metal",
    "Metal/Plastic": "Metal/Plástico",
    Cashmere: "Cashmere",
    "Cotton/Thread": "Algodão/Fio",
    Various: "Vários",
    Pashmina: "Pashmina",
    Silk: "Seda",
    Rubber: "Borracha",
    Rope: "Corda",
    "Leather/Plastic": "Couro/Plástico",
    "Leather/Fabric": "Couro/Tecido",
    "Mixed Chemicals": "Produtos Químicos Mistos",
    "Mixed Ingredients": "Ingredientes Mistos",
    Oils: "Óleos",
    Stone: "Pedra",
    "Digital Media": "Mídia Digital",
    "Organic Material": "Material Orgânico",
    "Live Performance": "Performance Ao Vivo",
    "Leather/Wood": "Couro/Madeira",
    "Fabric/Rope": "Tecido/Corda",
  },
};
