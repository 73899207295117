import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SkyLight from "react-skylight";
import moment from "moment";
import "./product.css";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  Tooltip,
} from "reactstrap";
import {
  addFilter,
  deleteProduct,
  getProducts,
  updateProduct,
} from "../../../redux/reducers/seller";
import "react-toastify/dist/ReactToastify.css";
import "./../product.css";
import { ToastContainer, toast } from "react-toastify";
import { nextPagesItems } from "../../../helpers/custom";
import AddEditModal from "../../Events/AddEditModal";

const divStyle = { marginLeft: "60%" };
const myBigGreenDialog = { width: "35%", minHeight: "12%" };

const ProductList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteProductModal = useRef(null);
  const eventProductModal = useRef(null);
  const approveProductModal = useRef(null);
  const {
    user: { userType },
    userLanguage,
  } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);
  const { products, count, filters, loading } = useSelector(
    (state) => state.seller
  );

  const [selectedId, setSelectedId] = useState(0);
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [actionType, setActionType] = useState("edit");
  const [productObj, setProductObj] = useState({
    id: "",
    name: "",
    description: "",
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    eventCategory: "event",
    regularPrice: 1,
    images: [],
    dynamicPrice: { currentPrice: 1 },
  });

  const [tooltipOpen, setTooltipOpen] = useState({
    view: {},
    edit: {},
    delete: {},
  });

  const toggle = (type, id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [id]: !prevState[type][id],
      },
    }));
  };

  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const otherOrderTranslation =
    translationState?.[0]?.translations?.otherOrder?.[userLanguage];
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);

  useEffect(() => {
    dispatch(addFilter({ field: "digitalType", value: "" }));
    dispatch(addFilter({ field: "type", value: "physical" }));
    dispatch(addFilter({ field: "pageNo", value: 1 }));
    dispatch(addFilter({ field: "perPage", value: 5 }));
    setSelectedPerPage(5);
    dispatch(getProducts());
  }, []);

  const approveModal = (id) => {
    setSelectedId(id);
    approveProductModal.current.show();
  };
  const approveProduct = () => {
    const data = {
      id: selectedId,
      status: "Active",
    };
    dispatch(updateProduct(data)).then(() => {
      approveProductModal.current.hide();
      dispatch(getProducts());
    });
  };
  const rejectProduct = () => {
    const data = {
      id: selectedId,
      status: "Rejected",
    };
    dispatch(updateProduct(data)).then(() => {
      approveProductModal.current.hide();
      dispatch(getProducts());
    });
  };
  const deleteModal = (id) => {
    setSelectedId(id);
    deleteProductModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteProduct(selectedId)).then(() => {
      deleteProductModal.current.hide();
      dispatch(getProducts());
    });
  };
  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getProducts());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getProducts());
    }
  };
  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getProducts());
  };
  const showProduct = (id) => {
    const product = products.find((prod) => prod._id === id);
    if (product?.eventCategory === "event") {
      const startDate = product?.startDate;
      const formattedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm");
      setProductObj({
        ...productObj,
        ...product,
        id,
        startDate: formattedStartDate,
      });
      setActionType("view");
      eventProductModal.current.show();
    } else {
      if (userType === "admin") {
        history.push(`/admin/products/view/${id}`);
      } else {
        history.push(`/seller/products/viewproduct/${id}`);
      }
    }
  };
  const editProduct = (id) => {
    const product = products.find((prod) => prod._id === id);
    if (product.eventCategory === "event") {
      const startDate = product?.startDate;
      const formattedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm");
      setProductObj({
        ...productObj,
        ...product,
        id,
        startDate: formattedStartDate,
      });
      setActionType("edit");
      eventProductModal.current.show();
    } else {
      if (userType === "admin") {
        history.push(`/admin/products/edit/${id}`);
      } else {
        history.push(`/seller/products/editproduct/${id}`);
      }
    }
  };
  const handleOnSubmit = async (data) => {
    const {
      name,
      description,
      startDate,
      eventCategory,
      images,
      dynamicPrice,
    } = data;
    if (
      !name ||
      !description ||
      !startDate ||
      !eventCategory ||
      !images?.length ||
      !dynamicPrice.currentPrice
    ) {
      return toast.error("All fields are required");
    }
    if (actionType === "edit") {
      await dispatch(updateProduct(data));
    }
    eventProductModal.current.hide();
    setProductObj({
      ...data,
      id: "",
      name: "",
      description: "",
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      eventCategory: "event",
      regularPrice: 1,
      images: [],
      dynamicPrice: { currentPrice: 1 },
    });
    dispatch(getProducts());
  };

  return (
    <div className="animated fadeIn dialog-Overlay">
      <ToastContainer autoClose={20000} />
      <AddEditModal
        ref={eventProductModal}
        eventContent={productObj}
        type={actionType}
        onSubmit={handleOnSubmit}
      />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={approveProductModal}
        title={
          <b
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "14px",
              margin: "8px",
            }}
          >
            Are you sure you want to Approve or Reject this Product?
          </b>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          {products?.find((item) => selectedId === item._id)?.status !==
            "Active" && (
            <>
              <Button
                type="submit"
                onClick={approveProduct}
                size="md"
                color="warning"
              >
                Approve
              </Button>
              <Col xs="12" sm="1"></Col>
            </>
          )}
          {products?.find((item) => selectedId === item._id)?.status !==
            "Rejected" && (
            <Button
              type="submit"
              size="md"
              onClick={rejectProduct}
              color="danger"
            >
              Reject
            </Button>
          )}
        </Row>
      </SkyLight>
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={deleteProductModal}
        title={
          <b style={{ display: "flex", justifyContent: "center" }}>
            <h4>Are you sure you want to delete this Product?</h4>
          </b>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteProductModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <div className=" d-flex justify-content-between align-items-center">
                <div>
                  <b style={{ fontSize: "14px" }}>
                    {productTranslation?.list
                      ? productTranslation?.list
                      : "Product List"}
                  </b>
                </div>
                <div>
                  {userType === "seller" && (
                    <Button
                      size="sm"
                      // style={divStyle}
                      style={{ padding: "10px 5px" }}
                      color="warning"
                      onClick={() => history.push("/seller/products/add")}
                    >
                      <b>
                        {productTranslation?.addButton
                          ? productTranslation?.addButton
                          : "Add Product"}
                      </b>
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder={
                        productTranslation?.search
                          ? productTranslation?.search
                          : "search ..."
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
              <br />
              <div className="tableFixHead">
                <Table hover bordered striped responsive size="sm">
                  <thead align="center" style={{ position: "sticky" }}>
                    <tr className="header-color">
                      <th>
                        {productTranslation?.name
                          ? productTranslation?.name
                          : "Name"}{" "}
                      </th>
                      <th>
                        {productTranslation?.brand
                          ? productTranslation?.brand
                          : "Brand"}
                      </th>
                      <th>
                        {productTranslation?.stockQuantity
                          ? productTranslation?.stockQuantity
                          : "Stock Quantity"}
                      </th>
                      <th>MRPS</th>
                      <th>
                        {productTranslation?.salePrice
                          ? productTranslation?.salePrice
                          : "Sale Price"}
                      </th>
                      <th>
                        {productTranslation?.shippingCharges
                          ? productTranslation?.shippingCharges
                          : "Shipping Charges"}
                      </th>
                      <th>
                        {productTranslation?.action
                          ? productTranslation?.action
                          : "Action"}{" "}
                      </th>
                      <th>
                        {productTranslation?.status
                          ? productTranslation?.status
                          : "Status"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <th colSpan="8" className="py-5">
                          <div style={{ textAlign: "center" }}>
                            <i
                              className="fas fa-spinner fa-pulse fa-2x"
                              style={{ color: "green" }}
                            ></i>
                          </div>
                        </th>
                      </tr>
                    ) : products.length > 0 && !loading ? (
                      products.map((item, index) => {
                        const oldItem = item.translatedArray.find(
                          (item) => item.language === userLanguage
                        );
                        return (
                          <tr
                            hidden={item.product_type == "digital"}
                            key={index}
                          >
                            <td>{oldItem?.name ? oldItem?.name : item.name}</td>
                            <td>
                              {oldItem?.brand ? oldItem?.brand : item.brand}
                            </td>
                            <td align="center">
                              {item.eventCategory === "event"
                                ? "\u221E"
                                : item.stockQuantity}
                            </td>
                            <td align="center">$ {item.regularPrice}</td>
                            <td align="center">$ {item.salesPrice}</td>
                            <td align="center">$ {item.shippingCharges}</td>
                            <td align="center">
                              <i
                                className="fa fa-eye fa-lg mt-4"
                                id={`view-${item._id}`}
                                onClick={(e) => showProduct(item._id)}
                                style={{ cursor: "pointer" }}
                              ></i>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen.view[item._id]}
                                target={`view-${item._id}`}
                                toggle={() => toggle("view", item._id)}
                                className="custom-tooltip"
                              >
                                View Product
                              </Tooltip>

                              <i
                                className="fa fa-edit fa-lg mt-4"
                                id={`edit-${item._id}`}
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "8px",
                                  marginRight: "8px",
                                }}
                                onClick={(e) => editProduct(item._id, index)}
                              ></i>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen.edit[item._id]}
                                target={`edit-${item._id}`}
                                toggle={() => toggle("edit", item._id)}
                                className="custom-tooltip"
                              >
                                Edit Product
                              </Tooltip>

                              <i
                                className="fa fa-trash fa-lg mt-4"
                                id={`delete-${item._id}`}
                                onClick={(e) => deleteModal(item._id)}
                                style={{ cursor: "pointer" }}
                              ></i>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipOpen.delete[item._id]}
                                target={`delete-${item._id}`}
                                toggle={() => toggle("delete", item._id)}
                                className="custom-tooltip"
                              >
                                Delete Product
                              </Tooltip>
                            </td>
                            {userType === "seller" && (
                              <td
                                align="center"
                                style={{
                                  color:
                                    item.status ===
                                    `${
                                      productTranslation?.rejected
                                        ? productTranslation?.rejected
                                        : "Rejected"
                                    }`
                                      ? "red"
                                      : item.status ===
                                        `${
                                          productTranslation?.active
                                            ? productTranslation?.active
                                            : "Actives"
                                        }`
                                      ? "green"
                                      : "",
                                }}
                              >
                                {item.status === "Pending"
                                  ? `${
                                      otherOrderTranslation?.pending
                                        ? otherOrderTranslation?.pending
                                        : "Pending"
                                    }`
                                  : item.status === "Active"
                                  ? `${
                                      productTranslation?.active
                                        ? productTranslation?.active
                                        : "Active"
                                    }`
                                  : `${
                                      productTranslation?.rejected
                                        ? productTranslation?.rejected
                                        : "Rejected"
                                    }`}
                              </td>
                            )}
                            {userType === "admin" && (
                              <td align="center">
                                <Button
                                  // disabled={item.status !== "Pending"}
                                  onClick={() => {
                                    approveModal(item._id);
                                  }}
                                  style={{
                                    backgroundColor:
                                      item.status ===
                                      `${
                                        productTranslation?.pending
                                          ? productTranslation?.pending
                                          : "Pending"
                                      }`
                                        ? "#FFCC00"
                                        : item.status ===
                                          `${
                                            productTranslation?.active
                                              ? productTranslation?.active
                                              : "Active"
                                          }`
                                        ? "green"
                                        : "red",
                                    color: "white",
                                  }}
                                >
                                  {item.status ===
                                  `${
                                    productTranslation?.pending
                                      ? productTranslation?.pending
                                      : "Pending"
                                  }`
                                    ? item.status
                                    : item.status ===
                                      `${
                                        productTranslation?.active
                                          ? productTranslation?.active
                                          : "Active"
                                      }`
                                    ? `${
                                        productTranslation?.approved
                                          ? productTranslation?.approved
                                          : "Approved"
                                      }`
                                    : item.status}
                                </Button>
                              </td>
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={8} className="py-5">
                          <div style={{ textAlign: "center" }}>
                            <p style={{ color: "red" }}>
                              {productTranslation?.notAvailable
                                ? productTranslation?.notAvailable
                                : "Products not Available"}
                            </p>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              <nav className="mt-3">
                <Pagination>
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (pageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      {reportingTranslation?.previousButton
                        ? reportingTranslation?.previousButton
                        : "Prev"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="ml-2 mr-2"
                    >
                      {reportingTranslation?.nextButton
                        ? reportingTranslation?.nextButton
                        : "Next"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        {productTranslation?.itemPerPage
                          ? productTranslation?.itemPerPage
                          : "Items per Page"}
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ProductList;
