import React, { useEffect, useState, useRef } from "react";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addFilter,
  claimEVoucher,
  createEVoucher,
  createPayoutRequest,
  getPayouts,
  getVouchers,
  updateVoucher,
} from "../../redux/reducers/seller";
import { getTranslation, getWallet } from "../../redux/reducers/user";
import {
  approvePayment,
  getAllUsers,
  addFilter as addFilterByAdmin,
} from "../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SkyLight from "react-skylight";
import moment from "moment";
import "./eVoucher.css";
import { nextPagesItems } from "../../helpers/custom";

var divStyle = {
  // marginLeft: "10%",
};

const Evoucher = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const modal = useRef(null);
  const sendModal = useRef(null);
  const claimModal = useRef(null);

  const { wallet, translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const eVoucherTranslation =
    translationState?.[0]?.translations?.eVoucher?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const contectUsTranslation =
    translationState?.[0]?.translations?.contectUs?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const { allVouchers, filters, count, loading } = useSelector(
    (state) => state.seller
  );
  const { users, loading: AdminLoading } = useSelector((state) => state.admin);
  const {
    user: { userType, id },
  } = useSelector((state) => state.auth);
  const { value: perPage } = filters.find((item) => item.field === "perPage");
  const { value: pageNo } = filters.find((item) => item.field === "pageNo");
  const [selectedPerPage, setSelectedPerPage] = useState(perPage);
  const [claimVoucherNumber, setClaimVoucherNumber] = useState("");
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [itemsPerPageOptions, setItemsPerPageOptions] = useState([
    5, 10, 50, 100,
  ]);
  const [pageContent, setPageContent] = useState({
    amount: 100,
    expiryDate: "",
  });

  useEffect(() => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: 5,
      })
    );
    setSelectedPerPage(5);
    dispatch(getVouchers());
    dispatch(getWallet());
  }, []);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const handleClaimInput = (value) => {
    setClaimVoucherNumber(value);
  };

  const onCreateVoucher = () => {
    modal.current.show();
  };
  const onClaimVoucher = () => {
    claimModal.current.show();
  };

  const onSendVoucher = (voucher, loginUserId) => {
    setSelectedVoucher(voucher);
    if (voucher.receiverId === loginUserId) {
      const data = {
        amount: voucher.amount,
        voucherId: voucher._id,
        status: "used",
      };
      dispatch(updateVoucher(data)).then(() => {
        dispatch(getVouchers());
        dispatch(getWallet());
      });
    } else {
      dispatch(
        addFilterByAdmin({
          field: "perPage",
          value: "all",
        })
      );
      dispatch(getAllUsers());
      sendModal.current.show();
    }
  };

  const onSubmitVoucher = async () => {
    const { amount, expiryDate } = pageContent;
    if (!amount || !expiryDate) {
      toast.error(
        `${
          toastTranslation?.fieldsError
            ? toastTranslation?.fieldsError
            : "Please fill all fields"
        }`
      );
    } else {
      if (amount < 0 || wallet.coinBalance < amount) {
        toast.error(
          `${
            toastTranslation?.lowBlance
              ? toastTranslation?.lowBlance
              : "Wallet doesn't have enough yinn coins"
          }`
        );
        return;
      } else {
        const data = {
          amount,
          expiryDate,
        };
        dispatch(createEVoucher(data)).then(() => {
          dispatch(getVouchers());
          dispatch(getWallet());
        });
        setPageContent({
          expiryDate: "",
          amount: 100,
        });
        modal.current.hide();
      }
    }
  };

  const onSubmitClaimVoucher = async () => {
    if (!claimVoucherNumber) {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Please fill voucher number"
        }`
      );
    } else {
      const data = {
        claimVoucherNumber,
      };
      dispatch(claimEVoucher(data)).then(() => {
        dispatch(getVouchers());
        dispatch(getWallet());
      });
      setClaimVoucherNumber("");
      claimModal.current.hide();
    }
  };

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getVouchers());
  };
  const searchUsersElement = (evt) => {
    dispatch(
      addFilterByAdmin({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilterByAdmin({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(
      addFilterByAdmin({
        field: "perPage",
        value: "all",
      })
    );
    dispatch(getAllUsers());
  };

  const nextCount = nextPagesItems(Math.abs(pageNo - 1), perPage, count);
  const clickableNext = nextCount > perPage;
  const paginate = (pageNo) => {
    if (pageNo > 0 && nextCount > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(
        addFilter({
          field: "perPage",
          value: perPage,
        })
      );
      dispatch(getVouchers());
      dispatch(getWallet());
    }
  };

  const perPageHandler = (e, pageNo) => {
    setSelectedPerPage(Number(e.target.value));
    dispatch(
      addFilter({
        field: "pageNo",
        value: pageNo,
      })
    );
    dispatch(
      addFilter({
        field: "perPage",
        value: Number(e.target.value),
      })
    );
    dispatch(getVouchers());
    dispatch(getWallet());
  };

  const sentVoucherHandler = (voucherId, receiverId) => {
    const data = {
      voucherId,
      receiverId,
      status: "sent",
    };
    dispatch(updateVoucher(data)).then(() => {
      sendModal.current.hide();
      dispatch(getVouchers());
      dispatch(getWallet());
    });
  };

  const myBigGreenDialog = {
    minwidth: "40%",
    minHeight: "20%",
  };

  const myClaimDialog = {
    minwidth: "40%",
    minHeight: "20%",
  };

  const myBigUserShowDialog = {
    width: "70%",
    minHeight: "60%",
  };
  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={modal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {eVoucherTranslation?.eVoucher
                  ? eVoucherTranslation?.eVoucher
                  : "E-Voucher"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <Row>
              <Label>
                {eVoucherTranslation?.coin
                  ? eVoucherTranslation?.coin
                  : "Coins"}
              </Label>
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  placeholder="Amount"
                  name="amount"
                  value={pageContent.amount}
                  onChange={handleInputChange}
                  type="number"
                />
              </InputGroup>
            </Row>
          </FormGroup>
          <FormGroup>
            <Label for="expiryDate">
              {eVoucherTranslation?.expireDate
                ? eVoucherTranslation?.expireDate
                : "Expiry Date"}
            </Label>
            <Input
              id="expiryDate"
              name="expiryDate"
              placeholder="date placeholder"
              type="date"
              min={new Date().toISOString().split("T")[0]}
              value={pageContent?.expiryDate}
              onChange={handleInputChange}
            />
          </FormGroup>
          <Row>
            <Button
              onClick={onSubmitVoucher}
              color="warning"
              style={divStyle}
              btn="sm"
            >
              <b>
                {eVoucherTranslation?.createVoucher
                  ? eVoucherTranslation?.createVoucher
                  : "Create"}
              </b>
            </Button>
          </Row>
        </div>
      </SkyLight>
      <SkyLight
        dialogStyles={myClaimDialog}
        hideOnOverlayClicked
        ref={claimModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>
                {eVoucherTranslation?.claim
                  ? eVoucherTranslation?.claim +
                    " " +
                    eVoucherTranslation?.eVoucher
                  : "Claim E-Voucher"}
              </h4>
            </b>
          </p>
        }
      >
        <div>
          <FormGroup>
            <Row>
              <Label>
                {eVoucherTranslation?.voucherNo
                  ? eVoucherTranslation?.voucherNo
                  : "Voucher Number"}
              </Label>
              <InputGroup className="input-prepend">
                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                <Input
                  placeholder={
                    eVoucherTranslation?.voucherNo
                      ? eVoucherTranslation?.voucherNo
                      : "Voucher Number"
                  }
                  name="voucherNumber"
                  value={claimVoucherNumber}
                  onChange={(e) => handleClaimInput(e.target.value)}
                  type="number"
                />
              </InputGroup>
            </Row>
          </FormGroup>
          <Row>
            <Button
              onClick={onSubmitClaimVoucher}
              color="warning"
              style={divStyle}
              btn="sm"
            >
              <b>
                {eVoucherTranslation?.claim
                  ? eVoucherTranslation?.claim
                  : "Claim"}
              </b>
            </Button>
          </Row>
        </div>
      </SkyLight>
      <SkyLight
        dialogStyles={myBigUserShowDialog}
        hideOnOverlayClicked
        ref={sendModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h3>
                {eVoucherTranslation?.allUser
                  ? eVoucherTranslation?.allUser
                  : "All Users"}
              </h3>
            </b>
          </p>
        }
      >
        <Row>
          <Col xs="12" sm="4">
            <InputGroup>
              <Input
                type="text"
                id="input1-group2"
                onChange={searchUsersElement}
                className="search-color mb-3"
                name="input1-group2"
                placeholder=" Search by email/name"
              />
            </InputGroup>
          </Col>
        </Row>
        <div>
          <FormGroup>
            <Row className="user-table">
              <Table
                hover
                bordered
                striped
                responsive
                size="sm"
                className={"heheh"}
              >
                <thead align="center">
                  <tr className="header-color">
                    <th>
                      {" "}
                      {shippingCartTranslation?.firsrtName
                        ? shippingCartTranslation?.firsrtName
                        : "First Name"}
                    </th>
                    <th>
                      {" "}
                      {shippingCartTranslation?.lastName
                        ? shippingCartTranslation?.lastName
                        : "Last Name"}
                    </th>
                    <th>
                      {" "}
                      {sellerHomeTranslation?.email
                        ? sellerHomeTranslation?.email
                        : "Email"}
                    </th>
                    <th>
                      {" "}
                      {sellerHomeTranslation?.dob
                        ? sellerHomeTranslation?.dob
                        : "Date of Birth"}
                    </th>
                    <th>
                      {" "}
                      {productTranslation?.action
                        ? productTranslation?.action
                        : "Action"}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {AdminLoading ? (
                    <td colSpan={6}>
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : users.length > 0 && !AdminLoading ? (
                    users.map((user, ind) => (
                      <tr align="center" key={ind}>
                        <td> {user?.firstname} </td>
                        <td> {user?.lastname} </td>
                        <td> {user?.email} </td>
                        <td>{moment(user.dateOfBirth).format("MM-DD-YYYY")}</td>
                        <td>
                          <Button
                            color="warning"
                            style={divStyle}
                            btn="sm"
                            disabled={user._id === id}
                            onClick={(e) =>
                              sentVoucherHandler(selectedVoucher?._id, user._id)
                            }
                          >
                            <b>
                              {contectUsTranslation?.button
                                ? contectUsTranslation?.button
                                : "Send"}
                            </b>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td colSpan={6} className="py-5 text-center">
                      <p style={{ color: "red" }}>Records Not Found</p>
                    </td>
                  )}
                </tbody>
              </Table>
            </Row>
          </FormGroup>
        </div>
      </SkyLight>
      <Row className="mt-2">
        <div className="top-message">
          <small>
            {eVoucherTranslation?.paraOne
              ? eVoucherTranslation?.paraOne
              : "Transfer money to a love one or business partner by creating E-voucher and share reference number to claim and redeem and you need sufficient Yinncoin in your wallet and the receiver need to have a valid YINN account to redeem/Claim the E-voucher"}
          </small>
        </div>
        <Col className="padding-remove">
          <Card className="mx-2">
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {eVoucherTranslation?.eVoucher
                      ? eVoucherTranslation?.eVoucher
                      : "E-Vouchers"}
                  </b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              {(userType == "seller" || userType == "user") && (
                <Row>
                  <Col xs="6" sm="6" className="padding-remove">
                    <InputGroup>
                      <Button
                        color="warning"
                        style={divStyle}
                        btn="sm"
                        onClick={onCreateVoucher}
                      >
                        <b>
                          {eVoucherTranslation?.createVoucher
                            ? eVoucherTranslation?.createVoucher
                            : "Create Voucher"}
                        </b>
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col xs="6" sm="6" className="padding-remove">
                    <InputGroup className={"justify"}>
                      <Button color="warning" btn="sm" onClick={onClaimVoucher}>
                        <b>
                          {eVoucherTranslation?.claim
                            ? eVoucherTranslation?.claim
                            : "Claim"}
                        </b>
                      </Button>
                    </InputGroup>
                  </Col>
                </Row>
              )}
              {userType == "admin" && (
                <Row>
                  <Col xs="12" sm="4">
                    <InputGroup>
                      <Input
                        type="text"
                        id="input1-group2"
                        onChange={searchElement}
                        className="search-color"
                        name="input1-group2"
                        placeholder=" Search by email/name"
                      />
                    </InputGroup>
                  </Col>
                </Row>
              )}
              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th>
                      {" "}
                      {eVoucherTranslation?.voucherNo
                        ? eVoucherTranslation?.voucherNo
                        : "Voucher Number"}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.coin
                        ? eVoucherTranslation?.coin
                        : "Coins"}{" "}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.status
                        ? eVoucherTranslation?.status
                        : "Status"}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.createDate
                        ? eVoucherTranslation?.createDate
                        : "Created Date"}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.expireDate
                        ? eVoucherTranslation?.expireDate
                        : "Expiry Date"}
                    </th>
                    <th>
                      {" "}
                      {eVoucherTranslation?.action
                        ? eVoucherTranslation?.action
                        : "Action"}{" "}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <td
                      colSpan={6}
                      align="center"
                      style={{ verticalAlign: "middle" }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <i
                          className="fas fa-spinner fa-pulse fa-2x"
                          style={{ color: "green", margin: "80px" }}
                        ></i>
                      </div>
                    </td>
                  ) : allVouchers.length > 0 && !loading ? (
                    allVouchers.map((item, ind) => (
                      <tr align="center" key={ind}>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {" "}
                          {item?.voucherNumber}{" "}
                        </td>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {" "}
                          {item?.amount}{" "}
                        </td>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {" "}
                          {item?.status}{" "}
                        </td>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {moment(item.createdAt).format("MM-DD-YYYY")}
                        </td>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {moment(item.expiryDate).format("MM-DD-YYYY")}
                        </td>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          <Button
                            color="warning"
                            style={divStyle}
                            btn="sm"
                            value={item.status}
                            disabled={
                              (item.status === "sent" &&
                                item.receiverId !== id) ||
                              item.status === "used" ||
                              item.status === "expired"
                            }
                            onClick={(e) => onSendVoucher(item, id)}
                          >
                            <b>
                              {" "}
                              {(item?.status === "sent" &&
                                item?.receiverId === id) ||
                              (item?.status === "used" &&
                                item?.receiverId === id) ||
                              (item?.status === "expired" &&
                                item?.receiverId === id)
                                ? "Redeem"
                                : `${
                                    contectUsTranslation?.button
                                      ? contectUsTranslation?.button
                                      : "Send"
                                  }`}
                            </b>
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <td
                      colSpan={6}
                      className="py-5 text-center"
                      align="center"
                      style={{ verticalAlign: "middle" }}
                    >
                      <p style={{ color: "red" }}>
                        {walletTranslation?.notFound
                          ? walletTranslation?.notFound
                          : "Records Not Found"}
                      </p>
                    </td>
                  )}
                </tbody>
              </Table>
              <nav>
                <Pagination className="mt-3">
                  <PaginationItem>
                    <PaginationLink
                      onClick={(e) => paginate(pageNo - 1)}
                      disabled={pageNo === 1 ? true : false}
                      style={{
                        backgroundColor:
                          (pageNo === 1 ? true : false) && "#e4e7ea",
                      }}
                    >
                      {reportingTranslation?.previousButton
                        ? reportingTranslation?.previousButton
                        : "Prev"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => paginate(pageNo + 1)}
                      disabled={!clickableNext}
                      style={{
                        backgroundColor: !clickableNext ? "#e4e7ea" : null,
                      }}
                      className="mx-2"
                    >
                      {reportingTranslation?.nextButton
                        ? reportingTranslation?.nextButton
                        : "Next"}
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem className={"ml-auto"}>
                    <PaginationLink
                      className="rounded"
                      style={{
                        color: "black",
                        hover: false,
                        backgroundColor: "white",
                      }}
                    >
                      <div>
                        {productTranslation?.itemPerPage
                          ? productTranslation?.itemPerPage
                          : "Items per Page"}
                        <select
                          className="ml-2"
                          value={selectedPerPage}
                          onChange={(e) => perPageHandler(e, pageNo)}
                        >
                          {itemsPerPageOptions.map((option, i) => (
                            <option key={i} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default Evoucher;
