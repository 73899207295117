import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import Config from "../../../../config/aws";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import {
  addFilter,
  carbonEmissionData,
  createProduct,
  digitalcarbonEmission,
  getCategoriesByType,
} from "../../../../redux/reducers/seller";
import "./Add.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Gerne from "../../../../assets/json/gerne";
import videoquality from "../../../../assets/json/videoquality";
import Language from "../../../../assets/language/language";
import Mp3 from "../../../../assets/json/mp3";
import SoftwareCategory from "../../../../assets/json/softwares";
import TheaterType from "../../../../assets/json/theaterType";
import {
  getPresigedUrl,
  uploadToS3ViaSignedUrl,
} from "../../../../helpers/s3Utils";
import {
  APPSoftware,
  Ebook,
  Games,
  Movies,
  Music,
  StageArt,
  TVSeries,
} from "../ConstantCategory";

const AddDigitalProduct = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const bookUrlRef = useRef(null);
  const bookCoverUrlRef = useRef(null);
  const trailerUrlRef = useRef(null);
  const [formError, setFormError] = useState(false);
  const urlArray = history.location.pathname.split("/");
  const digitalType = urlArray[urlArray.length - 2];
  const { loading, productCategories, digitalcarbonemission } = useSelector(
    (state) => state.seller
  );
  const [bookUrl, setBookUrl] = useState("");
  const [bookCoverUrl, setBookCoverUrl] = useState("");
  const [trailerUrl, setTrailerUrl] = useState("");
  const [progress, setProgress] = useState(0);
  const [pageContent, setPageContent] = useState({});
  const [selectedMainCategories, setSelectedMainCategories] = useState("");
  const [productDescriptionData, setProductDescriptionData] = useState("");
  const [description, setDescription] = useState({
    editorState: EditorState.createEmpty(),
  });

  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "digital",
      })
    );
    dispatch(getCategoriesByType());
  }, []);

  const { translationState } = useSelector((state) => state.user);

  const { userLanguage } = useSelector((state) => state.auth);

  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const otherDigitalProductTranslation =
    translationState?.[0]?.translations?.otherDigitalProduct?.[userLanguage];
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];

  const changeDescription = (editorState) => {
    setDescription({ editorState });
  };
  const handleMainCategory = (event) => {
    setSelectedMainCategories(event.target.value);
  };
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  const handleClick = () => {
    bookUrlRef.current.click();
  };
  const handleCoverClick = () => {
    bookCoverUrlRef.current.click();
  };
  const handleTrailerClick = () => {
    trailerUrlRef.current.click();
  };

  const uploadImage = async (blobs, type) => {
    for (let i = 0; i < blobs.length; i++) {
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        if (type === "book") {
          toast.success(
            `${
              toastTranslation?.success
                ? toastTranslation?.success
                : "uploaded successfully"
            }`
          );
          setBookUrl(imageUrl);
        } else if (type === "trailer") {
          toast.success(
            `${
              toastTranslation?.success
                ? toastTranslation?.success
                : "Trailer uploaded successfully"
            }`
          );
          setTrailerUrl(imageUrl);
        } else {
          toast.success(
            `${
              toastTranslation?.success
                ? toastTranslation?.success
                : "Cover uploaded successfully"
            }`
          );
          setBookCoverUrl(imageUrl);
        }
      } catch (error) {
        toast.error(
          `${
            toastTranslation?.error
              ? toastTranslation?.error
              : "Error in file uploading"
          }`
        );
      }
    }
  };
  const uploadBook = (event) => {
    uploadImage(event.target.files, "book");
  };
  const uploadTrailer = (event) => {
    uploadImage(event.target.files, "trailer");
  };
  const uploadBookCover = (event) => {
    uploadImage(event.target.files, "bookCover");
  };

  const save = async () => {
    const specification = convertToRaw(
      description.editorState.getCurrentContent()
    );
    setProductDescriptionData(specification?.blocks[0]?.text);

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    const capitalizedDigitalType = capitalizeFirstLetter(digitalType);
    let responseDigitalCarbon = await dispatch(
      digitalcarbonEmission({
        product_category: capitalizedDigitalType,
        product_type: pageContent.selectedOption,
      })
    );

    const {
      name,
      authorName,
      bookLength,
      language,
      director,
      runtime,
      releaseDate,
      seasonLength,
      gerne,
      videoQuality,
      productionName,
      regularPrice,
      stockQuantity,
      offeredBy,
      gameSize,
      requireDevice,
      singerName,
      audioQuality,
      softwareSize,
      softwareCategory,
      theaterType,
      environmentStory,
      investmentStory,
      productStory,
    } = pageContent;
    if (
      name &&
      bookUrl &&
      bookCoverUrl &&
      language &&
      regularPrice &&
      stockQuantity &&
      selectedMainCategories &&
      environmentStory &&
      investmentStory &&
      productStory
    ) {
      setFormError(false);
      if (digitalType === "series") {
        if (
          !seasonLength ||
          !director ||
          !releaseDate ||
          !productionName ||
          !gerne ||
          !videoQuality ||
          !trailerUrl
        ) {
          setFormError(true);
          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "movie") {
        if (
          !runtime ||
          !director ||
          !releaseDate ||
          !productionName ||
          !gerne ||
          !videoQuality ||
          !trailerUrl
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "ebook") {
        if (!bookLength || !authorName) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "game") {
        if (!offeredBy || !gameSize || !releaseDate || !requireDevice) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "music") {
        if (
          !singerName ||
          !runtime ||
          !releaseDate ||
          !productionName ||
          !audioQuality
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "software") {
        if (
          !offeredBy ||
          !softwareSize ||
          !releaseDate ||
          !requireDevice ||
          !softwareCategory
        ) {
          setFormError(true);

          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      } else if (digitalType === "theater") {
        if (
          !director ||
          !runtime ||
          !releaseDate ||
          !theaterType ||
          !videoQuality ||
          !trailerUrl
        ) {
          setFormError(true);
          toast.error(
            `${
              toastTranslation?.requried
                ? toastTranslation?.requried
                : "Kindly fill the required fields"
            }`
          );
          return;
        }
      }
    } else {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Kindly fill the required fields"
        }`
      );
      setFormError(true);
      return;
    }
    setFormError(false);
    const data = {
      ...pageContent,
      specification,
      productUrl: bookUrl,
      trailerUrl: trailerUrl,
      mainCategory: selectedMainCategories,
      images: [bookCoverUrl],
      digitalType: digitalType,
      dynamicPrice: {
        basePrice: pageContent.regularPrice,
        currentPrice: pageContent.regularPrice,
      },
      carbonEmissionDigital: responseDigitalCarbon.payload.payload,
      productType: "digital",
    };
    dispatch(createProduct(data)).then(() =>
      history.push(`/seller/digitalproduct/${digitalType}`)
    );
  };

  const digitalOptions = {
    ebook: Ebook,
    music: Music,
    movie: Movies,
    game: Games,
    series: TVSeries,
    software: APPSoftware,
    theater: StageArt,
  };

  return (
    <div className="animated fadeIn">
      {/* layout for prising table */}
      <ToastContainer autoClose={20000} />
      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5">
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <i className="fa fa-align-justify"></i>
                  <b style={{ textTransform: "capitalize" }} className="ml-2">
                    {otherDigitalProductTranslation?.uploadMusic
                      ? otherDigitalProductTranslation?.uploadMusic
                      : "Upload New " + `${digitalType}`}
                  </b>
                </Col>
                <Col xs="12" sm="2">
                  <Button
                    size="sm"
                    onClick={() =>
                      history.push(`/seller/digitalproduct/${digitalType}`)
                    }
                    color="warning"
                  >
                    <b>
                      {residencySwapTranslation?.viewDetail
                        ? residencySwapTranslation?.viewDetail
                        : "View All"}
                    </b>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label
                      htmlFor="exampleInputName2"
                      className="pr-1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {digitalProductTranslation?.title
                        ? digitalProductTranslation?.title
                        : `${digitalType}` + "Title"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.name && formError && "form-error"
                      }`}
                      name="name"
                      onChange={handleInputChange}
                      value={pageContent.name}
                      type="text"
                      placeholder={
                        digitalProductTranslation?.title
                          ? digitalProductTranslation?.title
                          : `${digitalType}` + "Title"
                      }
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label
                      htmlFor="exampleInputName2"
                      className="pr-1"
                      style={{ textTransform: "capitalize" }}
                    >
                      {digitalProductTranslation?.description
                        ? digitalProductTranslation?.description
                        : `${digitalType}` + "Description"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <div>
                      <Editor
                        toolbarClassName="toolbar-class"
                        placeholder="Product Description"
                        wrapperClassName="demo-wrapper"
                        editorClassName="editer-content"
                        editorStyle={{
                          border: `${
                            formError && !productDescriptionData
                              ? "2px solid red"
                              : ""
                          }`,
                        }}
                        editorState={description.editorState}
                        onEditorStateChange={changeDescription}
                      />
                    </div>
                  </Col>
                </Row>
              </FormGroup>
              {(digitalType === "movie" || digitalType === "series") && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {missedTranslation?.director
                            ? missedTranslation?.director
                            : "Director"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.director && formError && "form-error"
                          }`}
                          name="director"
                          onChange={handleInputChange}
                          value={pageContent?.director}
                          type="text"
                          placeholder={
                            missedTranslation?.director
                              ? missedTranslation?.director
                              : "Director"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {digitalType === "movie" && (
                    <>
                      <FormGroup className="pr-1">
                        <Row>
                          <Col lg="3">
                            <Label className="pr-1">
                              {digitalProductTranslation?.Length
                                ? digitalProductTranslation?.Length
                                : "Movie Length"}
                            </Label>
                          </Col>
                          <Col lg="9">
                            <Input
                              className={`input-prepend ${
                                !pageContent?.runtime &&
                                formError &&
                                "form-error"
                              }`}
                              name="runtime"
                              onChange={handleInputChange}
                              value={pageContent.runtime}
                              type="number"
                              min={0}
                              placeholder={
                                digitalProductTranslation?.Length
                                  ? digitalProductTranslation?.Length
                                  : "Movie Length"
                              }
                              required
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  )}
                  {digitalType === "series" && (
                    <>
                      <FormGroup className="pr-1">
                        <Row>
                          <Col lg="3">
                            <Label className="pr-1">
                              {otherDigitalProductTranslation?.noOfSeason
                                ? otherDigitalProductTranslation?.noOfSeason
                                : "Number of Seasons"}
                            </Label>
                          </Col>
                          <Col lg="9">
                            <Input
                              className={`input-prepend ${
                                !pageContent?.seasonLength &&
                                formError &&
                                "form-error"
                              }`}
                              name="seasonLength"
                              onChange={handleInputChange}
                              value={pageContent.seasonLength}
                              type="number"
                              min={0}
                              placeholder={
                                otherDigitalProductTranslation?.noOfSeason
                                  ? otherDigitalProductTranslation?.noOfSeason
                                  : "Seasons"
                              }
                              required
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </>
                  )}
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.releaseDate
                            ? otherDigitalProductTranslation?.releaseDate
                            : "Release Date"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          name="releaseDate"
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder={
                            otherDigitalProductTranslation?.releaseDate
                              ? otherDigitalProductTranslation?.releaseDate
                              : "Release Date"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {missedTranslation?.film
                            ? missedTranslation?.film
                            : "Filming and production"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.productionName &&
                            formError &&
                            "form-error"
                          }`}
                          name="productionName"
                          value={pageContent.productionName}
                          onChange={handleInputChange}
                          type="text"
                          placeholder={
                            missedTranslation?.film
                              ? missedTranslation?.film
                              : "Filming and production"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.gerne
                            ? otherDigitalProductTranslation?.gerne
                            : "Gerne"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.gerne && formError && "form-error"
                          }`}
                          type="select"
                          name="gerne"
                          value={pageContent.gerne}
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.gerne
                              ? otherDigitalProductTranslation?.gerne
                              : "Gerne"}
                          </option>
                          {Gerne
                            ? Gerne.map((ct, i) => <option>{ct.name}</option>)
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.quanlity
                            ? otherDigitalProductTranslation?.quanlity
                            : "Video quality"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.videoQuality &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="videoQuality"
                          value={pageContent.videoQuality}
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Select Video quality"}
                          </option>
                          {videoquality
                            ? videoquality.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "ebook" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          {digitalProductTranslation?.authorName
                            ? digitalProductTranslation?.authorName
                            : "Author Name"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.authorName &&
                            formError &&
                            "form-error"
                          }`}
                          name="authorName"
                          onChange={handleInputChange}
                          value={pageContent.authorName}
                          type="text"
                          placeholder={
                            digitalProductTranslation?.authorName
                              ? digitalProductTranslation?.authorName
                              : "Author Name"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          {missedTranslation?.book
                            ? missedTranslation?.book
                            : "Book Length"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.bookLength &&
                            formError &&
                            "form-error"
                          }`}
                          name="bookLength"
                          onChange={handleInputChange}
                          value={pageContent.bookLength}
                          type="number"
                          min={0}
                          placeholder={
                            missedTranslation?.book
                              ? missedTranslation?.book
                              : "Book Length"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "game" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.offeredBy
                            ? otherDigitalProductTranslation?.offeredBy
                            : "Offered by"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.offeredBy && formError && "form-error"
                          }`}
                          name="offeredBy"
                          onChange={handleInputChange}
                          value={pageContent.offeredBy}
                          type="text"
                          placeholder={
                            otherDigitalProductTranslation?.offeredBy
                              ? otherDigitalProductTranslation?.offeredBy
                              : "Offered by"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.size
                            ? otherDigitalProductTranslation?.size
                            : "Size"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.gameSize && formError && "form-error"
                          }`}
                          name="gameSize"
                          onChange={handleInputChange}
                          value={pageContent.gameSize}
                          type="number"
                          min={0}
                          placeholder={
                            otherDigitalProductTranslation?.size
                              ? otherDigitalProductTranslation?.size
                              : "Size"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.releaseDate
                            ? otherDigitalProductTranslation?.releaseDate
                            : "Release Date"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder={
                            otherDigitalProductTranslation?.releaseDate
                              ? otherDigitalProductTranslation?.releaseDate
                              : "Release Date"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.supportedDevice
                            ? otherDigitalProductTranslation?.supportedDevice
                            : "Supported Device"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.requireDevice &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="requireDevice"
                          value={pageContent.requireDevice}
                          onChange={handleInputChange}
                        >
                          <option>Select Device</option>
                          <option>Android</option>
                          <option>IOS</option>
                          <option>Both android and ios</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "music" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.singer
                            ? otherDigitalProductTranslation?.singer
                            : "Singer"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.singerName &&
                            formError &&
                            "form-error"
                          }`}
                          name="singerName"
                          onChange={handleInputChange}
                          value={pageContent.singerName}
                          type="text"
                          placeholder={
                            otherDigitalProductTranslation?.singer
                              ? otherDigitalProductTranslation?.singer
                              : "Singer"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {digitalProductTranslation?.Length
                            ? digitalProductTranslation?.Length
                            : "Music Length"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.runtime && formError && "form-error"
                          }`}
                          name="runtime"
                          onChange={handleInputChange}
                          value={pageContent.runtime}
                          type="number"
                          min={0}
                          placeholder={
                            digitalProductTranslation?.Length
                              ? digitalProductTranslation?.Length
                              : "Music Length"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.releaseDate
                            ? otherDigitalProductTranslation?.releaseDate
                            : "Release Date"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder={
                            otherDigitalProductTranslation?.releaseDate
                              ? otherDigitalProductTranslation?.releaseDate
                              : "Release Date"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.prouction
                            ? otherDigitalProductTranslation?.prouction
                            : "production"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.productionName &&
                            formError &&
                            "form-error"
                          }`}
                          name="productionName"
                          value={pageContent.productionName}
                          type="text"
                          placeholder="Filming and production"
                          onChange={handleInputChange}
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.quanlity
                            ? otherDigitalProductTranslation?.quanlity
                            : "Quality"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.audioQuality &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="audioQuality"
                          value={pageContent.audioQuality}
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Select Quality"}
                          </option>
                          {Mp3
                            ? Mp3.map((ct, i) => <option>{ct.name}</option>)
                            : " not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "software" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.offeredBy
                            ? otherDigitalProductTranslation?.offeredBy
                            : "Offred by"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.offeredBy && formError && "form-error"
                          }`}
                          name="offeredBy"
                          onChange={handleInputChange}
                          value={pageContent.offeredBy}
                          type="text"
                          placeholder={
                            otherDigitalProductTranslation?.offeredBy
                              ? otherDigitalProductTranslation?.offeredBy
                              : "Offred by"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.size
                            ? otherDigitalProductTranslation?.size
                            : "Size"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.softwareSize &&
                            formError &&
                            "form-error"
                          }`}
                          name="softwareSize"
                          onChange={handleInputChange}
                          value={pageContent.softwareSize}
                          type="number"
                          min={0}
                          placeholder={
                            otherDigitalProductTranslation?.size
                              ? otherDigitalProductTranslation?.size
                              : "Size"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.releaseDate
                            ? otherDigitalProductTranslation?.releaseDate
                            : "Release Date"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          name="releaseDate"
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder={
                            otherDigitalProductTranslation?.releaseDate
                              ? otherDigitalProductTranslation?.releaseDate
                              : "Release Date"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.supportedDevice
                            ? otherDigitalProductTranslation?.supportedDevice
                            : "Supported Device"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          type="select"
                          className={`input-prepend ${
                            !pageContent?.requireDevice &&
                            formError &&
                            "form-error"
                          }`}
                          name="requireDevice"
                          value={pageContent.requireDevice}
                          required
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.supportedDevice
                              ? otherDigitalProductTranslation?.supportedDevice
                              : "Supported Device"}
                          </option>
                          <option>Android</option>
                          <option>IOS</option>
                          <option>Both android and ios</option>
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label htmlFor="exampleInputName2" className="pr-1">
                          {digitalProductTranslation?.category
                            ? digitalProductTranslation?.category
                            : "Category"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          type="select"
                          className={`input-prepend ${
                            !pageContent?.softwareCategory &&
                            formError &&
                            "form-error"
                          }`}
                          name="softwareCategory"
                          value={pageContent.softwareCategory}
                          onChange={handleInputChange}
                          required
                        >
                          <option>
                            {digitalProductTranslation?.category
                              ? digitalProductTranslation?.category
                              : "Select Category"}
                          </option>
                          {SoftwareCategory
                            ? SoftwareCategory.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Category not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              {digitalType === "theater" && (
                <>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {missedTranslation?.director
                            ? missedTranslation?.director
                            : "Director"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.director && formError && "form-error"
                          }`}
                          name="director"
                          onChange={handleInputChange}
                          value={pageContent.director}
                          type="text"
                          placeholder={
                            missedTranslation?.director
                              ? missedTranslation?.director
                              : "Director"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {digitalProductTranslation?.Length
                            ? digitalProductTranslation?.Length
                            : "Length"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          name="runtime"
                          className={`input-prepend ${
                            !pageContent?.runtime && formError && "form-error"
                          }`}
                          onChange={handleInputChange}
                          value={pageContent.runtime}
                          type="number"
                          min={0}
                          placeholder={
                            digitalProductTranslation?.Length
                              ? digitalProductTranslation?.Length
                              : "Stage Art Length in minutes"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.releaseDate
                            ? otherDigitalProductTranslation?.releaseDate
                            : "Release Date"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          name="releaseDate"
                          className={`input-prepend ${
                            !pageContent?.releaseDate &&
                            formError &&
                            "form-error"
                          }`}
                          onChange={handleInputChange}
                          value={pageContent.releaseDate}
                          type="date"
                          min="1700-01-02"
                          max="2099-12-31"
                          placeholder={
                            otherDigitalProductTranslation?.releaseDate
                              ? otherDigitalProductTranslation?.releaseDate
                              : "Release Date"
                          }
                          required
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.type
                            ? otherDigitalProductTranslation?.type
                            : "type"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          className={`input-prepend ${
                            !pageContent?.theaterType &&
                            formError &&
                            "form-error"
                          }`}
                          type="select"
                          name="theaterType"
                          value={pageContent.theaterType}
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.type
                              ? otherDigitalProductTranslation?.type
                              : "Select Theater Type"}
                          </option>
                          {TheaterType
                            ? TheaterType.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>

                  <FormGroup className="pr-1">
                    <Row>
                      <Col lg="3">
                        <Label className="pr-1">
                          {otherDigitalProductTranslation?.quanlity
                            ? otherDigitalProductTranslation?.quanlity
                            : "Video quality"}
                        </Label>
                      </Col>
                      <Col lg="9">
                        <Input
                          type="select"
                          className={`input-prepend ${
                            !pageContent?.videoQuality &&
                            formError &&
                            "form-error"
                          }`}
                          name="videoQuality"
                          value={pageContent.videoQuality}
                          onChange={handleInputChange}
                        >
                          <option>
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Select Video quality"}
                          </option>
                          {videoquality
                            ? videoquality.map((ct, i) => (
                                <option>{ct.name}</option>
                              ))
                            : "Language not found"}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </>
              )}
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.language
                        ? digitalProductTranslation?.language
                        : "Language"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.language && formError && "form-error"
                      }`}
                      type="select"
                      name="language"
                      value={pageContent.language}
                      onChange={handleInputChange}
                    >
                      <option>
                        {digitalProductTranslation?.language
                          ? digitalProductTranslation?.language
                          : "Language"}
                      </option>
                      {Language
                        ? Language.map((ct, i) => <option>{ct.name}</option>)
                        : "Language not found"}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.regularPrice
                        ? digitalProductTranslation?.regularPrice
                        : "Regular Price"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.regularPrice && formError && "form-error"
                      }`}
                      name="regularPrice"
                      onChange={handleInputChange}
                      value={pageContent.regularPrice}
                      type="number"
                      placeholder={
                        digitalProductTranslation?.regularPrice
                          ? digitalProductTranslation?.regularPrice
                          : "Regular Price"
                      }
                      required
                      min={0}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.stockQuantity
                        ? digitalProductTranslation?.stockQuantity
                        : "Stock Quantity"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !pageContent?.stockQuantity && formError && "form-error"
                      }`}
                      name="stockQuantity"
                      onChange={handleInputChange}
                      value={pageContent.stockQuantity}
                      type="number"
                      placeholder={
                        digitalProductTranslation?.stockQuantity
                          ? digitalProductTranslation?.stockQuantity
                          : "Stock Quantity"
                      }
                      required
                      min={0}
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.category
                        ? digitalProductTranslation?.category
                        : "Category"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <select
                      className="digital-product-select-box"
                      onChange={handleInputChange}
                      value={pageContent.selectedOption}
                      name="selectedOption"
                    >
                      <option value="" disabled selected>
                        {digitalType}
                      </option>
                      {digitalOptions[digitalType]?.map((item, index) => {
                        return <option>{item}</option>;
                      })}
                    </select>
                    {/* <p>Selected Value: {pageContent.selectedOption}</p> */}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.investment
                        ? digitalProductTranslation?.investment
                        : "Investment on product"}
                      <span
                        style={{
                          display: "block",
                          font: "small-caption",
                        }}
                      >
                        {digitalProductTranslation?.investmenttagline
                          ? digitalProductTranslation?.investmenttagline
                          : "(i.e can be the cost of production, cost of materials, cost of labor etc)"}
                      </span>
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      id="InvestmentText"
                      name="investmentStory"
                      placeholder={
                        digitalProductTranslation?.investment
                          ? digitalProductTranslation?.investment
                          : "Investment on product"
                      }
                      onChange={handleInputChange}
                      maxlength="30"
                      size="16"
                      type="number"
                      value={pageContent.investmentStory}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.environment
                        ? digitalProductTranslation?.environment
                        : "Impact on environment"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className="text-eara-product-story"
                      id="environmentText"
                      name="environmentStory"
                      placeholder={
                        digitalProductTranslation?.environment
                          ? digitalProductTranslation?.environment
                          : "Impact on environment"
                      }
                      onChange={handleInputChange}
                      maxlength="1000"
                      size="16"
                      type="textarea"
                      value={pageContent.environmentStory}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.productStory
                        ? digitalProductTranslation?.productStory
                        : " Brief Product Story"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className="text-eara-product-story"
                      id="productStoryText"
                      name="productStory"
                      placeholder={
                        digitalProductTranslation?.productStory
                          ? digitalProductTranslation?.productStory
                          : " Brief Product Story"
                      }
                      onChange={handleInputChange}
                      maxlength="1000"
                      size="16"
                      type="textarea"
                      value={pageContent.productStory}
                      required
                    />
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup className="pr-1">
                <Row>
                  <Col lg="3">
                    <Label htmlFor="exampleInputName2" className="pr-1">
                      {digitalProductTranslation?.mainCategory
                        ? digitalProductTranslation?.mainCategory
                        : "Main Category"}
                    </Label>
                  </Col>
                  <Col lg="9">
                    <Input
                      className={`input-prepend ${
                        !selectedMainCategories && formError && "form-error"
                      }`}
                      type="select"
                      name="mainCategory"
                      value={selectedMainCategories}
                      onChange={handleMainCategory}
                    >
                      <option>
                        {digitalProductTranslation?.mainCategory
                          ? digitalProductTranslation?.mainCategory
                          : "Main Category"}
                      </option>
                      {productCategories?.map(
                        (item, i) =>
                          item.type === "digital" && (
                            <option key={i} value={item._id}>
                              {item.name}
                            </option>
                          )
                      )}
                    </Input>
                  </Col>
                </Row>
              </FormGroup>

              {/* UPLOAD COVER */}
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="file-multiple-input">
                    {digitalProductTranslation?.uploadCover
                      ? digitalProductTranslation?.uploadCover
                      : "Upload Cover"}
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Button
                    className={`input-prepend ${
                      !bookCoverUrl && formError && "form-error"
                    }`}
                    onClick={handleCoverClick}
                  >
                    {otherDigitalProductTranslation?.uploadMusic
                      ? otherDigitalProductTranslation?.uploadMusic
                      : "Upload a file"}
                  </Button>
                  <Label style={{ marginLeft: 10 }}>
                    {digitalProductTranslation?.note
                      ? digitalProductTranslation?.note +
                        ":" +
                        digitalProductTranslation?.noteExplain
                      : "Note: Recommended size for image is 1152 * 500"}
                  </Label>
                  <input
                    type="file"
                    onChange={uploadBookCover}
                    ref={bookCoverUrlRef}
                    name="files"
                    style={{ display: "none" }}
                  />
                  <p>{bookCoverUrl?.split("/")?.slice(-1)}</p>
                </Col>
              </FormGroup>

              {/* UPLOAD TRAILER */}
              {["movie", "series", "theater", "music"].includes(
                digitalType
              ) && (
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">
                      {otherDigitalProductTranslation?.uploadMusic
                        ? otherDigitalProductTranslation?.uploadMusic
                        : "Upload trailer"}
                    </Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Button
                      className={`input-prepend ${
                        !trailerUrl && formError && "form-error"
                      }`}
                      onClick={handleTrailerClick}
                    >
                      {otherDigitalProductTranslation?.uploadMusic
                        ? otherDigitalProductTranslation?.uploadMusic
                        : "Upload a file"}
                    </Button>
                    <input
                      type="file"
                      onChange={uploadTrailer}
                      ref={trailerUrlRef}
                      name="files"
                      style={{ display: "none" }}
                    />
                    <p>{trailerUrl?.split("/")?.slice(-1)}</p>
                  </Col>
                </FormGroup>
              )}

              {/* UPLOAD MOVIE */}
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="file-multiple-input">
                    {otherDigitalProductTranslation?.uploadMusic
                      ? otherDigitalProductTranslation?.uploadMusic
                      : "Upload"}
                  </Label>
                </Col>
                <Col xs="12" md="9">
                  <Button
                    className={`input-prepend ${
                      !bookUrl && formError && "form-error"
                    }`}
                    onClick={handleClick}
                  >
                    {otherDigitalProductTranslation?.uploadMusic
                      ? otherDigitalProductTranslation?.uploadMusic
                      : "Upload a file"}
                  </Button>
                  <input
                    type="file"
                    onChange={uploadBook}
                    ref={bookUrlRef}
                    style={{ display: "none" }}
                  />
                  <p>{bookUrl?.split("/")?.slice(-1)}</p>
                </Col>
              </FormGroup>

              <Button
                hidden={loading}
                color="primary"
                type="submit"
                className="btn btn-sm"
                onClick={save}
                block
              >
                {digitalProductTranslation?.saveButton
                  ? digitalProductTranslation?.saveButton
                  : "Save"}
              </Button>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <i
                  hidden={!loading}
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", float: "right" }}
                ></i>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* end of prising table layout */}
    </div>
  );
};

export default AddDigitalProduct;
