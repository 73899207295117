import React, { useState, useRef, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import SkyLight from "react-skylight";
import moment from "moment";
import {
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  FormGroup,
  Label,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  eventCreation,
  getEvent,
  deleteProduct,
  getSingleProduct,
  updateProduct,
  getOrderEvent,
  updateOrderStreaming,
  SetState,
  createEventAnnouncement,
  updateEventAnnouncement,
  updateOrderStreamingFalse,
  getSubscription,
  addFilter,
} from "../../redux/reducers/seller";
import { getAnnouncements } from "../../redux/reducers/admin";
import {
  getCategories,
  SetState as AuthSetState,
} from "../../redux/reducers/auth";
import { socket } from "../../config/axios";
import WebRTCComponent from "./videoEvent/publisher";
import SubscriberRTCComponent from "./videoEvent/subscriber";
import AddEditModal from "./AddEditModal";
import "./event.css";
import FreemiumPage from "../Seller/CustomPages/FreemiumPage";
import { PAGINATION_MODEL } from "../../constants";
import { nextPagesItems } from "../../helpers/custom";

const divStyle = { marginLeft: "10%" };
const myBigGreenDialog = { width: "35%", minHeight: "12%" };

const Event = () => {
  const dispatch = useDispatch();
  const modal = useRef(null);
  const eventTokenModal = useRef(null);
  const checkStreamModal = useRef(null);
  const checkdeleteModal = useRef(null);

  const { singleSubscription, loading, events, orderEvents, filters, count } =
    useSelector((state) => state.seller);
  const { announcements } = useSelector((state) => state.admin);
  const {
    user: { userType, _id: userId },
    userLanguage,
    productCategories,
  } = useSelector((state) => state.auth);

  const { translationState } = useSelector((state) => state.user);
  const eventTranslation =
    translationState?.[0]?.translations?.event?.[userLanguage];

  const [showWebRTC, setShowWebRTC] = useState(false);
  const [rtcProp, setRtcProps] = useState({ eventTokenId: "", productId: "" });
  const [subscriberRTC, setSubscriberRTC] = useState(false);
  const [eventOrderTokenValue, setEventOrderTokenValue] = useState("");
  const [subscriberProductToken, setSubscriberProductToken] = useState("");
  const [selectedSubscriberOrderEvent, setSelectedSubscriberOrderEvent] =
    useState({});
  const [actionType, setActionType] = useState("add");
  const [translatedContent, setTranslatedContent] = useState({});
  const [pagination, setPagination] = useState(PAGINATION_MODEL);
  const [eventContent, setEventContent] = useState({
    id: "",
    name: "",
    description: "",
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    eventCategory: "event",
    regularPrice: 1,
    images: [],
    dynamicPrice: { currentPrice: 1 },
    mainCategory: "",
    orderEventIdToken: "",
  });

  useEffect(() => {
    dispatch(SetState({ field: "productCategories", value: [] }));
    dispatch(getCategories());
    dispatch(getOrderEvent());
    dispatch(getSubscription());
    socket.on(`stream-on_${userId}`, (data) => {
      dispatch(SetState({ field: "orderEvents", value: data.data }));
    });
    socket.on(`stream-off_${userId}`, () => {
      dispatch(getOrderEvent());
      setSubscriberRTC(false);
      setSubscriberProductToken("");
    });
    return () => {
      // socket.off("stream-on", handleEventJoin);
    };
  }, []);

  useEffect(() => {
    if (productCategories?.length) {
      const eventCategory = productCategories.find(
        (cat) => cat.name === "Event"
      );
      dispatch(addFilter({ field: "pageNo", value: 1 }));
      dispatch(addFilter({ field: "perPage", value: 4 }));
      dispatch(
        getEvent({ mainCategory: eventCategory?._id, eventCategory: "event" })
      );
    }
  }, [productCategories]);

  useEffect(() => {
    if (announcements?.length > 0) {
      const activeEvent = announcements.find(
        (announcement) =>
          announcement.productEventTokenIds === subscriberProductToken &&
          announcement.isRead === false
      );
      if (activeEvent?.isRead === false) {
        checkStreamModal.current.hide();
        dispatch(updateEventAnnouncement()).then(() =>
          dispatch(getAnnouncements())
        );
        setSubscriberRTC(true);
      }
    }
  }, [announcements]);

  useEffect(() => {
    if (events?.length) {
      const productIds = events.map((event) => event._id);
      dispatch(updateOrderStreamingFalse({ productIds }));
    }
  }, [events]);

  useEffect(() => {
    if (orderEvents?.length && selectedSubscriberOrderEvent._id) {
      const updatedOrder = orderEvents?.find(
        (event) => event._id === selectedSubscriberOrderEvent._id
      );
      setSelectedSubscriberOrderEvent(updatedOrder);
    }
    if (translationState?.length) {
      const eventTranslation =
        translationState[0].translations?.event?.[userLanguage];
      eventTranslation
        ? setTranslatedContent({ ...translatedContent, ...eventTranslation })
        : setTranslatedContent({});
    }
    const { value: perPage } = filters.find((item) => item.field === "perPage");
    const { value: pageNo } = filters.find((item) => item.field === "pageNo");
    setPagination({ ...pagination, pageNo, perPage });
  }, [orderEvents, translationState, userLanguage, filters]);

  useEffect(() => {
    if (eventContent.id && actionType === "delete") {
      deleteEvent(eventContent.id);
    }
  }, [actionType]);

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setEventContent({ ...eventContent, [name]: value });
  };
  const paginate = (pageNo) => {
    const nextCount = nextPagesItems(
      Math.abs(pageNo - 1),
      pagination.perPage,
      count
    );
    if (pageNo > 0 && nextCount > 0) {
      dispatch(addFilter({ field: "pageNo", value: pageNo }));
      dispatch(getEvent());
    }
  };
  const handleStartLiveButton = async (event) => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    } catch (error) {
      // Show a toast message that access to camera and microphone is blocked please allow it
      toast.error("Access to camera and microphone is blocked please allow it");
      return;
    }

    const { _id: productId, productEventTokenId } = event;
    const message = `event is start on ${productEventTokenId} ID`;
    const data = { productId, message };
    dispatch(createEventAnnouncement(data));
    dispatch(updateOrderStreaming({ productId }));
    setRtcProps({ eventTokenId: productEventTokenId, productId });
    setShowWebRTC(true);
  };
  const handleCloseLiveButton = () => {
    setShowWebRTC(false);
  };
  const handleJoinLiveButton = () => {
    eventTokenModal.current.hide();
    if (eventContent.orderEventIdToken === eventOrderTokenValue) {
      if (selectedSubscriberOrderEvent?.eventStream) {
        setSubscriberRTC(true);
        eventTokenModal.current.hide();
        setEventContent({ ...eventContent, orderEventIdToken: "" });
      } else {
        checkStreamModal.current.show();
        setEventContent({ ...eventContent, orderEventIdToken: "" });
      }
    } else {
      toast.error("Event Token ID Is Wrong");
      eventTokenModal.current.hide();
      setEventContent({ ...eventContent, orderEventIdToken: "" });
    }
  };
  const handleJoinEvent = (value) => {
    const { orderEventTokenId, products } = value;
    const productTokenId = products?.[0]?.product?.productEventTokenId;
    const startDate = products?.[0]?.product?.startDate;
    setSelectedSubscriberOrderEvent(value);
    setEventContent({
      ...eventContent,
      startDate: moment(startDate).format("YYYY-MM-DDTHH:mm"),
    });
    setEventOrderTokenValue(orderEventTokenId);
    setSubscriberProductToken(productTokenId);
    eventTokenModal.current.show();
  };
  const onCreateEvent = () => {
    setEventContent({
      ...eventContent,
      id: "",
      name: "",
      description: "",
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      eventCategory: "event",
      regularPrice: 1,
      images: [],
      dynamicPrice: { currentPrice: 1 },
    });
    setActionType("add");
    modal.current.show();
  };
  const onEditEvent = (id) => {
    dispatch(getSingleProduct(id)).then((item) => {
      const data = item.payload.data;
      const startDate = data?.startDate;
      const formattedStartDate = moment(startDate).format("YYYY-MM-DDTHH:mm");
      setEventContent({
        ...eventContent,
        id,
        name: data.name,
        description: data.description,
        startDate: formattedStartDate,
        eventCategory: "event",
        images: data.images,
        dynamicPrice: { currentPrice: data.dynamicPrice?.currentPrice },
        mainCategory: data.mainCategory,
      });
      setActionType("edit");
      modal.current.show();
    });
  };
  const handleDeleteConfirmation = (id) => {
    checkdeleteModal.current.show();
    setEventContent({ ...eventContent, id });
  };
  const deleteEvent = async (id) => {
    await dispatch(deleteProduct(id));
    checkdeleteModal.current.hide();
    setActionType("");
    setEventContent({ ...eventContent, id: "" });
    dispatch(getEvent());
  };
  const handleOnSubmit = async (data) => {
    const {
      name,
      description,
      startDate,
      eventCategory,
      images,
      dynamicPrice,
    } = data;
    if (
      !name ||
      !description ||
      !startDate ||
      !eventCategory ||
      !images?.length ||
      !dynamicPrice.currentPrice
    ) {
      return toast.error("All fields are required");
    }
    if (actionType === "add") {
      await dispatch(eventCreation(data));
    } else if (actionType === "edit") {
      await dispatch(updateProduct(data));
    }
    modal.current.hide();
    setEventContent({
      ...data,
      id: "",
      name: "",
      description: "",
      startDate: moment().format("YYYY-MM-DDTHH:mm"),
      eventCategory: "event",
      regularPrice: 1,
      images: [],
      dynamicPrice: { currentPrice: 1 },
      mainCategory: "",
    });
    dispatch(getEvent());
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      {loading ? (
        <div className="mt-5" style={{ textAlign: "center" }}>
          <i
            className="fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green" }}
          ></i>
        </div>
      ) : userType === "seller" &&
        singleSubscription?.membershipType === "Fremium" ? (
        <FreemiumPage
          text={
            eventTranslation?.infoLineOne ||
            "Only SOLO and Partner users can use the Event Live Streaming feature. Please upgrade your membership to access this and many more exciting features."
          }
        />
      ) : (
        <>
          <AddEditModal
            ref={modal}
            eventContent={eventContent}
            type={actionType}
            onSubmit={handleOnSubmit}
          />
          <SkyLight
            dialogStyles={{ width: "50%", height: "25%" }}
            hideOnOverlayClicked
            ref={eventTokenModal}
            title={
              <h4>{translatedContent.eventTokenId || "Event Token Id"}</h4>
            }
          >
            <FormGroup>
              <Row>
                <Col>
                  <div className="label-input-container">
                    <div className="label-container">
                      <Label>
                        {translatedContent.eventTokenId || "Event Token Id"}:
                      </Label>
                    </div>
                    <div className="input-container">
                      <Input
                        placeholder="Event Token Id"
                        name="orderEventIdToken"
                        value={eventContent.orderEventIdToken}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </FormGroup>
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <Col>
                  <Button
                    className="custom-event-button"
                    onClick={() => eventTokenModal?.current?.hide()}
                  >
                    <b>{translatedContent.cancel || "Cancel"}</b>
                  </Button>
                </Col>
              </div>
              <div>
                <Col>
                  <Button
                    className="custom-event-button"
                    onClick={() => handleJoinLiveButton()}
                  >
                    <b>{translatedContent.ok || "Ok"}</b>
                  </Button>
                </Col>
              </div>
            </Row>
          </SkyLight>
          <SkyLight
            dialogStyles={{ width: "50%", height: "25%" }}
            hideOnOverlayClicked
            ref={checkStreamModal}
            title={
              <h3>{translatedContent.eventStreaming || "Event Streaming"}</h3>
            }
          >
            <div
              className="mt-5"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                textAlign: "center",
              }}
            >
              <i
                className="fas fa-spinner fa-pulse fa-2x"
                style={{ color: "green" }}
              ></i>
              <div>
                <h4>
                  {translatedContent.content1 || "Streaming not started yet"}
                  <br />
                  {translatedContent.content2 ||
                    "We will let you in when streaming starts"}
                </h4>
                <div>
                  {moment(eventContent.startDate).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )}
                </div>
              </div>
            </div>
          </SkyLight>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={checkdeleteModal}
            title={
              <b style={{ display: "flex", justifyContent: "center" }}>
                <h4>
                  {translatedContent.content3 ||
                    "Are you sure you want to delete this Event?"}
                </h4>
              </b>
            }
          >
            <div>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  // disabled={!(productMaterial.name && productMaterial.cost)}
                  type="submit"
                  onClick={() => setActionType("delete")}
                  size="md"
                  color="warning"
                >
                  <strong>{translatedContent.confirm || "Confirm"}</strong>
                </Button>
                <Col xs="12" sm="1"></Col>
                <Button
                  type="submit"
                  size="md"
                  onClick={() => {
                    checkdeleteModal.current.hide();
                    setActionType("");
                  }}
                  color="danger"
                >
                  <strong>{translatedContent.cancel || "Cancel"}</strong>
                </Button>
              </Row>
            </div>
          </SkyLight>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Row className="justify-content-between align-items-center flex-wrap">
                    <Col /* xs="12" sm="9" */ className="p-0">
                      <b>{translatedContent.event || "Event"}</b>
                    </Col>
                    {userType === "seller" && (
                      <Col /* xs="12" sm="3" */ className="p-0">
                        <div className="d-flex justify-content-end">
                          <Button
                            className="custom-event-button"
                            color="black"
                            style={divStyle}
                            btn="sm"
                            onClick={onCreateEvent}
                          >
                            <i
                              className="fas fa-plus"
                              style={{ color: "white", marginRight: "9px" }}
                            ></i>
                            <b>
                              {translatedContent.createEvent || "Create Event"}
                            </b>
                          </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                <CardBody className="card-body">
                  {userType === "seller" ? (
                    <h4>{translatedContent.eventTicket || "Event Ticket"}</h4>
                  ) : (
                    ""
                    // <h2>{translatedContent.events || "Events"}</h2>
                  )}
                  {userType === "seller" ? (
                    <div>
                      {showWebRTC ? (
                        <WebRTCComponent
                          rtcProp={rtcProp}
                          onClose={handleCloseLiveButton}
                        />
                      ) : (
                        <Row className="card-row">
                          {events?.map((event, index) => (
                            <div key={index} className="d-flex mx-md-2 mx-sm-0">
                              <div className="event-card">
                                <Card>
                                  <div className="d-flex justify-content-center align-items-center imageContainer">
                                    <img
                                      height="250px"
                                      width="250px"
                                      src={event.images}
                                      alt="Event"
                                    />
                                    <div className="image-blur-overlay"></div>
                                    <button
                                      className="imageContainer-button-overlay1"
                                      onClick={() =>
                                        handleStartLiveButton(event)
                                      }
                                    >
                                      {translatedContent.startLive ||
                                        "Start Live"}
                                    </button>
                                    <h4 className="imageContainer-text-overlay">
                                      {event.name}
                                    </h4>
                                  </div>
                                  <div className="button-container">
                                    <Button
                                      className="custom-button"
                                      onClick={() => onEditEvent(event._id)}
                                    >
                                      {translatedContent.edit || "Edit"}
                                    </Button>
                                    <Button
                                      className="custom-button"
                                      onClick={() =>
                                        handleDeleteConfirmation(event._id)
                                      }
                                    >
                                      {eventTranslation?.delete || "Delete"}
                                    </Button>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          ))}
                        </Row>
                      )}
                    </div>
                  ) : (
                    <div>
                      <Row className="card-row event-card-customer-account">
                        {subscriberRTC ? (
                          <SubscriberRTCComponent
                            subscriberProductToken={subscriberProductToken}
                            onClose={() => {
                              setSubscriberRTC(false);
                              setEventContent({
                                ...eventContent,
                                orderEventIdToken: "",
                              });
                            }}
                          />
                        ) : (
                          orderEvents?.map((event, index) => (
                            <div className="d-flex mx-2">
                              <div>
                                <div className="d-flex flex-wrapp justify-content-center mb-2">
                                  <img
                                    style={{ height: "200px", width: "200px" }}
                                    src={event.products?.[0]?.product?.images}
                                    alt="Event"
                                  />
                                </div>
                                <div className="d-flex flex-wrapp justify-content-center mb-2">
                                  <Button
                                    className="event-button"
                                    onClick={() => handleJoinEvent(event)}
                                  >
                                    {translatedContent.join || "Join"}{" "}
                                    {event.eventStream && "(Live)"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </Row>
                    </div>
                  )}
                  <nav>
                    <Pagination>
                      <PaginationItem style={{ marginRight: "3px" }}>
                        <PaginationLink
                          onClick={(e) => paginate(pagination.pageNo - 1)}
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        >
                          {translatedContent.prev || "Prev"}
                        </PaginationLink>
                      </PaginationItem>
                      <PaginationItem>
                        <PaginationLink
                          onClick={(e) => paginate(pagination.pageNo + 1)}
                          className="ml-2 mr-2"
                          style={{ backgroundColor: "rgb(228, 231, 234)" }}
                        >
                          {translatedContent.next || "Next"}
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default Event;
