import React, { useEffect, useState, useRef } from "react";
import { delay, round } from "lodash";
import { Button, Label, Input, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { ToastContainer, toast } from "react-toastify";
import ProductPreviewBox from "../Home/ProductPreviewBox";
import { addToCart, getSuggestedPrice } from "../../../redux/reducers/auth";
import {
  getSingleProduct,
  getRelatedProducts,
} from "../../../redux/reducers/auth";
import "./productdetail.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AppHeader } from "@coreui/react";
import SkyLight from "react-skylight";
import DefaultFooter from "../../Footer/DefaultFooter";
import DefaultHeader from "../../Header/DefaultHeader";
import RatingWidget from "./RatingWidget";
import { FaMinus } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { materialList } from "../../../views/Product/addproduct/Materiallist";
import { translations } from "../../TranslationHelper/Translation";

const ProductDetail = (props) => {
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [newPrice, setNewPrice] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const priceModal = useRef(null);
  const {
    singleProduct,
    relatedProducts,
    isAlreadyPurchased,
    suggestedPrice,
    loading,
    cart,
  } = useSelector((state) => state.auth);
  console.log("🚀 ~ ProductDetail ~ singleProduct:", singleProduct);
  const { usdRate } = useSelector((state) => state.auth);
  const { userCurrency, translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const checkCart = cart?.map((item) => item?.product?.eventCategory);
  console.log("🚀 ~ ProductDetail ~ checkCart:", checkCart);
  const addproductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const productDetailContent = allWebContent?.[0]?.productDetail;
  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const productDigitalTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];

  const ChatGPTTranslated = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const ShopContent = allWebContent?.[0]?.shop;
  const shopTranslation =
    translationState?.[0]?.translations?.shop?.[userLanguage];

  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const [suggestedValue, setSuggestedValue] = useState(0);
  const [buttonType, setButtonType] = useState("");
  const [qty, setQty] = useState(1);
  const selectedLanguage = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const filterProduct = relatedProducts?.filter(
    (id) => id._id !== singleProduct?._id
  );
  useEffect(() => {
    dispatch(getSingleProduct(productId));
    dispatch(getRelatedProducts(productId));
  }, []);

  useEffect(() => {
    if (suggestedPrice) {
      delay(() => {
        if (buttonType == "shipping") shipping();
        else if (buttonType == "addToCart") addCart();
      }, 1000);
    }
  }, [suggestedPrice]);

  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  const shipping = () => {
    const data = {
      product: { ...singleProduct, unitPrice: parseFloat(suggestedValue) },
      qty,
    };
    if (checkCart?.[0] === "event") {
      if (data?.product?.eventCategory === "event") {
        dispatch(addToCart(data));
        history.push("/my-cart");
      } else {
        toast.error(translations["categoryNotSame"][userLanguage]);
      }
    } else {
      if (data?.product?.eventCategory !== "event") {
        dispatch(addToCart(data));
        history.push("/my-cart");
      } else {
        toast.error(translations["categoryNotSame"][userLanguage]);
      }
    }
  };
  const addCart = () => {
    if (isAlreadyPurchased) {
      window.open(singleProduct.productUrl);
    } else {
      const data = {
        product: {
          ...singleProduct,
          unitPrice: parseFloat(singleProduct.regularPrice),
        },
        qty: 1,
      };
      toast.success(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Added to cart"
        }`
      );
      dispatch(addToCart(data));
      history.push("/my-cart");
    }
  };
  const incrementCounter = () => {
    if (qty < singleProduct.stockQuantity) {
      setQty(qty + 1);
    }
  };
  const decrementCounter = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };
  const viewProduct = (id, productType, digitalType) => {
    dispatch(getSingleProduct(id));
    dispatch(getRelatedProducts(id));
    const isViewTv = ["movie", "series", "theater"].includes(digitalType);
    if (!isViewTv)
      history.push(
        `/${
          productType == "digital" ? "digitalproduct" : "productdetail"
        }/${id}`
      );
    // history.push(
    //   `/${type == "digital" ? "digitalproduct" : "productdetail"}/${id}`
    // );
    else
      history.push(
        `/${productType == "digital" ? "viewtv" : "productdetail"}/${id}`
      );
  };
  const checkSuggestedPrice = (type) => {
    setButtonType(type);
    const price = parseFloat(suggestedValue);
    dispatch(
      getSuggestedPrice({
        price,
        productId,
      })
    ).then(({ payload }) => {
      if (payload.message) {
        toast.success(
          `${
            toastTranslation?.success
              ? toastTranslation?.success
              : "You got product"
          }`
        );
        setIsOpen(false);
      } else {
        setNewPrice(payload.newPrice);
        if (payload.checkPrice <= 10) {
          toast.error(translations["closeToValuingProduct"][userLanguage], {
            onClose: 3000,
          });
        } else {
          toast.error(translations["tooFarFromValuingProduct"][userLanguage], {
            onClose: 3000,
          });
        }
        let updatedCount = count + 1;

        setCount(updatedCount);
        if (updatedCount === 2) {
          setIsOpen(true);
          return;
        }
      }
    });
  };
  const getStarRating = () => {
    let price = singleProduct
      ? singleProduct?.dynamicPrice?.currentPrice
      : null;
    if (price) {
      if (price < 20) {
        return 1;
      } else if (price < 100) {
        return 2;
      } else if (price < 500) {
        return 3;
      } else if (price < 1500) {
        return 4;
      } else {
        return 5;
      }
    } else {
      return null;
    }
  };
  const setSuggestedPrice = (price) => {
    setSuggestedValue(price);
  };
  const closeHanlder = () => {
    setIsOpen(false);
    setCount(0);
  };

  return (
    <div className="animated fadeIn" style={{ position: "relative" }}>
      {isOpen && (
        <div className="price-detail-wrapper">
          <div className="priceModal">
            <div className="close_icon">
              <IoClose onClick={closeHanlder} />
            </div>

            <p className="error-tag-line">
              {productDetailTranslation?.suggestedPriceline
                ? productDetailTranslation?.suggestedPriceline
                : `We suggest setting the price from ${newPrice} and upwards for the best results.`}
            </p>
            <div
              style={{
                display: "flex",
                marginBottom: 8,
                justifyContent: "space-in-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  width: "50%",
                  display: "flex",
                  minWidth: 205,
                  marginBottom: 10,
                  paddingLeft: 20,
                }}
              >
                <input
                  autoFocus
                  className={
                    !suggestedPrice ? "price-input" : "price-input-accepted"
                  }
                  type="number"
                  min={0}
                  placeholder={
                    productDetailTranslation?.placeHolderUSD
                      ? productDetailTranslation?.placeHolderUSD + " USD"
                      : "Offer in USD"
                  }
                  onChange={(e) => setSuggestedPrice(e.target.value)}
                />
              </div>

              <div
                style={{
                  width: "50%",
                  display: "flex",
                  minWidth: 205,
                  paddingLeft: 20,
                }}
              >
                <button
                  className="buy-now"
                  onClick={() => checkSuggestedPrice("shipping")}
                >
                  {singleProduct?.isResidency
                    ? `${
                        productDetailTranslation?.rentbutton
                          ? productDetailTranslation?.rentbutton
                          : "Rent Now"
                      }`
                    : singleProduct?.eventCategory !== "event"
                    ? `${
                        productDetailTranslation?.buyButton
                          ? productDetailTranslation?.buyButton
                          : "Buy Now"
                      }`
                    : "Buy Event Ticket"}
                </button>
                {!singleProduct?.isResidency && (
                  <button
                    disabled={singleProduct?.stockQuantity <= 0}
                    className="add-to-cart"
                    onClick={() => checkSuggestedPrice("addToCart")}
                  />
                )}
              </div>
            </div>
            <h2 className="product-detail-heading mb-3">
              {productDetailTranslation?.productheading
                ? productDetailTranslation?.productheading
                : "Product Details"}
            </h2>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading ">
                <p>
                  {productDetailTranslation?.carbonEmission
                    ? productDetailTranslation?.carbonEmission
                    : "Carbon Emission "}
                </p>
              </div>
              <div className="col-md-8 col-12 pl-4">
                {singleProduct?.carbonEmission?.toFixed(4)
                  ? singleProduct?.carbonEmission?.toFixed(4)
                  : productDetailTranslation?.undefineline
                  ? productDetailTranslation?.undefineline
                  : "Data is not available for this product"}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading ">
                {productDigitalTranslation?.investment
                  ? productDigitalTranslation?.investment
                  : " Investment on Product:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {singleProduct?.investmentStory
                  ? singleProduct?.investmentStory
                  : productDetailTranslation?.undefineline
                  ? productDetailTranslation?.undefineline
                  : "Data is not available for this product"}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                {productDigitalTranslation?.environment
                  ? productDigitalTranslation?.environment
                  : " Environmental Impact:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {ChatGPTTranslated?.[0]?.environmentStory ? (
                  <div className="product-brand">
                    {ChatGPTTranslated[0]?.environmentStory}
                  </div>
                ) : (
                  <div>
                    {" "}
                    {singleProduct?.environmentStory
                      ? singleProduct?.environmentStory
                      : productDetailTranslation?.undefineline
                      ? productDetailTranslation?.undefineline
                      : "Data is not available for this product"}
                  </div>
                )}
              </div>
            </div>
            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                {productDigitalTranslation?.productStory
                  ? productDigitalTranslation?.productStory
                  : "Brief Story Of Product:"}
              </div>
              <div className="col-md-8 col-12 pl-4">
                {ChatGPTTranslated?.[0]?.productStory ? (
                  <div className="product-brand">
                    {ChatGPTTranslated[0]?.productStory}
                  </div>
                ) : (
                  <div>
                    {singleProduct?.productStory
                      ? singleProduct?.productStory
                      : productDetailTranslation?.undefineline
                      ? productDetailTranslation?.undefineline
                      : "Data is not available for this product"}
                  </div>
                )}
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md-4 col-12 price-detail-heading">
                <p>
                  {addproductTranslation?.materiallabel
                    ? addproductTranslation?.materiallabel
                    : "Materials Used:"}
                </p>
              </div>
              <div className="col-md-8 col-12 pl-4">
                {(() => {
                  if (singleProduct?.material?.length > 0) {
                    return singleProduct.material.map((material, index) => (
                      <div className="mb-3" key={index}>
                        {index + 1}: &nbsp;
                        <span className="material-cost-text">
                          {materialList[userLanguage][material.name]}
                        </span>
                        &nbsp;
                        {material.ranges}% &nbsp;
                        <br />
                      </div>
                    ));
                  } else {
                    return productDetailTranslation?.undefineline
                      ? productDetailTranslation.undefineline
                      : "Data is not available for this product";
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer autoClose={20000} />
      <div className="app" style={{ backgroundColor: "white" }}>
        <div>
          <AppHeader fixed>
            <DefaultHeader />
          </AppHeader>
        </div>
        {singleProduct?._id && (
          <div className="pt-5 d-flex flex-column align-items-center">
            <div className="mt-5 d-flex flex-wrap image-detail-holder">
              <img
                className="digital-product-image"
                src={singleProduct.images[0]}
              />
              <div className="digital-product-details">
                <div className="digital-product-title-holder">
                  <h4 className="digital-product-title">
                    {selectedLanguage?.[0]
                      ? selectedLanguage[0]?.name
                      : singleProduct.name}
                  </h4>
                  {selectedLanguage?.[0] ? (
                    <div className="product-brand">
                      {selectedLanguage[0]?.discription}
                    </div>
                  ) : (
                    singleProduct &&
                    singleProduct.specification && (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: convertCommentFromJSONToHTML(
                            singleProduct.specification
                          ),
                        }}
                      />
                    )
                  )}
                  {/* <p className="digital-product-note">
                    <b>Note:*</b> if you already purchased this item, login to
                    download directly.
                  </p> */}
                  {!singleProduct?.isResidency && (
                    <div className="d-flex align-items-center my-2">
                      <div>
                        <div className="attribute-text-qantity">
                          {productDetailTranslation?.quantity
                            ? productDetailTranslation?.quantity
                            : "Quantity"}
                          :
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {singleProduct?.eventCategory !== "event" && (
                          <FaMinus
                            onClick={decrementCounter}
                            className="quantity-minus"
                            size={20}
                          />
                        )}
                        {singleProduct?.eventCategory !== "event" && (
                          <div className="quanttity-count" id="counter">
                            {qty}
                          </div>
                        )}
                        {singleProduct?.eventCategory !== "event" && (
                          <IoMdAdd
                            onClick={incrementCounter}
                            size={20}
                            className="quantity-minus"
                          />
                        )}
                      </div>
                    </div>
                  )}
                  <div
                    hidden={false}
                    className={
                      singleProduct?.stockQuantity > 0
                        ? "availability-tag"
                        : "unavailability-tag"
                    }
                  >
                    <b>
                      {singleProduct?.stockQuantity > 0
                        ? `${
                            productDetailTranslation?.inStock
                              ? productDetailTranslation?.inStock
                              : "In stock"
                          }`
                        : `${
                            productDetailTranslation?.outStock
                              ? productDetailTranslation?.outStock
                              : "Out of Stock"
                          }`}
                    </b>
                  </div>
                </div>
                <div className="digital-product-title-holder">
                  <div className="pricing-section">
                    {singleProduct?.dynamicPrice?.currentPrice !== 0 && (
                      <div
                        style={{
                          display: "flex",
                          marginBottom: 10,
                          alignItems: "baseline",
                          flexWrap: "wrap",
                        }}
                      >
                        <div className="attribute-text">
                          {productDetailTranslation?.priceRating
                            ? productDetailTranslation?.priceRating
                            : "Price rating"}
                          :
                        </div>
                        <div>
                          <RatingWidget
                            product={singleProduct}
                            rating={getStarRating()}
                          />
                        </div>
                        <div className="seperator">|</div>
                        <div
                          className="what-is-price-rate"
                          onClick={() => priceModal.current.show()}
                        >
                          <u>
                            {shopTranslation?.popUpHeading
                              ? shopTranslation?.popUpHeading
                              : ShopContent?.popUpHeading}
                            {/* What is price rate? */}
                          </u>
                        </div>
                      </div>
                    )}
                    <div className="attribute-text">
                      {singleProduct?.isResidency
                        ? `${
                            productDetailTranslation?.suggestValue
                              ? productDetailTranslation?.suggestValue
                              : "Suggest a daily rate(USD)"
                          } :`
                        : `${
                            productDetailTranslation?.suggestValue
                              ? productDetailTranslation?.suggestValue
                              : "Suggest Value(USD)"
                          } :`}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: 8,
                        justifyContent: "space-in-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          minWidth: 205,
                          marginBottom: 10,
                        }}
                      >
                        <input
                          autoFocus
                          className={
                            !suggestedPrice
                              ? "price-input"
                              : "price-input-accepted"
                          }
                          type="number"
                          min={0}
                          placeholder={
                            productDetailTranslation?.placeHolderUSD
                              ? productDetailTranslation?.placeHolderUSD +
                                " USD"
                              : "Offer in USD"
                          }
                          onChange={(e) => setSuggestedPrice(e.target.value)}
                        />
                      </div>
                      <div
                        style={{
                          width: "50%",
                          display: "flex",
                          minWidth: 205,
                        }}
                      >
                        <button
                          className="buy-now"
                          disabled={singleProduct?.stockQuantity <= 0}
                          onClick={() => checkSuggestedPrice("shipping")}
                        >
                          {singleProduct?.isResidency
                            ? `${
                                productDetailTranslation?.rentbutton
                                  ? productDetailTranslation?.rentbutton
                                  : "Rent Now"
                              }`
                            : `${
                                productDetailTranslation?.buyButton
                                  ? productDetailTranslation?.buyButton
                                  : "Buy Now"
                              }`}
                          {/* {singleProduct?.isResidency ? "Rent Now" : "Buy Now"} */}
                        </button>
                        {!singleProduct?.isResidency && (
                          <button
                            disabled={singleProduct?.stockQuantity <= 0}
                            className="add-to-cart"
                            onClick={() => checkSuggestedPrice("addToCart")}
                          />
                        )}
                      </div>
                    </div>

                    <div className="pwiw-input-helper-text">
                      {loading
                        ? "Evaluating offer"
                        : singleProduct?.hasPriceError ||
                          !singleProduct?.hasMadeOffer
                        ? `${
                            productDetailTranslation?.instructedParaLine
                              ? productDetailTranslation?.instructedParaLine
                              : "Tell us how much you would value this product"
                          }`
                        : singleProduct?.isResidency
                        ? "You can rent this property now"
                        : "You can purchase this now"}

                      {singleProduct?.carbonEmissionDigital !== undefined && (
                        <p style={{ fontSize: "14px", paddingTop: "20px" }}>
                          {productDetailTranslation?.carbonEmission
                            ? productDetailTranslation?.carbonEmission
                            : "Carbon Emission "}{" "}
                          {singleProduct.carbonEmissionDigital}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="digital-product-details">
                <div className="digital-product-title-holder">
                  <h2 className="digital-product-title">
                    {singleProduct.name}
                  </h2>
                  {singleProduct && singleProduct.specification && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: convertCommentFromJSONToHTML(
                          singleProduct.specification
                        ),
                      }}
                    />
                  )}
                  <p className="digital-product-note">
                    <b>Note:*</b> if you already purchased this item, login to
                    download directly.
                  </p>
                </div>
                <div className="digital-product-price-holder">
                  <p className="digital-product-stock">
                    Item is
                    <b>
                      {singleProduct.quantityFlag
                        ? " out of stock"
                        : " in stock"}
                    </b>
                  </p>
                  <p className="digital-product-price">
                    Price:
                    <b>
                      {round(singleProduct.regularPrice * usdRate, 2)}
                      {userCurrency}
                    </b>
                  </p>
                  <Button
                    size="md"
                    className="digital-product-download-button"
                    color="warning"
                    onClick={() => addCart()}
                  >
                    <b>Download</b>
                  </Button>
                </div>
              </div> */}
            </div>
            <div style={{ alignItems: "start" }}>
              <h2>
                {productDetailTranslation?.relatedProduct
                  ? productDetailTranslation?.relatedProduct
                  : productDetailContent?.relatedProduct}
                {/* Related Products */}
              </h2>
            </div>
            <div className="related-item-holder">
              {filterProduct?.length > 0 ? (
                filterProduct.map((product, index) => (
                  <ProductPreviewBox
                    key={index}
                    product={product}
                    viewProduct={(type) => {
                      // viewProduct(product._id, type, product.digitalType);
                      viewProduct(
                        product._id,
                        product.productType,
                        product.digitalType
                      );
                    }}
                  />
                ))
              ) : (
                <>
                  {filterProduct?.length <= 0 && (
                    <div style={{ marginTop: "80px" }}>
                      <h5>
                        {productDetailTranslation?.relatedMessage
                          ? productDetailTranslation?.relatedMessage
                          : productDetailContent?.relatedMessage}
                        {/* We didn't have any Related products */}
                      </h5>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div>
          <DefaultFooter />
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
