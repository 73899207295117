import React, { useEffect, useState } from "react";
import { GeneratePDF } from "./Invoice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleInvoice } from "../../redux/reducers/user";
import { AiOutlineCloudDownload } from "react-icons/ai";

const ViewInvoice = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { singleInvoice, loading, translationState } = useSelector(
    (state) => state.user
  );
  const { user, userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const paymentInfoTranslation =
    translationState?.[0]?.translations?.paymentInfo?.[userLanguage];
  const orderTranslation =
    translationState?.[0]?.translations?.order?.[userLanguage];

  useEffect(() => {
    dispatch(getSingleInvoice(id));
  }, []);

  return (
    <>
      <div className="main-row">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div>
            <h5>
              <b>
                {paymentInfoTranslation?.userName
                  ? paymentInfoTranslation?.userName
                  : "userName :"}{" "}
                &nbsp;
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  {user.firstname}
                </span>
              </b>
            </h5>
            <h5>
              <b>
                {paymentInfoTranslation?.invoiceId
                  ? paymentInfoTranslation?.invoiceId
                  : "Invoice Id :"}
                &nbsp;
                <span style={{ fontSize: "16px", fontWeight: 400 }}>
                  {singleInvoice._id}
                </span>
              </b>
            </h5>
          </div>
          <div>
            {loading ? (
              <div className="view-invoice">
                <i
                  className="mx-auto fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", float: "right" }}
                ></i>
              </div>
            ) : (
              <div>
                <div className="my-3 d-flex justify-content-end">
                  <button
                    className="button-color"
                    onClick={() => GeneratePDF(singleInvoice, user)}
                  >
                    {paymentInfoTranslation?.download
                      ? paymentInfoTranslation?.download
                      : " Download"}

                    <span className="ml-2">
                      <AiOutlineCloudDownload size={20} />
                    </span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row  pdf_portion">
        <div className="col-12 p-0">
          <table style={{ border: "0.5px solid grey" }} className="table ">
            <thead className="text-left bg-dark">
              <tr>
                <th>
                  {digitalProductTranslation?.title
                    ? digitalProductTranslation?.title
                    : "Title"}
                </th>
                <th>
                  {digitalProductTranslation?.qty
                    ? digitalProductTranslation?.qty
                    : "QTY"}
                </th>
                <th>
                  {paymentInfoTranslation?.unitPrice
                    ? paymentInfoTranslation?.unitPrice
                    : "Unit Price"}
                </th>
                <th>
                  {paymentInfoTranslation?.totalPrice
                    ? paymentInfoTranslation?.totalPrice
                    : "Total Price "}
                </th>
              </tr>
            </thead>
            {singleInvoice.products?.map((data, i) => {
              return (
                <tbody key={i}>
                  <tr>
                    <td>{data.product?.name}</td>
                    <td>#{data.qty}</td>
                    <td>${data.unitPrice}</td>
                    <td>${data.unitPrice * data.qty}</td>
                  </tr>
                </tbody>
              );
            })}
          </table>
        </div>
      </div>
      <div>
        <div>
          <div className="d-flex justify-content-end">
            <b className="mx-3">
              {orderTranslation?.total ? orderTranslation?.total : "Total : "}
            </b>
            <b className="mr-3">
              $
              {singleInvoice.products?.reduce((a, b) => {
                return a + b.unitPrice * b.qty;
              }, 0)}
            </b>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewInvoice;
