import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SkyLight from "react-skylight";
import moment from "moment";
import { Input, Row, Col, Button, FormGroup, Label } from "reactstrap";
import { toast } from "react-toastify";

import Config from "../../config/aws";
import "./event.css";
import { getPresigedUrl, uploadToS3ViaSignedUrl } from "../../helpers/s3Utils";

const AddEditModal = React.forwardRef((props, ref) => {
  const { eventContent, type, onSubmit } = props;
  const { userLanguage, productCategories } = useSelector(
    (state) => state.auth
  );
  const { translationState } = useSelector((state) => state.user);
  const [translatedContent, setTranslatedContent] = useState({});

  const [addEditObj, setAddEditObj] = useState({
    id: "",
    name: "",
    description: "",
    startDate: moment().format("YYYY-MM-DDTHH:mm"),
    eventCategory: "event",
    regularPrice: 1,
    images: [],
    dynamicPrice: { currentPrice: 1 },
    mainCategory: "",
  });

  useEffect(() => {
    if (eventContent) {
      setAddEditObj({ ...addEditObj, ...eventContent });
    }
  }, [eventContent]);

  useEffect(() => {
    if (translationState?.length) {
      const eventTranslation =
        translationState[0].translations?.event?.[userLanguage];
      eventTranslation
        ? setTranslatedContent({ ...translatedContent, ...eventTranslation })
        : setTranslatedContent({});
    }
  }, [translationState, userLanguage]);

  const handleInputChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    setAddEditObj({ ...addEditObj, [name]: value });
  };
  const handleOnCancel = async () => {
    ref.current.hide();
  };
  const uploadImage = async (event) => {
    const blobs = event.target.files;
    for (let i = 0; i < blobs.length; i++) {
      let newImages = [...eventContent.images];
      const blob = blobs[i];
      const params = {
        Body: blob,
        Bucket: `${Config.bucketName}`,
        Key: blob.name,
      };

      try {
        const signedUrlResp = await getPresigedUrl({
          fileName: params.Key,
          bucketName: params.Bucket,
          contentType: blob.type,
        });

        await uploadToS3ViaSignedUrl({
          signedUrl: signedUrlResp.signedUrl,
          contentType: blob.type,
          body: params.Body,
        });

        const imageUrl = `${Config.digitalOceanSpaces}/` + blob.name;
        newImages = [imageUrl];
        setAddEditObj({ ...addEditObj, images: newImages });
      } catch (error) {
        toast.error("Error in uploading image! Please try again");
      }
    }
  };
  const handleOnSubmit = (event) => {
    event.preventDefault();
    const data = { ...eventContent, ...addEditObj };
    onSubmit(data);
  };

  return (
    <SkyLight
      hideOnOverlayClicked
      ref={ref}
      title={
        <h4>
          {type === "add"
            ? translatedContent.scheduleEventTicket || "Schedule Event Ticket"
            : type === "view"
            ? translatedContent.viewEvent || "View Event"
            : translatedContent.editEventTicket || "Edit Event Ticket"}
        </h4>
      }
    >
      <FormGroup>
        <Row>
          <Col className="dv">
            <div className="label-input-container">
              <div className="label-container">
                <Label>{translatedContent.eventTitle || "Event Title"}:</Label>
              </div>
              <div className="input-container">
                <Input
                  placeholder={translatedContent.eventTitle || "Event Title"}
                  name="name"
                  value={addEditObj.name}
                  onChange={handleInputChange}
                  disabled={type === "view"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="label-input-container">
              <div className="label-container">
                <Label>{translatedContent.price || "Price"}:</Label>
              </div>
              <div className="input-container">
                <Input
                  placeholder={`${
                    (translatedContent.priceIn || "Price in") + " USD"
                  }`}
                  value={addEditObj.dynamicPrice?.currentPrice}
                  onChange={(e) =>
                    setAddEditObj({
                      ...addEditObj,
                      dynamicPrice: { currentPrice: e.target.value },
                      regularPrice: e.target.value,
                    })
                  }
                  disabled={type === "view"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="label-input-container">
              <div className="label-container">
                <Label>{translatedContent.date || "Date"}:</Label>
              </div>
              <div className="input-container">
                <input
                  type="datetime-local"
                  name="startDate"
                  value={addEditObj.startDate}
                  onChange={handleInputChange}
                  min={moment().format("YYYY-MM-DDTHH:mm")}
                  disabled={type === "view"}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="label-input-container">
              <div className="label-container">
                <Label>{translatedContent.category || "Category"}:</Label>
              </div>
              <div className="input-container">
                <Input
                  className="form-input"
                  type="select"
                  name="mainCategory"
                  value={addEditObj.mainCategory}
                  onChange={handleInputChange}
                  disabled={type === "view"}
                >
                  {productCategories?.length &&
                    productCategories.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.name}
                        {/* {translatedContent.event || "Event"} */}
                      </option>
                    ))}
                </Input>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="image-dropdown">
              <div className="label-container">
                <Label>
                  {translatedContent.uploadImage || "Upload Image"}:
                </Label>
              </div>
              <div className="input-container">
                <div className="upload-wrapper">
                  <label htmlFor="file-input" className="upload-icon">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      {addEditObj?.images?.[0] ? (
                        <img
                          height="70"
                          width="70"
                          src={addEditObj?.images?.[0]}
                        />
                      ) : (
                        <i className="fa fa-upload fa-lg"></i>
                      )}
                      {addEditObj?.images?.[0] ? (
                        " "
                      ) : (
                        <div className="upload-text">
                          {translatedContent.uploadImage || "Upload Image"}
                        </div>
                      )}
                    </div>
                  </label>
                </div>
                <input
                  id="file-input"
                  multiple={true}
                  className="form-input file-input"
                  type="file"
                  onChange={uploadImage}
                  disabled={type === "view"}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="label-input-container">
              <div className="label-container">
                <Label className="form-label">
                  {translatedContent.description || "Description"}:
                </Label>
              </div>
              <div className="input-container">
                <Input
                  className="form-input"
                  type="textarea"
                  placeholder={translatedContent.description || "Description"}
                  name="description"
                  value={addEditObj.description}
                  onChange={handleInputChange}
                  disabled={type === "view"}
                />
              </div>
            </div>
          </Col>
        </Row>
      </FormGroup>
      <Row style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Col>
            <Button className="custom-event-button" onClick={handleOnCancel}>
              <b>{translatedContent.cancel || "Cancel"}</b>
            </Button>
          </Col>
        </div>
        <div>
          <Col>
            {type !== "view" && (
              <Button
                className="custom-event-button"
                onClick={handleOnSubmit}
                color="warning"
              >
                <b>
                  {type === "add"
                    ? translatedContent.create || "Create"
                    : translatedContent.edit || "Edit"}
                </b>
              </Button>
            )}
          </Col>
        </div>
      </Row>
    </SkyLight>
  );
});

export default AddEditModal;
