import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";
import { getSingleProduct } from "../../../../redux/reducers/seller";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import "./View.css";
const ViewEbooks = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlArray = history.location.pathname.split("/");
  const productId = urlArray[urlArray.length - 1];
  const digitalType = urlArray[urlArray.length - 3];
  useEffect(() => {
    dispatch(getSingleProduct(productId));
  }, []);
  const { singleProduct, loading } = useSelector((state) => state.seller);
  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { userCurrency, translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);

  const ChatGPTTranslated = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const addproductTranslation =
    translationState?.[0]?.translations?.addProduct?.[userLanguage];
  const productDetailContent = allWebContent?.[0]?.productDetail;
  const productDetailTranslation =
    translationState?.[0]?.translations?.productDetail?.[userLanguage];
  const productDigitalTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];
  const otherDigitalProductTranslation =
    translationState?.[0]?.translations?.otherDigitalProduct?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];

  const convertCommentFromJSONToHTML = (text) => {
    return stateToHTML(convertFromRaw({ entityMap: {}, blocks: text.blocks }));
  };
  const selectedLanguage = singleProduct?.translatedArray?.filter(
    (item) => item.language == userLanguage
  );

  const viewAll = () => {
    if (userType === "admin") {
      history.push("/admin/digitalproductlist");
    } else {
      history.push(`/seller/digitalproduct/${digitalType}`);
    }
  };

  return (
    <div className="animated fadeIn">
      <Row>
        <Col xs="12" lg="12">
          <Card className="mt-5">
            <CardHeader>
              <div className="d-flex justify-content-between">
                <div>
                  <i
                    className="fa fa-align-justify"
                    style={{ textTransform: "capitalize" }}
                  ></i>{" "}
                  {productDigitalTranslation?.view
                    ? productDigitalTranslation?.view
                    : ` View ${digitalType}`}
                </div>
                <div>
                  <Button onClick={viewAll} size="sm" color="warning">
                    <b>
                      {productDigitalTranslation?.view &&
                      productDigitalTranslation?.viewAll
                        ? productDigitalTranslation?.viewAll
                        : "View All"}
                    </b>
                  </Button>
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <div style={{ textAlign: "center" }}>
                <i
                  className="fas fa-spinner fa-pulse fa-2x"
                  style={{ color: "green", margin: "240px" }}
                ></i>
              </div>
            ) : (
              <CardBody>
                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.title
                          ? productDigitalTranslation?.title
                          : "Title"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {selectedLanguage?.[0]
                          ? selectedLanguage[0]?.name
                          : singleProduct.name}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.description
                          ? productDigitalTranslation?.description
                          : "Description"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <div className="controls">
                        <div id="comment-div">
                          {selectedLanguage?.[0] ? (
                            <div className="product-brand">
                              {
                                selectedLanguage[0]?.specification?.blocks[0]
                                  ?.text
                              }
                            </div>
                          ) : (
                            singleProduct &&
                            singleProduct.specification && (
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: convertCommentFromJSONToHTML(
                                    singleProduct.specification
                                  ),
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </FormGroup>
                {digitalType === "ebook" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {productDigitalTranslation?.authorName
                              ? productDigitalTranslation?.authorName
                              : "Author Name"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.authorName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {productDigitalTranslation?.bookLength
                              ? productDigitalTranslation?.bookLength
                              : "Book Length"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.bookLength}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "theater" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.missedTranslation
                              ? missedTranslation?.missedTranslation
                              : "Director"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.theaterLength
                              ? missedTranslation?.theaterLength
                              : " Theater Length"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime}{" "}
                            {digitalProductTranslation?.Minutes
                              ? digitalProductTranslation?.Minutes
                              : "Minutes"}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Video Quality"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.videoQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.type
                              ? otherDigitalProductTranslation?.type
                              : "Type"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.theaterType}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.release
                              ? missedTranslation?.release
                              : "Release Date"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "movie" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.director
                              ? missedTranslation?.director
                              : "Director"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.Length
                              ? digitalProductTranslation?.Length
                              : "Movie Length"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime}
                            {digitalProductTranslation?.Minutes
                              ? digitalProductTranslation?.Minutes
                              : "Minutes"}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Video Quality"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.videoQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.gerne
                              ? otherDigitalProductTranslation?.gerne
                              : "Gerne"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gerne}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.film
                              ? missedTranslation?.film
                              : "Production Name"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.productionName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.releaseDate
                              ? digitalProductTranslation?.releaseDate
                              : "Release Date"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "music" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.singer
                              ? otherDigitalProductTranslation?.singer
                              : "Singer"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.singerName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.Length
                              ? digitalProductTranslation?.Length
                              : "Music Length"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.runtime}
                            {digitalProductTranslation?.Minutes
                              ? digitalProductTranslation?.Minutes
                              : "Minutes"}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.quanlity
                              ? otherDigitalProductTranslation?.quanlity
                              : "Quality"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.audioQuality}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.prouction
                              ? otherDigitalProductTranslation?.prouction
                              : "Production Name"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.productionName}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.releaseDate
                              ? digitalProductTranslation?.releaseDate
                              : "Release DateS"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "game" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.size
                              ? otherDigitalProductTranslation?.size
                              : "Size"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gameSize} mb
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.releaseDate
                              ? digitalProductTranslation?.releaseDate
                              : "Release Date"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {otherDigitalProductTranslation?.supportedDevice
                              ? otherDigitalProductTranslation?.supportedDevice
                              : "Supported Device"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.requireDevice}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "series" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {missedTranslation?.director
                              ? missedTranslation?.director
                              : "Director"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor=" " className="pr-1">
                            {singleProduct.director}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.gerne
                              ? otherDigitalProductTranslation?.gerne
                              : "Gerne"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.gerne}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.releaseDate
                              ? digitalProductTranslation?.releaseDate
                              : "Release Date"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                {digitalType === "software" && (
                  <>
                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {otherDigitalProductTranslation?.size
                              ? otherDigitalProductTranslation?.size
                              : "Size"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.softwareSize} mb
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor=" " className="pr-1">
                            {digitalProductTranslation?.releaseDate
                              ? digitalProductTranslation?.releaseDate
                              : "Release Date"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="" className="pr-1">
                            {singleProduct.releaseDate}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {otherDigitalProductTranslation?.supportedDevice
                              ? otherDigitalProductTranslation?.supportedDevice
                              : "Supported Device"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.requireDevice}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>

                    <FormGroup className="pr-1">
                      <Row>
                        <Col lg="3">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {productDigitalTranslation?.category
                              ? productDigitalTranslation?.category
                              : "Category"}
                          </Label>
                        </Col>
                        <Col lg="9">
                          <Label htmlFor="exampleInputName2" className="pr-1">
                            {singleProduct.softwareCategory}
                          </Label>
                        </Col>
                      </Row>
                    </FormGroup>
                  </>
                )}
                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.language
                          ? productDigitalTranslation?.language
                          : "Language"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.language}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.regularPrice
                          ? productDigitalTranslation?.regularPrice
                          : "Regular Price"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.regularPrice}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.salePrice
                          ? productDigitalTranslation?.salePrice
                          : "Sale Price"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.salesPrice}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.stockQuantity
                          ? productDigitalTranslation?.stockQuantity
                          : " Stock Quantity"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct.stockQuantity}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.investment
                          ? productDigitalTranslation?.investment
                          : "Investment on product"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {singleProduct?.investmentStory
                          ? singleProduct?.investmentStory
                          : productDetailTranslation?.undefineline
                          ? productDetailTranslation?.undefineline
                          : "Data is not available for this product"}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.environment
                          ? productDigitalTranslation?.environment
                          : "  Impact on environment"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {ChatGPTTranslated?.[0] ? (
                          <div className="product-brand">
                            {ChatGPTTranslated[0]?.environmentStory}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            {singleProduct?.environmentStory
                              ? singleProduct?.environmentStory
                              : productDetailTranslation?.undefineline
                              ? productDetailTranslation?.undefineline
                              : "Data is not available for this product"}
                          </div>
                        )}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup className="pr-1">
                  <Row>
                    <Col lg="3">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {productDigitalTranslation?.productStory
                          ? productDigitalTranslation?.productStory
                          : "Brief Story Of Product"}
                      </Label>
                    </Col>
                    <Col lg="9">
                      <Label htmlFor="exampleInputName2" className="pr-1">
                        {ChatGPTTranslated?.[0] ? (
                          <div className="product-brand">
                            {ChatGPTTranslated[0]?.productStory}
                          </div>
                        ) : (
                          <div>
                            {singleProduct?.productStory
                              ? singleProduct?.productStory
                              : productDetailTranslation?.undefineline
                              ? productDetailTranslation?.undefineline
                              : "Data is not available for this product"}
                          </div>
                        )}
                      </Label>
                    </Col>
                  </Row>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">
                      {" "}
                      {productDigitalTranslation?.cover
                        ? productDigitalTranslation?.cover
                        : "Cover"}
                    </Label>
                  </Col>

                  <Col xs="12" lg="3">
                    <div class="container">
                      {singleProduct?.images && singleProduct?.images[0] ? (
                        <>
                          <img
                            style={{ width: "100%" }}
                            src={singleProduct?.images[0]}
                            alt="Avatar"
                            class="image"
                          />
                        </>
                      ) : (
                        "no image"
                      )}
                    </div>
                  </Col>
                </FormGroup>

                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="file-multiple-input">
                      {productDigitalTranslation?.view
                        ? productDigitalTranslation?.view
                        : "View"}
                    </Label>
                  </Col>

                  <Col xs="12" lg="3">
                    <div class="container">
                      <a target="_blank" href={singleProduct.productUrl}>
                        {productDigitalTranslation?.downloadlink
                          ? productDigitalTranslation?.downloadlink
                          : "Click to download"}
                      </a>
                      {digitalType === "theater" ||
                        (digitalType === "movie" && (
                          <>
                            <video width="320" height="240" controls>
                              <source
                                src={singleProduct.productUrl}
                                type="video/mp4"
                              />
                              {productDigitalTranslation?.supportLine
                                ? productDigitalTranslation?.supportLine
                                : "Your browser does not support the video tag."}
                            </video>
                          </>
                        ))}
                      {digitalType === "music" && (
                        <>
                          <audio controls>
                            <source
                              src={singleProduct.productUrl}
                              type="audio/mpeg"
                            />
                          </audio>
                        </>
                      )}
                    </div>
                  </Col>
                </FormGroup>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ViewEbooks;
