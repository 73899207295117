import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createsellerSupport,
  getsellerSupport,
} from "../../redux/reducers/user";
import { useHistory } from "react-router-dom";
// import {
//   Row,
//   Col,
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   Label,
//   Input,
//   InputGroup,
//   Dropdown,
//   Form,
//   FormGroup,
//   InputGroupAddon,
// } from "reactstrap";

const CreateSellerTicket = () => {
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const { sellers } = useSelector((state) => state.admin);

  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];

  const history = useHistory();
  const dispatch = useDispatch();
  const [pageContent, setPageContent] = useState({
    subject: "",
    message: "",
    // sellerList: [],
  });
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };

  // const announcementTypes = [
  //   {
  //     name: "Everyone",
  //     checked: true,
  //   },
  //   {
  //     name: "Specific Seller",
  //     checked: false,
  //   },
  // ];

  const HandleTicket = () => {
    const { subject, message } = pageContent;
    const data = {
      subject,
      message,
    };

    dispatch(createsellerSupport(data)).then(() => {
      dispatch(getsellerSupport());
      history.push("/customer-account/seller-support-tickets");
    });
  };

  // const HandleTicket = () => {
  //   const { announcementType, sellerList, message , subject } = pageContent;
  //   if (!message) {
  //     toast.error("notification is empty");
  //   } else {
  //     let to = sellerList;
  //     if (announcementType == "Everyone") {
  //       to = sellers.map((item) => item._id);
  //     }
  //     const data = {
  //       message: message,
  //       subject,
  //       to,
  //       date: new Date(),
  //     };
  //     dispatch(createsellerSupport(data));
  //     history.push("/admin/announcementlist");
  //   }
  // };

  return (
    <>
      <div style={{ marginTop: "90px" }}>
        <div class="form-group">
          <label for="exampleFormControlInput1">
            {userDashboardTranslation?.subjecttext
              ? userDashboardTranslation?.subjecttext
              : "Subject"}
          </label>
          <input
            type="text"
            className="form-control"
            name="subject"
            onChange={handleInputChange}
            id="exampleFormControlInput1"
            placeholder={
              userDashboardTranslation?.subjecttext
                ? userDashboardTranslation?.subjecttext
                : "subject"
            }
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextarea1">
            {userDashboardTranslation?.placeHolder
              ? userDashboardTranslation?.placeHolder
              : "Message"}
          </label>
          <textarea
            placeholder={
              userDashboardTranslation?.message
                ? userDashboardTranslation?.message
                : "type message "
            }
            className="form-control"
            name="message"
            onChange={handleInputChange}
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
        <div>
          <button
            type="submit"
            className="button-color mx-1"
            onClick={HandleTicket}
          >
            {userDashboardTranslation?.submit
              ? userDashboardTranslation?.submit
              : "Submit"}
          </button>
          <button
            type="submit"
            className="button-color"
            onClick={() =>
              history.push("/customer-account/seller-support-tickets")
            }
          >
            {userDashboardTranslation?.cancel
              ? userDashboardTranslation?.cancel
              : "Cancel"}
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSellerTicket;
