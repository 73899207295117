import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getTranslation, getsellerSupport } from "../../redux/reducers/user";
import { FaEye } from "react-icons/fa";

const SupportTicket = () => {
  const dispatch = useDispatch();
  const { sellersupport, loading } = useSelector((state) => state.user);
  const { user: _id } = useSelector(({ auth }) => auth);

  const history = useHistory();

  useEffect(() => {
    dispatch(getsellerSupport());
  }, []);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];
  const residencySwapTranslation =
    translationState?.[0]?.translations?.residencySwap?.[userLanguage];

  return (
    <>
      <div className="main-row">
        <div className="ticket-button-wrapper">
          <div className="ticket-box-wrapper">
            <p>
              {userDashboardTranslation?.ticket
                ? userDashboardTranslation?.ticket
                : "Open New Ticket"}{" "}
            </p>
          </div>
          <button
            onClick={() =>
              history.push("/customer-account/open-sellersupport-ticket")
            }
            className="button-color m-3"
          >
            {userDashboardTranslation?.open
              ? userDashboardTranslation?.open
              : "Open"}
          </button>
        </div>
        <div className="">
          <span className="mx-2">
            <small style={{ cursor: "pointer" }}>
              {userDashboardTranslation?.all
                ? userDashboardTranslation?.all
                : "All Tickets()"}
            </small>
          </span>
          |
          <span className="mx-2">
            <small style={{ cursor: "pointer" }}>
              {userDashboardTranslation?.open
                ? userDashboardTranslation?.open
                : "Open Tickets()"}
            </small>
          </span>
          |
          <span className="mx-2">
            <small
              style={{ cursor: "pointer" }}
              onClick={() => setType("closedClick")}
            >
              {userDashboardTranslation?.closed
                ? userDashboardTranslation?.closed
                : "Closed Ticket()"}
            </small>
          </span>
        </div>
        <div className="my-2">
          <table className="table">
            <thead className="bg-color">
              <tr>
                <th>
                  {digitalProductTranslation?.title
                    ? digitalProductTranslation?.title
                    : "Title"}
                </th>
                {/* <th>Status</th> */}
                <th>
                  {advertisementsTranslation?.date
                    ? advertisementsTranslation?.date
                    : "Date"}
                </th>
                <th>
                  {digitalProductTranslation?.action
                    ? digitalProductTranslation?.action
                    : "Action"}
                </th>
              </tr>
            </thead>

            {loading ? (
              <td colSpan={4} className="py-5">
                <div style={{ textAlign: "center" }}>
                  <i
                    className="fas fa-spinner fa-pulse fa-2x"
                    style={{ color: "green" }}
                  ></i>
                </div>
              </td>
            ) : sellersupport?.length > 0 && !loading ? (
              sellersupport?.map((data, index) => {
                const bgUnread =
                  data.status !== "unread" &&
                  data.thread.find((item) => {
                    return item.isRead === false && _id !== item.from;
                  });
                return (
                  <tbody key={index} style={{ border: "1px solid lightgray" }}>
                    <tr className="text-center">
                      <td>{data.subject}</td>
                      {/* <td>
                            <button
                              type="button"
                              className="btn btn-warning btn-sm"
                              style={{ cursor: "auto" }}
                            >
                              <b>{data.status}</b>
                            </button>
                          </td> */}
                      <td>
                        {moment(data.createdAt).format("MMM / Do / YYYY")}
                      </td>
                      <td>
                        <div>
                          <FaEye
                            onClick={() =>
                              history.push(
                                `/customer-account/view-sellersupport-detail/${data._id}`
                              )
                            }
                            className="support-detail-view-icons"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : (
              <td colSpan={4} className="py-5">
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "red" }}>
                    {walletTranslation?.notFound
                      ? walletTranslation?.notFound
                      : "Records Not Found"}{" "}
                  </p>
                </div>
              </td>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default SupportTicket;
