import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  InputGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  Button,
} from "reactstrap";
import SkyLight from "react-skylight";
import {
  addFilter,
  getAllSellerSupports,
  DeleteSellerSupport,
} from "../../redux/reducers/admin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import "./SellerSupport.css";
import { DeleteChat, GetCustomerChat } from "../../redux/reducers/seller";

const divStyle = {
  marginLeft: "10%",
};

const myBigGreenDialog = {
  width: "40%",
  // height: '39%',
  minHeight: "20%",
};

const SellerSupport = () => {
  const approveProductModal = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const {
    user: { _id },
  } = useSelector((state) => state.auth);
  const { sellerSupports, count, filters, loading } = useSelector(
    (state) => state.admin
  );
  const { customerChats } = useSelector((state) => state.seller);
  const { userLanguage } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);

  const customerTranslation =
    translationState?.[0]?.translations?.customer?.[userLanguage];
  const eventTranslation =
    translationState?.[0]?.translations?.event?.[userLanguage];

  const [chatType, setChatType] = useState("seller"); // `customer` for customer-seller chat
  const [selectId, setSelectedId] = useState("");
  const [supportData, setSupportData] = useState({ support: [] });

  // const { value: PageNo } = filters.find((item) => item.field === "pageNo");

  useEffect(() => {
    if (pathname === "/seller/customer-support") {
      dispatch(GetCustomerChat());
      setChatType("customer");
    } else {
      dispatch(getAllSellerSupports());
    }
  }, []);

  useEffect(() => {
    let data = {};
    if (chatType === "customer" /*  && customerChats?.length */) {
      data = { ...data, support: customerChats };
    } else if (chatType === "seller" /* && sellerSupports?.length */) {
      data = { ...data, support: sellerSupports };
    } else {
      data = { ...data, support: [] };
    }
    setSupportData({ ...supportData, ...data });
  }, [sellerSupports, customerChats]);

  // const searchElement = (evt) => {
  //   dispatch(addFilter({ field: "pageNo", value: 1 }));
  //   dispatch(addFilter({ field: "search", value: evt.target.value }));
  //   dispatch(getOrders());
  // };

  const viewSellerSupport = (id) => {
    if (chatType === "customer") {
      history.push(`/seller/support/view-customer-ticket/${id}`);
    } else {
      history.push(`/seller/support/view-ticket/${id}`);
    }
  };

  const paginate = (pageNo) => {
    if (pageNo > 0) {
      dispatch(addFilter({ field: "pageNo", value: pageNo }));
      dispatch(getOrders());
    }
  };

  const handleDelete = async () => {
    if (chatType === "customer") {
      await dispatch(DeleteChat(selectId));
      dispatch(GetCustomerChat());
    } else {
      await dispatch(DeleteSellerSupport(selectId));
      dispatch(getAllSellerSupports());
    }
    approveProductModal.current.hide();
  };
  const closeSkyLight = () => {
    approveProductModal.current.hide();
  };

  return (
    <div className="animated fadeIn">
      <ToastContainer autoClose={20000} />
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={approveProductModal}
        title={
          <h4>
            <p style={{ display: "flex", justifyContent: "center" }}>
              <b>Are you sure you want to Delete this ...?</b>
            </p>
          </h4>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="submit"
            onClick={handleDelete}
            size="md"
            color="warning"
          >
            YES
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={closeSkyLight}
            color="danger"
          >
            ON
          </Button>
        </Row>
      </SkyLight>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="9">
                  <b>
                    {chatType === "customer"
                      ? customerTranslation?.customertext
                        ? customerTranslation?.customertext
                        : "Customer Support"
                      : customerTranslation?.sellerText
                      ? customerTranslation?.sellerText
                      : "Seller Support"}
                  </b>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="card-body">
              <Row>
                {/* <Col xs="12" sm="4">
                  <InputGroup>
                    <Input
                      type="text"
                      id="input1-group2"
                      onChange={searchElement}
                      className="search-color"
                      name="input1-group2"
                      placeholder=" Search Order by id"
                    />
                  </InputGroup>
                </Col> */}
              </Row>
              <br />
              <Table hover bordered striped responsive size="sm">
                <thead align="center">
                  <tr className="header-color">
                    <th>
                      {chatType === "customer"
                        ? customerTranslation?.customerEmail
                          ? customerTranslation?.customerEmail
                          : "Customer Email"
                        : customerTranslation?.ticket
                        ? customerTranslation?.ticket
                        : "Ticket Id"}
                    </th>
                    <th>
                      {customerTranslation?.title
                        ? customerTranslation?.title
                        : "Title"}
                    </th>
                    <th>
                      {customerTranslation?.date
                        ? customerTranslation?.date
                        : "Date"}
                    </th>
                    <th>
                      {customerTranslation?.action
                        ? customerTranslation?.action
                        : "Action"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan={5}>
                        <div style={{ textAlign: "center" }}>
                          <i
                            className="fas fa-spinner fa-pulse fa-2x"
                            style={{ color: "green", margin: "80px" }}
                          ></i>
                        </div>
                      </td>
                    </tr>
                  ) : supportData.support?.length > 0 && !loading ? (
                    supportData.support?.map((item, index) => {
                      const bgUnread = item.thread.find((item1) => {
                        return item1.isRead === false && _id !== item1.from;
                      });
                      return (
                        <tr align="center" key={index}>
                          {chatType === "customer" ? (
                            <td>{item.from.email}</td>
                          ) : (
                            <td>{item._id}</td>
                          )}
                          <td>{item.subject}</td>
                          <td>
                            {moment(item.createdAt).format(
                              "MMM D YYYY, h:mm A"
                            )}
                          </td>
                          <td>
                            <Button
                              type="submit"
                              onClick={() => {
                                viewSellerSupport(item._id);
                              }}
                              color="warning"
                              style={{ margin: "5px" }}
                              btn="sm"
                            >
                              <b>
                                {chatType === "customer"
                                  ? customerTranslation?.viewChat
                                    ? customerTranslation?.viewChat
                                    : "View Chat"
                                  : customerTranslation?.viewTicket
                                  ? customerTranslation?.viewTicket
                                  : "View Ticket"}
                              </b>
                            </Button>
                            <Button
                              type="submit"
                              onClick={() => {
                                setSelectedId(item._id);
                                approveProductModal.current.show();
                              }}
                              color="warning"
                              style={divStyle}
                              btn="sm"
                            >
                              <b>
                                {eventTranslation?.delete
                                  ? eventTranslation?.delete
                                  : "Delete"}
                              </b>
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="py-5 text-center">
                        <p style={{ color: "red" }}>
                          {customerTranslation?.record
                            ? customerTranslation?.record
                            : "Records Not Found"}
                        </p>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              {/* <nav>
                <Pagination>
                  <PaginationItem>
                    <PaginationLink onClick={(e) => paginate(PageNo - 1)}>
                      prev
                    </PaginationLink>
                  </PaginationItem>
                  <PaginationItem>
                    <PaginationLink onClick={(e) => paginate(PageNo + 1)}>
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav> */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SellerSupport;
