import React, { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RTCPublisher } from "red5pro-webrtc-sdk";
import { updateOrderStreamingFalse } from "../../../redux/reducers/seller";
import "./videEvent.css";
import { toast } from "react-toastify";

const WebRTCComponent = memo((props) => {
  const { rtcProp, onClose } = props;
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const { userLanguage } = useSelector((state) => state.auth);
  const { translationState } = useSelector((state) => state.user);
  const [translatedContent, setTranslatedContent] = useState({});
  const [isCloseStream, setIsCloseStream] = useState(true);

  useEffect(() => {
    if (videoRef?.current) {
      start();
    }
  }, []);

  const toastTranslation =
    translationState[0].translations?.toast?.[userLanguage];

  useEffect(() => {
    if (translationState?.length) {
      const eventTranslation =
        translationState[0].translations?.event?.[userLanguage];
      eventTranslation
        ? setTranslatedContent({ ...translatedContent, ...eventTranslation })
        : setTranslatedContent({});
    }
  }, [translationState, userLanguage]);

  const start = async () => {
    try {
      const publisher = new RTCPublisher();
      await publisher.init({
        protocol: "wss",
        port: 5443,
        host: "live.yinn.ca",
        app: "live",
        streamName: rtcProp?.eventTokenId,
        rtcConfiguration: {
          iceServers: [{ urls: "stun:stun2.l.google.com:19302" }],
          iceCandidatePoolSize: 2,
          bundlePolicy: "max-bundle",
        },
        streamMode: "record",
        mediaElementId: "red5pro-publisher",
        bandwidth: {
          audio: 56,
          video: 512,
        },
        mediaConstraints: {
          audio: true,
          video: {
            width: {
              exact: 640,
            },
            height: {
              exact: 480,
            },
            frameRate: {
              min: 8,
              max: 24,
            },
          },
        },
      });
      // publisher.on("*", handlePublisherEvent);
      await publisher.publish();
    } catch (e) {
      toast.error(
        `${
          translatedContent.publisherError ||
          "An error occured in establishing a broadcast session:"
        }`
      );
      console.error(
        `🚀 ~ ${
          translatedContent.publisherError ||
          "An error occured in establishing a broadcast session:"
        }`,
        e
      );
      handleCloseStream();
    }
  };
  const handleFullScreen = () => {
    const video = videoRef.current;
    if (video.requestFullscreen) {
      video.requestFullscreen();
    }
  };
  const handleCloseStream = () => {
    toast.info(
      `${
        toastTranslation?.streamEnd
          ? toastTranslation?.streamEnd
          : "Live stream ended."
      }`
    );
    rtcProp?.productId &&
      dispatch(updateOrderStreamingFalse({ productIds: rtcProp.productId }));
    const video = videoRef?.current;
    const mediaStream = video?.srcObject;
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
      video.srcObject = null;
      setIsCloseStream(false);
      onClose();
    }
  };

  return (
    // <div>
    //   <div className="d-lflex justify-content-center ">
    //     {isCloseStream && (
    //       <video
    //         id="red5pro-publisher"
    //         style={{ maxWidth: "500px", height: "400px" }}
    //         ref={videoRef}
    //         controls={false}
    //         autoPlay
    //         muted
    //         className="d-block mx-auto"
    //       ></video>
    //     )}
    //   </div>
    //   {isCloseStream && (
    //     <div className="d-flex justify-content-center mt-2">
    //       <button onClick={handleFullScreen} className="mx-2">
    //         {translatedContent.fullScreen || "Full Screen"}
    //       </button>
    //       <button onClick={handleCloseStream}>
    //         {translatedContent.closeStream || "Close Stream "}
    //       </button>
    //     </div>
    //   )}
    // </div>

    <div>
      <div className="d-flex justify-content-center">
        {isCloseStream && (
          <div className="video-wrapper">
            <video
              id="red5pro-publisher"
              style={{ maxWidth: "100%", height: "auto" }}
              ref={videoRef}
              controls={false}
              autoPlay
              muted
              className="embed-responsive-item d-block mx-auto"
              playsInline
            ></video>
          </div>
        )}
      </div>
      {isCloseStream && (
        <div className="d-flex justify-content-center mt-2">
          <button onClick={handleFullScreen} className="mx-2">
            {translatedContent.fullScreen || "Full Screen"}
          </button>
          <button onClick={handleCloseStream}>
            {translatedContent.closeStream || "Close Stream "}
          </button>
        </div>
      )}
    </div>
  );
});

export default WebRTCComponent;
