import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  Input,
  Col,
  Row,
  Button,
  Card,
  CardBody,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardHeader,
  Tooltip,
} from "reactstrap";
import {
  addFilter,
  deleteProduct,
  getProducts,
} from "../../../redux/reducers/seller";
import SkyLight from "react-skylight";

const DigitalProducts = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const deleteProductModal = useRef(null);
  const urlArray = history.location.pathname.split("/");
  const digitalType = urlArray[urlArray.length - 1];
  const [selectedId, setSelectedId] = useState(0);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];
  const productTranslation =
    translationState?.[0]?.translations?.product?.[userLanguage];
  const reportingTranslation =
    translationState?.[0]?.translations?.reporting?.[userLanguage];
  const missedTranslation =
    translationState?.[0]?.translations?.missed?.[userLanguage];
  const otherDigitalProductTranslation =
    translationState?.[0]?.translations?.otherDigitalProduct?.[userLanguage];

  const [tooltipOpen, setTooltipOpen] = useState({
    view: {},
    edit: {},
    delete: {},
  });

  const toggle = (type, id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [type]: {
        ...prevState[type],
        [id]: !prevState[type][id],
      },
    }));
  };

  const {
    user: { userType },
  } = useSelector((state) => state.auth);
  const { products, count, filters, loading } = useSelector(
    (state) => state.seller
  );
  const { value: PageNo } = filters.find((item) => item.field === "pageNo");
  useEffect(() => {
    dispatch(
      addFilter({
        field: "type",
        value: "digital",
      })
    );
    dispatch(
      addFilter({
        field: "digitalType",
        value: digitalType,
      })
    );
    dispatch(getProducts());
  }, [digitalType]);
  const deleteModal = (id) => {
    setSelectedId(id);
    deleteProductModal.current.show();
  };
  const deleteItem = () => {
    dispatch(deleteProduct(selectedId)).then(() => {
      deleteProductModal.current.hide();
      dispatch(getProducts());
    });
  };

  const searchElement = (evt) => {
    dispatch(
      addFilter({
        field: "pageNo",
        value: 1,
      })
    );
    dispatch(
      addFilter({
        field: "search",
        value: evt.target.value,
      })
    );
    dispatch(getProducts());
  };
  const paginate = (pageNo) => {
    if (pageNo > 0) {
      dispatch(
        addFilter({
          field: "pageNo",
          value: pageNo,
        })
      );
      dispatch(getProducts());
    }
  };
  const showProduct = (id) => {
    history.push(`/seller/digitalproduct/${digitalType}/view/${id}`);
  };

  const editProduct = (id, index) => {
    history.push(`/seller/digitalproduct/${digitalType}/update/${id}`);
  };
  var myBigGreenDialog = {
    width: "40%",
    minHeight: "20%",
  };
  return (
    <div className="animated fadeIn">
      <SkyLight
        dialogStyles={myBigGreenDialog}
        hideOnOverlayClicked
        ref={deleteProductModal}
        title={
          <p style={{ display: "flex", justifyContent: "center" }}>
            <b>
              <h4>Are you sure you want to delete this Product ?</h4>
            </b>
          </p>
        }
      >
        <Row style={{ display: "flex", justifyContent: "center" }}>
          <Button type="submit" onClick={deleteItem} size="md" color="warning">
            Yes
          </Button>
          <Col xs="12" sm="1"></Col>
          <Button
            type="submit"
            size="md"
            onClick={() => deleteProductModal.current.hide()}
            color="danger"
          >
            No
          </Button>
        </Row>
      </SkyLight>
      {/* layout for prising table */}
      <Row>
        <Col xs="12" md="12">
          <Card>
            <CardHeader>
              <Row>
                <Col xs="12" sm="10">
                  <strong>
                    {digitalProductTranslation?.digitalProduct
                      ? digitalProductTranslation?.digitalProduct
                      : "Digital Products List"}
                  </strong>
                </Col>
                <Col xs="12" sm="2">
                  <Button
                    onClick={() =>
                      history.push(`/seller/digitalproduct/${digitalType}/add`)
                    }
                    size="sm"
                    color="warning"
                  >
                    <b style={{ textTransform: "capitalize" }}>
                      {digitalProductTranslation?.addbutton
                        ? digitalProductTranslation?.addbutton
                        : "Add "}{" "}
                      {digitalProductTranslation?.[digitalType]
                        ? digitalProductTranslation?.[digitalType]
                        : [digitalType]}
                    </b>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="animated fadeIn">
                <Row>
                  <Col xs="12" lg="12">
                    <Card>
                      <CardHeader>
                        <i className="fa fa-align-justify"></i>{" "}
                        <b style={{ textTransform: "capitalize" }}>
                          {digitalProductTranslation?.[digitalType]
                            ? digitalProductTranslation?.[digitalType]
                            : [digitalType]}
                        </b>
                      </CardHeader>
                      {digitalType === "series" && (
                        <div className="note ml-1">
                          {digitalProductTranslation?.note
                            ? digitalProductTranslation?.note + ": "
                            : ""}
                          {digitalProductTranslation?.noteExplain
                            ? digitalProductTranslation?.noteExplain
                            : " Note : Use Edit option to add episodes into the series"}
                        </div>
                      )}
                      <CardBody>
                        <Row>
                          <Col xs="12" sm="4">
                            <InputGroup>
                              <Input
                                type="text"
                                id="input1-group2"
                                onChange={searchElement}
                                className="search-color"
                                name="input1-group2"
                                placeholder={
                                  productTranslation?.search
                                    ? productTranslation?.search
                                    : "search"
                                }
                              />
                            </InputGroup>
                          </Col>
                        </Row>
                        <br />
                        <Table hover bordered striped responsive size="sm">
                          <thead align="center">
                            <tr className="header-color">
                              <th>
                                {productTranslation?.name
                                  ? productTranslation?.name
                                  : "Name"}
                              </th>
                              {digitalType === "theater" && (
                                <>
                                  <th>
                                    {digitalProductTranslation?.director
                                      ? digitalProductTranslation?.director
                                      : "Director"}
                                  </th>
                                  <th>
                                    {otherDigitalProductTranslation?.quanlity
                                      ? otherDigitalProductTranslation?.quanlity
                                      : "Video Quality"}
                                  </th>
                                  <th>
                                    {missedTranslation?.duration
                                      ? missedTranslation?.duration
                                      : "Duration"}
                                  </th>
                                </>
                              )}
                              {digitalType === "series" && (
                                <>
                                  <th>
                                    {digitalProductTranslation?.director
                                      ? digitalProductTranslation?.director
                                      : "Director"}
                                  </th>
                                </>
                              )}
                              {digitalType === "software" && (
                                <>
                                  <th>
                                    {missedTranslation?.device
                                      ? missedTranslation?.device
                                      : "Supported Device"}
                                  </th>
                                  <th>
                                    {missedTranslation?.release
                                      ? missedTranslation?.release
                                      : "Release Date"}
                                  </th>
                                  <th>
                                    {digitalProductTranslation?.category
                                      ? digitalProductTranslation?.category
                                      : "Category"}
                                  </th>
                                </>
                              )}
                              {digitalType === "movie" && (
                                <>
                                  <th>
                                    {digitalProductTranslation?.director
                                      ? digitalProductTranslation?.director
                                      : "Director"}
                                  </th>
                                  <th>
                                    {otherDigitalProductTranslation?.quanlity
                                      ? otherDigitalProductTranslation?.quanlity
                                      : "Video Quality"}{" "}
                                  </th>
                                  <th>
                                    {" "}
                                    {missedTranslation?.duration
                                      ? missedTranslation?.duration
                                      : "Duration"}
                                  </th>
                                </>
                              )}
                              {digitalType === "game" && (
                                <>
                                  <th>
                                    {missedTranslation?.device
                                      ? missedTranslation?.device
                                      : "Supported Device"}
                                  </th>
                                  <th>
                                    {missedTranslation?.release
                                      ? missedTranslation?.release
                                      : "Release Date"}
                                  </th>
                                </>
                              )}
                              {digitalType === "ebook" && (
                                <>
                                  <th>
                                    {digitalProductTranslation?.stockQuantity
                                      ? digitalProductTranslation?.stockQuantity
                                      : "Stock Quantity"}
                                  </th>
                                </>
                              )}
                              <th> MSRP</th>
                              {digitalType === "music" && (
                                <>
                                  <th>
                                    {missedTranslation?.singer
                                      ? missedTranslation?.singer
                                      : "Singer"}{" "}
                                  </th>
                                  <th>
                                    {otherDigitalProductTranslation?.quanlity
                                      ? otherDigitalProductTranslation?.quanlity
                                      : "Audio Quality"}
                                  </th>
                                  <th>
                                    {missedTranslation?.duration
                                      ? missedTranslation?.duration
                                      : "Duration"}
                                  </th>
                                </>
                              )}
                              <th>
                                {productTranslation?.salePrice
                                  ? productTranslation?.salePrice
                                  : "Sale Price"}
                              </th>
                              <th>
                                {productTranslation?.action
                                  ? productTranslation?.action
                                  : "Action"}{" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <td colSpan="10" className="py-5">
                                <div style={{ textAlign: "center" }}>
                                  <i
                                    className="fas fa-spinner fa-pulse fa-2x"
                                    style={{ color: "green" }}
                                  ></i>
                                </div>
                              </td>
                            ) : products.length > 0 && !loading ? (
                              products.map((item, index) => {
                                const oldItem = item.translatedArray.find(
                                  (item) => item.language === userLanguage
                                );
                                return (
                                  <tr>
                                    <td>
                                      {oldItem?.name
                                        ? oldItem?.name
                                        : item.name}
                                    </td>
                                    {digitalType === "theater" && (
                                      <>
                                        <td align="center">{item.director}</td>
                                        <td align="center">
                                          {item.videoQuality}
                                        </td>
                                        <td align="center">{item.runtime}</td>
                                      </>
                                    )}
                                    {digitalType === "series" && (
                                      <>
                                        <td align="center">{item.director}</td>
                                      </>
                                    )}
                                    {digitalType === "software" && (
                                      <>
                                        <td align="center">
                                          {item.requireDevice}
                                        </td>
                                        <td align="center">
                                          {item.releaseDate}
                                        </td>
                                        <td align="center">
                                          {item.softwareCategory}
                                        </td>
                                      </>
                                    )}
                                    {digitalType === "movie" && (
                                      <>
                                        <td align="center">{item.director}</td>
                                        <td align="center">
                                          {item.videoQuality}
                                        </td>
                                        <td align="center">{item.runtime}</td>
                                      </>
                                    )}
                                    {digitalType === "game" && (
                                      <>
                                        <td align="center">
                                          {item.requireDevice}
                                        </td>
                                        <td align="center">
                                          {item.releaseDate}
                                        </td>
                                      </>
                                    )}
                                    {digitalType === "ebook" && (
                                      <>
                                        <td align="center">
                                          {item.stockQuantity}
                                        </td>
                                      </>
                                    )}
                                    {digitalType === "music" && (
                                      <>
                                        <td align="center">
                                          {item.singerName}
                                        </td>
                                        <td align="center">
                                          {item.audioQuality}
                                        </td>
                                        <td align="center">{item.runtime}</td>
                                      </>
                                    )}
                                    <td align="center">${item.regularPrice}</td>
                                    <td align="center">${item.salesPrice}</td>
                                    <td
                                      align="center"
                                      style={{ flexWrap: "wrap" }}
                                    >
                                      <i
                                        className="fa fa-eye fa-lg mt-4 action-icon"
                                        id={`view-${item._id}`}
                                        onClick={(e) => showProduct(item._id)}
                                      ></i>
                                      <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen.view[item._id]}
                                        target={`view-${item._id}`}
                                        toggle={() => toggle("view", item._id)}
                                        className="custom-tooltip"
                                      >
                                        {digitalProductTranslation?.viewProduct ||
                                          "View Product"}
                                      </Tooltip>

                                      <i
                                        className="fa fa-edit fa-lg mt-4 action-icon"
                                        id={`edit-${item._id}`}
                                        style={{
                                          marginLeft: "8px",
                                          marginRight: "8px",
                                        }}
                                        onClick={(e) => editProduct(item._id)}
                                      ></i>
                                      <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen.edit[item._id]}
                                        target={`edit-${item._id}`}
                                        toggle={() => toggle("edit", item._id)}
                                        className="custom-tooltip"
                                      >
                                        {digitalProductTranslation?.editProduct ||
                                          "Edit Product"}
                                      </Tooltip>

                                      <i
                                        className="fa fa-trash fa-lg mt-4 action-icon"
                                        id={`delete-${item._id}`}
                                        onClick={(e) => deleteModal(item._id)}
                                      ></i>
                                      <Tooltip
                                        placement="top"
                                        isOpen={tooltipOpen.delete[item._id]}
                                        target={`delete-${item._id}`}
                                        toggle={() =>
                                          toggle("delete", item._id)
                                        }
                                        className="custom-tooltip"
                                      >
                                        {digitalProductTranslation?.deleteProduct ||
                                          "Delete Product"}
                                      </Tooltip>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <td colSpan={10} className="py-5">
                                <div style={{ textAlign: "center" }}>
                                  <p style={{ color: "red" }}>
                                    {digitalProductTranslation?.notAvailable
                                      ? digitalProductTranslation?.notAvailable
                                      : "Records Not Found"}
                                  </p>
                                </div>
                              </td>
                            )}
                          </tbody>
                        </Table>
                      </CardBody>
                    </Card>
                    <nav>
                      <Pagination>
                        <PaginationItem style={{ marginRight: "3px" }}>
                          <PaginationLink
                            onClick={(e) => paginate(PageNo - 1)}
                            style={{ backgroundColor: "rgb(228, 231, 234)" }}
                          >
                            {reportingTranslation?.previousButton
                              ? reportingTranslation?.previousButton
                              : "Prev"}
                          </PaginationLink>
                        </PaginationItem>
                        <PaginationItem>
                          <PaginationLink
                            onClick={(e) => paginate(PageNo + 1)}
                            style={{ backgroundColor: "rgb(228, 231, 234)" }}
                            className="mx-2"
                          >
                            {reportingTranslation?.nextButton
                              ? reportingTranslation?.nextButton
                              : "Next"}
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DigitalProducts;
