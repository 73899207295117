export const translations = {
  myProfile: {
    en: "My Profile",
    fr: "Mon profil",
    es: "Mi perfil",
    ar: "ملفي الشخصي",
    zh: "我的个人资料",
    pt: "Meu perfil",
  },
  myAccount: {
    en: "My Account",
    fr: "Mon compte",
    es: "Mi cuenta",
    ar: "حسابي",
    zh: "我的账户",
    pt: "Minha conta",
  },
  myOrders: {
    en: "My Orders",
    fr: "Mes commandes",
    es: "Mis pedidos",
    ar: "طلباتي",
    zh: "我的订单",
    pt: "Meus pedidos",
  },
  yinnCoins: {
    en: "Yinn Coins",
    fr: "Pièces Yinn",
    es: "Monedas Yinn",
    ar: "عملات ين",
    zh: "Yinn硬币",
    pt: "Moedas Yinn",
  },
  registerAsSeller: {
    en: "Register as Seller",
    fr: "S'inscrire en tant que vendeur",
    es: "Registrarse como vendedor",
    ar: "التسجيل كبائع",
    zh: "注册为卖家",
    pt: "Registrar-se como vendedor",
  },
  logout: {
    en: "Logout",
    fr: "Déconnexion",
    es: "Cerrar sesión",
    ar: "تسجيل الخروج",
    zh: "退出登录",
    pt: "Sair",
  },
  switchToSellerView: {
    en: "Switch to Seller View",
    fr: "Passer à la vue vendeur",
    es: "Cambiar a vista de vendedor",
    ar: "التبديل إلى عرض البائع",
    zh: "切换到卖家视图",
    pt: "Mudar para visão do vendedor",
  },
  profileSetting: {
    en: "Profile Setting",
    fr: "Paramètres du profil",
    es: "Configuración del perfil",
    ar: "إعدادات الملف الشخصي",
    zh: "个人资料设置",
    pt: "Configuração de perfil",
  },
  switchToUserView: {
    en: "Switch to User View",
    fr: "Passer à la vue utilisateur",
    es: "Cambiar a vista de usuario",
    ar: "التبديل إلى عرض المستخدم",
    zh: "切换到用户视图",
    pt: "Mudar para visão do usuário",
  },
  wallet: {
    en: "Wallet",
    fr: "Portefeuille",
    es: "Cartera",
    ar: "محفظة",
    zh: "钱包",
    pt: "Carteira",
  },
  switchToAdminView: {
    en: "Switch to Admin View",
    fr: "Passer à la vue administrateur",
    es: "Cambiar a vista de administrador",
    ar: "التبديل إلى عرض المسؤول",
    zh: "切换到管理员视图",
    pt: "Mudar para visão do administrador",
  },
  sellOnYinn: {
    en: "Sell on Yinn",
    fr: "Vendre sur Yinn",
    es: "Vender en Yinn",
    ar: "البيع على ين",
    zh: "在 Yinn 上销售",
    pt: "Vender no Yinn",
  },
  basePriceLessThanMaterialPrice: {
    en: "Base Price cannot be less than Material Price",
    fr: "Le prix de base ne peut pas être inférieur au prix du matériel",
    es: "El precio base no puede ser inferior al precio del material",
    ar: "لا يمكن أن يكون السعر الأساسي أقل من سعر المواد",
    zh: "基础价格不能低于材料价格",
    pt: "O preço base não pode ser inferior ao preço do material",
  },
  totalPercentageExceeds: {
    en: "Total percentage exceeds 100%. Please enter a valid percentage",
    fr: "Le pourcentage total dépasse 100 %. Veuillez entrer un pourcentage valide",
    es: "El porcentaje total excede el 100%. Por favor, ingrese un porcentaje válido",
    ar: "النسبة الإجمالية تتجاوز 100٪. يرجى إدخال نسبة مئوية صالحة",
    zh: "总百分比超过 100%。请输入有效的百分比",
    pt: "A porcentagem total excede 100%. Por favor, insira uma porcentagem válida",
  },
  totalPercentageLessThan: {
    en: "Total percentage is less than 100%. Please enter more percentages to reach 100%",
    fr: "Le pourcentage total est inférieur à 100 %. Veuillez entrer plus de pourcentages pour atteindre 100 %",
    es: "El porcentaje total es inferior al 100%. Por favor, ingrese más porcentajes para llegar al 100%",
    ar: "النسبة الإجمالية أقل من 100٪. يرجى إدخال المزيد من النسب للوصول إلى 100٪",
    zh: "总百分比小于 100%。请输入更多百分比以达到 100%",
    pt: "A porcentagem total é inferior a 100%. Por favor, insira mais porcentagens para chegar a 100%",
  },
  errorSavingProduct: {
    en: "An error occurred while saving the product",
    fr: "Une erreur est survenue lors de l'enregistrement du produit",
    es: "Ocurrió un error al guardar el producto",
    ar: "حدث خطأ أثناء حفظ المنتج",
    zh: "保存产品时出错",
    pt: "Ocorreu um erro ao salvar o produto",
  },
  closeToValuingProduct: {
    en: "You are close to valuing this product, try again",
    fr: "Vous êtes proche d'évaluer ce produit, réessayez",
    es: "Estás cerca de valorar este producto, inténtalo de nuevo",
    ar: "أنت قريب من تقييم هذا المنتج، حاول مرة أخرى",
    zh: "您即将评估此产品，请重试",
    pt: "Você está perto de avaliar este produto, tente novamente",
  },
  tooFarFromValuingProduct: {
    en: "You are too far from valuing this product, try again",
    fr: "Vous êtes trop loin d'évaluer ce produit, réessayez",
    es: "Estás demasiado lejos de valorar este producto, inténtalo de nuevo",
    ar: "أنت بعيد جدًا عن تقييم هذا المنتج، حاول مرة أخرى",
    zh: "您离评估此产品还差得远，请再试一次",
    pt: "Você está muito longe de avaliar este produto, tente novamente",
  },
  offerAccepted: {
    en: "Great! Your offer is accepted and thanks for your support",
    fr: "Génial ! Votre offre est acceptée et merci pour votre soutien",
    es: "¡Genial! Tu oferta ha sido aceptada y gracias por tu apoyo",
    ar: "رائع! تم قبول عرضك وشكرًا لدعمك",
    zh: "太棒了！您的报价已被接受，感谢您的支持",
    pt: "Ótimo! Sua oferta foi aceita e obrigado pelo seu apoio",
  },
  categoryNotSame: {
    en: "Category are not same, clear your cart",
    fr: "Les catégories ne sont pas identiques, veuillez vider votre panier",
    es: "Las categorías no son las mismas, vacía tu carrito",
    ar: "الفئات ليست متطابقة، قم بإفراغ سلة التسوق الخاصة بك",
    zh: "类别不同，请清空购物车",
    pt: "As categorias não são as mesmas, limpe seu carrinho",
  },
  loginDetailsNotVerified: {
    en: "Your login details could not be verified. Please try again.",
    fr: "Vos informations de connexion n'ont pas pu être vérifiées. Veuillez réessayer.",
    es: "No se pudieron verificar tus datos de inicio de sesión. Por favor, inténtalo de nuevo.",
    ar: "تعذر التحقق من تفاصيل تسجيل الدخول الخاصة بك. يرجى المحاولة مرة أخرى.",
    zh: "您的登录详细信息无法验证。请再试一次。",
    pt: "Seus dados de login não puderam ser verificados. Por favor, tente novamente.",
  },
  emailAlreadyExists: {
    en: "Email already exists.",
    fr: "L'email existe déjà.",
    es: "El correo electrónico ya existe.",
    ar: "البريد الإلكتروني موجود بالفعل.",
    zh: "电子邮件已存在。",
    pt: "O email já existe.",
  },
  sellerStore: {
    en: "Seller Store",
    fr: "Boutique du vendeur",
    es: "Tienda del vendedor",
    ar: "متجر البائع",
    zh: "卖家商店",
    pt: "Loja do vendedor",
  },
  allProducts: {
    en: "All Products",
    fr: "Tous les produits",
    es: "Todos los productos",
    ar: "جميع المنتجات",
    zh: "所有产品",
    pt: "Todos os produtos",
  },
};

export const ProductTranslation = {
  andAbove: {
    en: "and above",
    fr: "et au-dessus",
    es: "y superior",
    ar: "وما فوق",
    zh: "及以上",
    pt: "e acima",
  },
  selectMainCategory: {
    en: "Select Main Category",
    fr: "Sélectionnez la catégorie principale",
    es: "Seleccionar categoría principal",
    ar: "اختر الفئة الرئيسية",
    zh: "选择主类别",
    pt: "Selecionar categoria principal",
  },
  selectSubCategory: {
    en: "Select Sub Category",
    fr: "Sélectionnez la sous-catégorie",
    es: "Seleccionar subcategoría",
    ar: "اختر الفئة الفرعية",
    zh: "选择子类别",
    pt: "Selecionar subcategoria",
  },
  subCategory: {
    en: "Sub Category",
    fr: "Sous-catégorie",
    es: "Subcategoría",
    ar: "فئة فرعية",
    zh: "子类别",
    pt: "Subcategoria",
  },
};

export const editProductDimensionUnits = {
  centimeter: {
    en: "centimeter",
    fr: "Centimètre",
    es: "Centímetro",
    ar: "سنتيمتر",
    zh: "厘米",
    pt: "Centímetro",
  },
  inches: {
    en: "inches",
    fr: "Pouces",
    es: "Pulgadas",
    ar: "بوصات",
    zh: "英寸",
    pt: "Polegadas",
  },
  feet: {
    en: "feet",
    fr: "Pieds",
    es: "Pies",
    ar: "أقدام",
    zh: "英尺",
    pt: "Pés",
  },
  meter: {
    en: "meter",
    fr: "Mètre",
    es: "Metro",
    ar: "متر",
    zh: "米",
    pt: "Metro",
  },
  kilogram: {
    en: "kilogram",
    fr: "Kilogramme",
    es: "Kilogramo",
    ar: "كيلوغرام",
    zh: "千克",
    pt: "Quilograma",
  },
  pound: {
    en: "pound",
    fr: "Livre",
    es: "Libra",
    ar: "رطل",
    zh: "磅",
    pt: "Libra",
  },
};

export const reverseUnitDimension = {
  centimeter: "centimeter",
  [`Centimètre`]: "centimeter",
  ["Centímetro"]: "centimeter",
  سنتيمتر: "centimeter",
  厘米: "centimeter",
  Centímetro: "centimeter",
  inches: "inches",
  Pouces: "inches",
  Pulgadas: "inches",
  بوصات: "inches",
  英寸: "inches",
  Polegadas: "inches",
  feet: "feet",
  Pieds: "feet",
  Pies: "feet",
  أقدام: "feet",
  英尺: "feet",
  Pés: "feet",
  meter: "meter",
  Mètre: "meter",
  Metro: "meter",
  متر: "meter",
  米: "meter",
  Metro: "meter",
  kilogram: "kilogram",
  Kilogramme: "kilogram",
  Kilogramo: "kilogram",
  كيلوغرام: "kilogram",
  千克: "kilogram",
  Quilograma: "kilogram",
  pound: "pound",
  Livre: "pound",
  Libra: "pound",
  رطل: "pound",
  磅: "pound",
  Libra: "pound",
};

export const toastTranslation = {
  you_must_login_to_continue: {
    en: "You must login to continue",
    fr: "Vous devez vous connecter pour continuer",
    es: "Debes iniciar sesión para continuar",
    ar: "يجب عليك تسجيل الدخول للمتابعة",
    zh: "您必须登录才能继续",
    pt: "Você deve fazer login para continuar",
  },
};
