import React, { useEffect, useState } from "react";
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarMinimizer,
  AppSidebarNav,
} from "@coreui/react";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import DefaultFooter from "./DefaultFooter";
import DefaultHeader from "./DefaultHeader";
import "./sidebar.css";
import CustomerAccount from "../views/CustomerAccount/CustomerAccount";
import "react-toastify/dist/ReactToastify.css";

const mainStyle = {
  backgroundColor: "#F6F6F6",
};

const UserLayout = (props) => {
  const { children } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const dashboardValueTranslation =
    translationState?.[0]?.translations?.dashboardValue?.[userLanguage];
  const cancelAccountTranslation =
    translationState?.[0]?.translations?.cancelAccount?.[userLanguage];

  const [activeId, setActiveId] = useState(1);

  const SideBar = () => {
    useEffect(() => {
      const findActivelink = values.find(
        (value) =>
          value?.link === history?.location?.pathname ||
          value?.innerLink?.includes(
            history?.location?.pathname?.split("/")?.slice(2, 3)?.join()
          )
      );
      setActiveId(findActivelink?.id);
    }, [history]);
    const values = [
      {
        id: 1,
        link: "/customer-account/dashboard",
        text: `${
          dashboardValueTranslation?.dashboard
            ? dashboardValueTranslation?.dashboard
            : "Dashboard"
        }`,
        innerLink: [""],
      },
      {
        id: 2,
        link: "/customer-account/account-details",
        text: `${
          dashboardValueTranslation?.detail
            ? dashboardValueTranslation?.detail
            : "Account Details"
        }`,
        innerLink: ["accountdetailsedit"],
      },
      {
        id: 3,
        link: "/customer-account/orders",
        text: `${
          dashboardValueTranslation?.orders
            ? dashboardValueTranslation?.orders
            : "Orders"
        }`,
        innerLink: ["vieworder"],
      },
      {
        id: 4,
        link: "/customer-account/downloads",
        text: `${
          dashboardValueTranslation?.downloads
            ? dashboardValueTranslation?.downloads
            : "Downloads"
        }`,
        innerLink: [""],
      },
      {
        id: 5,
        link: "/customer-account/addresses",
        text: `${
          dashboardValueTranslation?.address
            ? dashboardValueTranslation?.address
            : "Addresses"
        }`,
        innerLink: ["Edit-billing-address", "Edit-shipping-address"],
      },
      {
        id: 6,
        link: "/customer-account/seller-support-tickets",
        text: `${
          dashboardValueTranslation?.support
            ? dashboardValueTranslation?.support
            : "Seller Support Tickets"
        }`,
        innerLink: ["view-sellersupport-detail"],
      },
      {
        id: 7,
        link: "/customer-account/invoice",
        text: `${
          dashboardValueTranslation?.history
            ? dashboardValueTranslation?.history
            : "Payment  History"
        }`,
        innerLink: ["view-invoice"],
      },
      {
        id: 8,
        link: "/customer-account/payment-method",
        text: `${
          dashboardValueTranslation?.method
            ? dashboardValueTranslation?.method
            : "Payment method"
        }`,
        innerLink: ["user-purchase-coin"],
      },
      {
        id: 9,
        link: "/customer-account/payouts",
        text: `${
          dashboardValueTranslation?.payout
            ? dashboardValueTranslation?.payout
            : "Payout"
        }`,
        innerLink: [""],
      },
      {
        id: 10,
        link: "/customer-account/e-voucher",
        text: `${
          dashboardValueTranslation?.eVoucher
            ? dashboardValueTranslation?.eVoucher
            : "E-Voucher"
        }`,
        innerLink: ["evnet"],
      },
      {
        id: 11,
        link: "/customer-account/event",
        text: `${
          dashboardValueTranslation?.event
            ? dashboardValueTranslation?.event
            : "Event"
        }`,
        innerLink: [""],
      },
      {
        id: 12,
        link: "/customer-account/cancel-account",
        text: `${
          cancelAccountTranslation?.cancelAccount
            ? cancelAccountTranslation?.cancelAccount
            : "Cancel Account"
        }`,
        innerLink: [""],
      },
    ];
    return (
      <>
        <Row className="user-sidebar">
          <div className="ml-4 mb-25 none">
            <h3>
              {dashboardValueTranslation?.account
                ? dashboardValueTranslation?.account
                : "My Account"}
            </h3>
          </div>
          <div className="col-12 direction">
            <ul className="list-group list-group-flush">
              {values?.map((value, i) => (
                <li
                  style={{ borderRight: "none" }}
                  key={value.id}
                  onClick={() => {
                    history.push(value.link), setActiveId(value.id);
                  }}
                  className={`button-color-user list-group-item d-flex align-items-center ${
                    value.id === activeId ? "active-link" : ""
                  }`}
                >
                  {value.text}
                </li>
              ))}
            </ul>
          </div>
        </Row>
      </>
    );
  };

  return (
    <div className="app">
      {/* <ToastContainer autoClose={20000} /> */}
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body" style={{ marginTop: "75px" }}>
        <SideBar />
        <main className="main user-main" style={mainStyle}>
          {children}
        </main>
      </div>
      <DefaultFooter />
    </div>
  );
};
export default UserLayout;
