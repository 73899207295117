import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  switchRole,
  getCategories,
  paymentConfig,
  getCmsPages,
} from "../../redux/reducers/auth";
import PropTypes from "prop-types";
import { Button, Label, Col, Row } from "reactstrap";
import logo from "../../assets/img/brand/logo.png";
import Env from "../../environment/environment";
import axios from "axios";
import "./footer.css";
import cookie from "react-cookies";

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};
let date = new Date();
let year = date.getFullYear();
const DefaultFooter = (props) => {
  const { translationState, allWebContent } = useSelector(
    (state) => state.user
  );
  const { userLanguage } = useSelector((state) => state.auth);
  const footer = allWebContent?.[0]?.footer;
  const footerTranslation =
    translationState?.[0]?.translations?.footer?.[userLanguage];

  const dispatch = useDispatch();
  const history = useHistory();
  const { productCategories, paymentConfiguration, cmsPages, user } =
    useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCmsPages());
    dispatch(paymentConfig());
  }, []);
  let state = {
    categories: [],
    pages: [],
    title: "",
    selectedPage: {},
    currencies: [],
    currency: "USD",
  };
  // constructor(props) {
  //   super(props);
  //   sellerLogin = sellerLogin;
  //   // handleCopyrights = handleCopyrights;
  // }

  const componentWillMount = () => {
    axios.get(Env.url + "/currency/getAll").then((response) => {
      //setState({ currencies: response.data })
      var currency = cookie.load("currency");
      if (currency != undefined) {
        //setState({ currency: currency })
        for (var i in state.currencies) {
          if (state.currencies[i].currency == currency) {
            cookie.save("currencyIcon", state.currencies[i].icon);
            cookie.save("exchangeRate", state.currencies[i].exchangeRatio);
          }
        }
      }
    });
    axios.get(Env.url + "/currency/getAll").then((response) => {
      //setState({ currencies: response.data })
      var currency = cookie.load("currency");
      if (currency != undefined) {
        //setState({ currency: currency })
        for (var i in state.currencies) {
          if (state.currencies[i].currency == currency) {
            cookie.save("currencyIcon", state.currencies[i].icon);
            cookie.save("exchangeRate", state.currencies[i].exchangeRatio);
          }
        }
      }
    });
    axios
      .get(Env.url + "/cms_category/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.status == 200) {
          if (response.data) {
            //setState({ categories: response.data });
          }
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 &&
            err.response.message == "Invalid Token"
          ) {
            window.location = "#/login";
          }
        }
      });
    axios
      .get(Env.url + "/pages/get/cms/categories", {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then((response) => {
        if (response.status == 200) {
          //setState({ pages: response.data });
        }
      })
      .catch((err) => {
        if (err.response != undefined) {
          if (
            err.response.status == 401 &&
            err.response.message == "Invalid Token"
          ) {
            window.location = "#/login";
          }
        }
      });
  };

  const changeCurrency = (evt) => {
    if (evt.target.value == "USD") {
      cookie.save("currency", "USD");
      cookie.save("currencyIcon", "$");
      cookie.save("exchangeRate", 1);
      window.location.reload();
    } else {
      for (var i in state.currencies) {
        if (state.currencies[i].currency == evt.target.value) {
          cookie.save("currency", evt.target.value);
          cookie.save("currencyIcon", state.currencies[i].icon);
          cookie.save("exchangeRate", state.currencies[i].exchangeRatio);
          //setState({ currency: evt.target.value })
          window.location.reload();
        }
      }
    }
  };

  const contentSearch = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    // state.search_page.title = data;
    var data = data;

    var searchString = "?";
    if (data != "") {
      searchString = searchString + "title=" + data;
    }
    axios
      .get(Env.url + "/pages/search" + searchString, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth_token"),
        },
      })
      .then(function (response) {
        if (response.status == 200) {
          window.history.pushState({}, null, "#/page" + searchString);
          //setState({ selectedPage: response.data[0] });
          console.log(state.selectedPage, "selectedPage");
          // props.history.push("#/page"+searchString)
          window.location.reload("#/page" + searchString);
          window.location.reload(true);
          // setTimeout(function () { window.location.reload("#/page"+searchString) }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const sellerLogin = async () => {
    if (user && user.userType) {
      const data = {
        role: user?.userType === "seller" ? "user" : "seller",
      };
      dispatch(switchRole(data)).then(({ payload }) => {
        if (payload.user) {
          history.push(`/${payload.user?.userType}/profile`);
        }
      });
    } else {
      history.push("/auth/login");
    }
  };
  const { children, ...attributes } = props;
  return (
    <div className="animated fadeIn ">
      <div style={{ backgroundColor: "white" }}>
        <Row className="col-12 footer-part" style={{ marginTop: 20 }}>
          <Col xs="12" sm="3" md="3" lg="3">
            <Row>
              <img id="yinn-logo" style={{ height: "80px" }} src={logo} />
            </Row>
            <Row>
              <h6>
                <Label className="text-color">
                  {footerTranslation?.contant
                    ? footerTranslation?.contant
                    : footer?.contant}
                  {/* Yinn.ca is the intersection where local small businesses in
                  innovation and creation meet with shoppers. We help you find
                  your favorite handmade and digital goods online and at stores
                  near you. */}
                </Label>
              </h6>
            </Row>
          </Col>
          <Col lg="2" md="2" className="text-color column-container">
            {cmsPages?.length > 0 && (
              <p id="cms-category">
                {userLanguage !== "en" ? (
                  <h6 className="cms-category-name">
                    {
                      cmsPages[0]?.translatedArray.filter((it) => {
                        return it.language === userLanguage;
                      })?.[0]?.categoryName
                    }
                  </h6>
                ) : (
                  <h6 className="cms-category-name">{cmsPages[0].name}</h6>
                )}
                {cmsPages[0].pages.map((page, index) => {
                  const pg = page.translatedArray.filter((it) => {
                    return it.language === userLanguage;
                  });
                  return (
                    <p
                      className="pointer"
                      onClick={() => history.push(`/pages/${page._id}`)}
                      key={index}
                    >
                      {pg?.[0]?.title ? pg?.[0]?.title : page.title}
                      {/* {page.title} */}
                    </p>
                  );
                })}
              </p>
            )}
          </Col>
          <Col lg="2" md="2" className="text-color column-container">
            {cmsPages?.length > 1 && (
              <p id="cms-category">
                {userLanguage !== "en" ? (
                  <h6 className="cms-category-name">
                    {
                      cmsPages[1]?.translatedArray.filter((it) => {
                        return it.language === userLanguage;
                      })?.[0]?.categoryName
                    }
                  </h6>
                ) : (
                  <h6 className="cms-category-name">{cmsPages[1].name}</h6>
                )}
                {cmsPages[1].pages.map((page, index) => {
                  const pg = page.translatedArray.filter((it) => {
                    return it.language === userLanguage;
                  });

                  return (
                    <p
                      className="pointer"
                      onClick={() => history.push(`/pages/${page._id}`)}
                      key={index}
                    >
                      {pg?.[0]?.title ? pg?.[0]?.title : page.title}
                    </p>
                  );
                })}
              </p>
            )}
          </Col>
          <Col lg="2" md="2" className="text-color column-container">
            {cmsPages?.length > 2 && (
              <p id="cms-category">
                {userLanguage !== "en" ? (
                  <h6 className="cms-category-name">
                    {
                      cmsPages[2]?.translatedArray.filter((it) => {
                        return it.language === userLanguage;
                      })?.[0]?.categoryName
                    }
                  </h6>
                ) : (
                  <h6 className="cms-category-name">{cmsPages[2].name}</h6>
                )}
                {cmsPages[2].pages.map((page, index) => {
                  const pg = page.translatedArray.filter((it) => {
                    return it.language === userLanguage;
                  });

                  return (
                    <p
                      className="pointer"
                      onClick={() => history.push(`/pages/${page._id}`)}
                      key={index}
                    >
                      {pg?.[0]?.title ? pg?.[0]?.title : page.title}
                    </p>
                  );
                })}
              </p>
            )}
          </Col>
          <Col
            lg="4"
            md="4"
            className="text-color column-container social-container"
          >
            {paymentConfiguration?.facebookLink && (
              <div className="social-link">
                <a
                  href={paymentConfiguration?.facebookLink}
                  target="_blank"
                  id="cms-category"
                  className="facebook-icon"
                >
                  <i
                    className="fa fa-facebook fa-lg social-icon"
                    aria-hidden="true"
                  ></i>
                  {/* {paymentConfiguration?.facebookLink} */}
                </a>
              </div>
            )}
            {paymentConfiguration?.instagramLink && (
              <div className="social-link">
                <a
                  href={paymentConfiguration?.instagramLink}
                  target="_blank"
                  id="cms-category"
                >
                  <i
                    className="fa fa-instagram fa-lg social-icon"
                    aria-hidden="true"
                  ></i>
                  {/* {paymentConfiguration?.instagramLink} */}
                </a>
              </div>
            )}
            {paymentConfiguration?.twitterLink && (
              <div className="social-link">
                <a
                  href={paymentConfiguration?.twitterLink}
                  target="_blank"
                  id="cms-category"
                >
                  <i
                    className="fa fa-twitter fa-lg social-icon"
                    aria-hidden="true"
                  ></i>
                  {/* {paymentConfiguration?.twitterLink} */}
                </a>
              </div>
            )}
            {paymentConfiguration?.linkedInLink && (
              <div className="social-link">
                <a
                  href={paymentConfiguration?.linkedInLink}
                  target="_blank"
                  id="cms-category"
                >
                  <i
                    className="fa fa-linkedin fa-lg social-icon"
                    aria-hidden="true"
                  ></i>
                  {/* {paymentConfiguration?.linkedInLink} */}
                </a>
              </div>
            )}
          </Col>
          <Row
            className="col-12"
            style={{ color: "white", marginBottom: "25px", marginTop: "1%" }}
          >
            <Col lg="1">
              <span onClick={() => history.push("/support")}>
                {footerTranslation?.contactUs
                  ? footerTranslation?.contactUs
                  : footer?.contactUs}
                {/* Contact Us */}
              </span>
            </Col>
            <Col lg="3" md="5">
              {/* All Rights Reserved */}
              {footerTranslation?.reserver
                ? footerTranslation?.reserver
                : footer?.reserver}{" "}
              @YINN Group Inc {year}
            </Col>
            <Col lg="3" md="4">
              <i className="fa fa-flag social-icon" aria-hidden="true"></i>
              <span onClick={() => history.push("/copyrights")}>
                {footerTranslation?.copyright
                  ? footerTranslation?.copyright
                  : footer?.copyright}
                {/* Report copyright infringements */}
              </span>
            </Col>
            {user?.userType !== "admin" && (
              <Col lg="3" md="3">
                <i
                  className="fa fa-shopping-bag fa-lg social-icon"
                  aria-hidden="true"
                ></i>
                &nbsp;
                <span className="seller-login" onClick={sellerLogin}>
                  {footerTranslation?.shopAt
                    ? footerTranslation?.shopAt
                    : footer?.shopAt}
                  {/* Sell On YINN */}
                </span>
              </Col>
            )}
          </Row>
        </Row>
      </div>
    </div>
  );
};

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
