import React, { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../../redux/reducers/seller";
import SkyLight from "react-skylight";
import { saveDispute } from "../../redux/reducers/auth";
import {
  InputGroup,
  InputGroupAddon,
  Input,
  Row,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { toast } from "react-toastify";

const OrderView = () => {
  const dispatch = useDispatch();

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const toastTranslation =
    translationState?.[0]?.translations?.toast?.[userLanguage];
  const shippingCartTranslation =
    translationState?.[0]?.translations?.shippingCart?.[userLanguage];
  const orderTranslation =
    translationState?.[0]?.translations?.order?.[userLanguage];
  const digitalProductTranslation =
    translationState?.[0]?.translations?.digitalProduct?.[userLanguage];

  const otherOrderTranslation =
    translationState?.[0]?.translations?.otherOrder?.[userLanguage];
  const { id } = useParams();
  const modal = useRef(null);
  const { singleOrder, loading } = useSelector((state) => state.seller);
  const [pageContent, setPageContent] = useState({
    customerName: "",
    orderId: "",
    productId: "",
    detailMessage: "",
  });
  useEffect(() => {
    dispatch(getSingleOrder(id));
  }, [dispatch]);

  const HandleDate = (date) => {
    var d = new Date(date);
    return d.toLocaleString();
  };

  const onDisputeOpen = () => {
    setPageContent({
      customerName: singleOrder?.userId?.firstname,
      orderId: singleOrder?._id,
      productId: singleOrder?.products?.[0]?.product?._id,
      detailMessage: "Dispute created by user side",
    });
    modal.current.show();
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPageContent({
      ...pageContent,
      [name]: value,
    });
  };
  const save = () => {
    const { orderId, productId } = pageContent;
    if (productId == "" && orderId == "") {
      toast.error(
        `${
          toastTranslation?.requried
            ? toastTranslation?.requried
            : "Product Id OR Order Id is required"
        }`
      );
      return;
    }
    dispatch(saveDispute(pageContent)).then(() => {
      modal.current.hide();
      toast.error(
        `${
          toastTranslation?.success
            ? toastTranslation?.success
            : "Dispute sent successfully"
        }`
      );
      history.push(`customer-account/account-details`);
    });
  };
  var myBigGreenDialog = {
    minWidth: "40%",
    minHeight: "20%",
  };

  return (
    <>
      {loading ? (
        <div className="order-view-loader">
          <i
            hidden={!loading}
            className="mx-auto fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green", float: "right" }}
          ></i>
        </div>
      ) : (
        <>
          <SkyLight
            dialogStyles={myBigGreenDialog}
            hideOnOverlayClicked
            ref={modal}
            title={
              <div className="d-none d-md-block mt-3">
                <div className="card">
                  <div className="card-block clearfix">
                    <i className="fa fa-flag bg-warning p-1 font-4xl mr-1 float-left"></i>
                    <div className="h2 text-warning mb-0 mt-h">
                      {otherOrderTranslation?.reportDispute
                        ? otherOrderTranslation?.reportDispute
                        : "Report a Dispute  "}
                    </div>
                  </div>
                </div>
              </div>
            }
          >
            <div>
              <FormGroup>
                <div className="main-content">
                  <FormGroup>
                    <Label>
                      {orderTranslation?.customerName
                        ? orderTranslation?.customerName
                        : "Customer Name"}
                    </Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="customerName"
                          value={pageContent.customerName}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {orderTranslation?.order
                        ? orderTranslation?.order + orderTranslation?.id
                        : "Order Id"}
                    </Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="orderId"
                          value={pageContent.orderId}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {orderTranslation?.product
                        ? orderTranslation?.product + orderTranslation?.id
                        : "Product Id"}
                    </Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="text"
                          name="productId"
                          value={pageContent.productId}
                          onChange={handleInputChange}
                          disabled
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>
                      {orderTranslation?.messageDetail
                        ? orderTranslation?.messageDetail
                        : "Detail Message"}
                    </Label>
                    <div className="controls">
                      <InputGroup className="input-prepend">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          size="16"
                          type="textarea"
                          name="detailMessage"
                          value={pageContent.detailMessage}
                          onChange={handleInputChange}
                        />
                      </InputGroup>
                    </div>
                  </FormGroup>

                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      columnGap: "12px",
                    }}
                  >
                    <Button
                      type="submit"
                      size="md"
                      color="warning"
                      onClick={save}
                    >
                      {digitalProductTranslation?.saveButton
                        ? digitalProductTranslation?.saveButton
                        : "Save"}
                    </Button>
                    <Button
                      type="submit"
                      size="md"
                      onClick={() => history.goBack()}
                      color="danger"
                    >
                      {digitalProductTranslation?.cancel
                        ? digitalProductTranslation?.cancel
                        : "Cancel"}
                    </Button>
                  </Row>
                </div>
              </FormGroup>
            </div>
          </SkyLight>
          {singleOrder && (
            <div className="main-row">
              <p className="order-id-text">
                {orderTranslation?.order ? orderTranslation?.order : "Order "}
                <span className="view-span">#{singleOrder?._id}</span>
                {orderTranslation?.onPlaced
                  ? orderTranslation?.onPlaced
                  : "was placed on"}
                <span className="view-span">
                  {HandleDate(singleOrder?.orderDate)}
                </span>
                {orderTranslation?.currentlyValue
                  ? orderTranslation?.currentlyValue
                  : "and is currently"}
                <span className="view-span">{singleOrder?.orderStatus}</span>.
              </p>
              <h2 className="order-view-text ">
                {orderTranslation?.orderDetail
                  ? orderTranslation?.orderDetail
                  : "Order details"}
              </h2>
              {singleOrder?.products?.length &&
                singleOrder?.products?.map((myproduct, index) => {
                  return (
                    <div className="div-style" key={index}>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        <div className="col-6">
                          <b>
                            {orderTranslation?.product
                              ? orderTranslation?.product
                              : "Product"}
                          </b>
                        </div>
                        <div>
                          <b>
                            {orderTranslation?.total
                              ? orderTranslation?.total
                              : "Total"}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        <div className="col-6 order-product-text">
                          <span className="">{myproduct.product?.name}</span>
                          <b className="mx-1">× {myproduct.qty}</b>
                          <br />
                          {orderTranslation?.testVendor
                            ? orderTranslation?.testVendor
                            : "Vendor:"}
                          <span>Authentic Corner</span>
                        </div>
                        <div>${myproduct?.unitPrice}</div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        <div className="col-6">
                          <b>
                            {orderTranslation?.subTotal
                              ? orderTranslation?.subTotal
                              : "Subtotal:"}
                          </b>
                        </div>
                        <div>
                          <b className="total-td-text">
                            ${myproduct?.unitPrice * myproduct?.qty}
                          </b>
                        </div>
                      </div>
                      <div
                        style={{ borderBottom: "0.5px solid grey" }}
                        className="row my-3"
                      >
                        <div className="col-6">
                          <b>
                            {orderTranslation?.paymentMethod
                              ? orderTranslation?.paymentMethod
                              : "Payment method:"}
                          </b>
                        </div>
                        <div>
                          <b className="total-td-text">
                            {singleOrder?.paymentMethod}
                          </b>
                        </div>
                      </div>
                      <div
                        className="row my-3"
                        style={{ borderBottom: "0.5px solid grey" }}
                      >
                        <div className="col-6">
                          <b>
                            {orderTranslation?.total
                              ? orderTranslation?.total
                              : "Total:"}
                          </b>
                        </div>
                        <div>
                          <b className="total-td-text">${singleOrder?.total}</b>
                        </div>
                      </div>
                      {singleOrder?.products ? (
                        <div className="row">
                          <div className="col-6">
                            <b>
                              {orderTranslation?.tokenId
                                ? orderTranslation?.tokenId
                                : "Event Token Id"}
                            </b>
                          </div>
                          <div>
                            <b>{singleOrder?.orderEventTokenId}</b>
                          </div>
                        </div>
                      ) : (
                        " "
                      )}
                    </div>
                  );
                })}

              <div className="my-3">
                {singleOrder?.disputeId === null && (
                  <button
                    className="button-color"
                    onClick={onDisputeOpen}
                    style={{ borderRadius: "3px" }}
                  >
                    {orderTranslation?.orderDetailButton
                      ? orderTranslation?.orderDetailButton
                      : "Open dispute"}
                  </button>
                )}
              </div>
              <div>
                <h2 className="order-view-text">
                  {shippingCartTranslation?.shippingAddress
                    ? shippingCartTranslation?.shippingAddress
                    : "Shipping Address"}
                </h2>
              </div>
              {singleOrder?.shippingAddress && (
                <div className="div-style p-3">
                  <b>
                    {shippingCartTranslation?.firsrtName
                      ? shippingCartTranslation?.firsrtName
                      : "First Name"}
                  </b>
                  : {singleOrder?.shippingAddress?.firstname}
                  <br />
                  <b>
                    {shippingCartTranslation?.lastName
                      ? shippingCartTranslation?.lastName
                      : "Last Name"}
                  </b>
                  : {singleOrder?.shippingAddress?.lastname}
                  <br />
                  <b>
                    {shippingCartTranslation?.onelineAddress
                      ? shippingCartTranslation?.onelineAddress
                      : "AddressLineOne "}
                  </b>
                  :{singleOrder?.shippingAddress?.addressLineOne}
                  <br />
                  <b>
                    {shippingCartTranslation?.secondlineAddress
                      ? shippingCartTranslation?.secondlineAddress
                      : "AddressLineTwo"}
                  </b>
                  :{singleOrder?.shippingAddress?.addressLineTwo}
                  <br />
                  <b>
                    {shippingCartTranslation?.zipCode
                      ? shippingCartTranslation?.zipCode
                      : "ZipCode"}
                  </b>
                  : {singleOrder?.shippingAddress?.zipCode}
                  <br />
                  <b>
                    {shippingCartTranslation?.country
                      ? shippingCartTranslation?.country
                      : "Country "}
                  </b>
                  : {singleOrder?.shippingAddress?.country}
                  <br />
                  <b>
                    {shippingCartTranslation?.state
                      ? shippingCartTranslation?.state
                      : "State "}
                  </b>
                  : {singleOrder?.shippingAddress?.state}
                  <br />
                  <b>
                    {shippingCartTranslation?.city
                      ? shippingCartTranslation?.city
                      : "City"}
                  </b>
                  : {singleOrder?.shippingAddress?.city}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default OrderView;
