import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getTransactions, getTranslation } from "../../redux/reducers/user";
import "./paymentMethod.css";

const PaymentMethod = () => {
  const { wallet, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { transaction } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getTransactions());
  }, [dispatch]);

  const { translationState } = useSelector((state) => state.user);
  const { userLanguage } = useSelector((state) => state.auth);
  const eVoucherTranslation =
    translationState?.[0]?.translations?.eVoucher?.[userLanguage];
  const advertisementsTranslation =
    translationState?.[0]?.translations?.advertisements?.[userLanguage];
  const walletTranslation =
    translationState?.[0]?.translations?.wallet?.[userLanguage];
  const userDashboardTranslation =
    translationState?.[0]?.translations?.userDashboard?.[userLanguage];
  const sellerTranslation =
    translationState?.[0]?.translations?.seller?.[userLanguage];
  const sellerHomeTranslation =
    translationState?.[0]?.translations?.sellerHome?.[userLanguage];

  const history = useHistory();
  return (
    <>
      {loading ? (
        <div style={{ marginTop: "200px" }} className="d-flex">
          <i
            className="mx-auto fas fa-spinner fa-pulse fa-2x"
            style={{ color: "green", float: "right" }}
          ></i>
        </div>
      ) : (
        <div>
          <div className="payment-method-wrapper">
            <div className="d-flex justify-content-between flex-wrap mx-xl-3">
              <div className="payment-method">
                <div>
                  <h3>
                    {sellerTranslation?.coin
                      ? sellerTranslation?.coin
                      : "Yinn Coin "}
                  </h3>
                  <b className="payment-method-tagline">
                    {wallet?.coinBalance
                      ? wallet?.coinBalance?.toFixed(2)
                      : "N/A"}
                  </b>
                </div>
                <div>
                  <button
                    onClick={() =>
                      history.push("/customer-account/user-purchase-coin")
                    }
                    className="btn btn-success btn-sm  py-2 mx-4"
                  >
                    {userDashboardTranslation?.purchase
                      ? userDashboardTranslation?.purchase
                      : "Purchase Coin "}
                  </button>
                </div>
              </div>
              <div className="payment-method">
                <div>
                  <h3 className="mob-heading">
                    {sellerTranslation?.Wallet
                      ? sellerTranslation?.Wallet
                      : "Wallet "}
                  </h3>
                  <b className="payment-method-tagline">
                    ${wallet?.earnings ? wallet?.earnings?.toFixed(2) : "N/A"}
                  </b>
                </div>
                <div>
                  <button
                    onClick={() => history.push("/customer-account/payouts")}
                    className="btn btn-success btn-sm  py-2 mx-4"
                  >
                    {userDashboardTranslation?.convert
                      ? userDashboardTranslation?.convert
                      : "Convert Coin"}
                  </button>
                </div>
              </div>
              <div className="payment-method">
                <div>
                  <h3 className="mob-heading">
                    {sellerHomeTranslation?.payoutButton
                      ? sellerHomeTranslation?.payoutButton
                      : "Payout"}
                  </h3>
                </div>
                <div>
                  <button
                    onClick={() => history.push("/customer-account/payouts")}
                    className="btn btn-success btn-sm  py-2 mx-4"
                  >
                    {userDashboardTranslation?.convert
                      ? userDashboardTranslation?.convert
                      : "Convert Coin"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3 overflow-auto user-table-payment-height">
            <div className="col-12 ">
              <h3 className="mt-5">
                {userDashboardTranslation?.transaction
                  ? userDashboardTranslation?.transaction
                  : "Transaction"}
              </h3>
              <table
                style={{ border: "0.5px solid grey", border: "none" }}
                className="table"
              >
                <thead className="text-left bg-color">
                  <tr>
                    <th>
                      {userDashboardTranslation?.id
                        ? userDashboardTranslation?.id
                        : "Transaction Id"}
                    </th>
                    <th>
                      {walletTranslation?.amount
                        ? walletTranslation?.amount
                        : "Amount"}
                    </th>
                    <th>
                      {eVoucherTranslation?.status
                        ? eVoucherTranslation?.status
                        : "Status"}
                    </th>
                  </tr>
                </thead>
                <div></div>
                {transaction?.map((data, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{data._id}</td>
                        <td>{data.amount}</td>
                        <td
                          className={`  ${
                            data?.processingInfo?.processingStatus === "PAID"
                              ? "text-success"
                              : "text-warning"
                          }`}
                        >
                          <span className="payment-mehod-status">
                            {data?.processingInfo?.processingStatus ===
                              "PENDING" && walletTranslation?.statusPending
                              ? walletTranslation?.statusPending
                              : data?.processingInfo?.processingStatus}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PaymentMethod;
